import React, { useState } from 'react';
import ForgotPasswordModal from './forgotPasswordModal.js';

const ForgotPasswordButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className='forgot-password-button' onClick={openModal}>Forgot Password?</button>
      {isModalOpen && <ForgotPasswordModal onClose={closeModal} />}
    </>
  );
};

export default ForgotPasswordButton;
