import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const CreateAuthenticatedUser = () => {
  const [email, setEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const auth = getAuth();

  const handleCreateUser = async () => {
    setMessage('');
    setError('');
    try {
      // Create the user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, tempPassword);

      setMessage('User created successfully.');
    } catch (error) {
      setError('Error creating user: ' + error.message);
    }
  };

  return (
    <div>
      <h2>Create Authenticated User</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Temporary Password"
        value={tempPassword}
        onChange={(e) => setTempPassword(e.target.value)}
      />
      <button onClick={handleCreateUser}>Create User</button>
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default CreateAuthenticatedUser;

