import React from 'react';
import './bundleinsuranceinfoContact.css';
import QuoteInfoFileUpload from '../quoteInfoFileUpload';

function BundleInsuranceContact({ formData, handleChange }) {
  return (
    <div className="bundlequoteinfo-contact">
      <div className='bundlequoteinfo-contact-abc'>
        <div className='contact-abc-a'>
          <p id='bundle-contact-abc-a'>A</p>
          <p id='bundle-contact-abc-address'>Address</p>
        </div>
        <div className='contact-abc-b'>
          <p id='bundle-contact-abc-b'>B</p>
          <p id='bundle-contact-abc-birthdate'>Birthdate</p>
        </div>
        <div className='contact-abc-c'>
          <p id='bundle-contact-abc-c'>C</p>
          <p id='bundle-contact-abc-contact'>Contact Info</p>
        </div>
      </div>
      <div className='first-last-name-inputs'>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={(e) => handleChange('firstName', e.target.value)}
          placeholder='First Name'
        />
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={(e) => handleChange('lastName', e.target.value)}
          placeholder='Last Name'
        />
      </div>
      <input
        type="text"
        id="phoneNumber"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={(e) => handleChange('phoneNumber', e.target.value)}
        placeholder='Phone'
      />
      <input
        type="text"
        id="email"
        name="email"
        value={formData.email}
        onChange={(e) => handleChange('email', e.target.value)}
        placeholder='Email'
      />
      <div className='additionalNotes-textarea'>
        <textarea
          id="additionalNotes"
          name="additionalNotes"
          placeholder='Is there any additional information you would like your agent to know?'
          value={formData.additionalNotes}
          onChange={(e) => handleChange('additionalNotes', e.target.value)}
        />
      </div>
      <div className="agent-code-input">
        <label htmlFor="agentCode">If applicable, enter agent code:</label>
        <input
          type="text"
          id="agentCode"
          name="agentCode"
          value={formData.agentCode}
          onChange={(e) => handleChange('agentCode', e.target.value)}
          placeholder='Agent Code'
        />
      </div>
      <div className='contact-what-happens-next'>
        <p><span id='happens-next-bold'>WHAT HAPPENS NEXT</span> - You will receive confirmation who your local agent is and that they are working on your quotes. Within 24 hours your agent will set up a time to go over your quotes to verify everything is correct.</p>
      </div>
    </div>
  );
}

export default BundleInsuranceContact;
