import React from 'react';
import ReactDOM from 'react-dom/client';
import './homepage-bottom.css';

function Bottom() {

    return (
        <div className='homepage-bottom'>
            <p className='bottom-disclaimer'>ABCquote uses the information provided to get you competitive quotes using your local independent insurance agents. This process requires that we share your information with agents and carriers that we are requesting quotes from and may take a couple business days before your quote is sent to you by email and reviewed with you by a licensed independent agent.</p>
            <div className='bottom-secure'>
                <img className='bottomsecure-img' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fssl-secure.png?alt=media&token=7fd02ede-a09a-4846-ac22-e5b57ff7ada3'/>
                <p className='bottom-allrights'>© 2024 ABCquote. All Rights Reserved.</p>
            </div>
        </div>
    )

}

export default Bottom;