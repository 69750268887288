import React from 'react';
import ReactDOM from 'react-dom/client';
import './bundleinsuranceinfoAddress.css';


function BundleInsuranceAddress({formData, handleChange}) {

  
    return (
      <div className="bundlequoteinfo-address">
      <div className='bundlequoteinfo-address-abc'>
        <div className='address-abc-a'>
          <p id='bundle-address-abc-a'>A</p>
          <p id='bundle-address-abc-address'>Address</p>
        </div>
        <div className='address-abc-b'>
          <p id='bundle-address-abc-b'>B</p>
          <p id='bundle-address-abc-birthdate'>Birthdate</p>
        </div>
        <div className='address-abc-c'>
          <p id='bundle-address-abc-c'>C</p>
          <p id='bundle-address-abc-contact'>Contact Info</p>
        </div>
      </div>
      <div className='addressinputs'>
      <input
        type="text"
        id="streetAddress"
        name="streetAddress"
        value={formData.streetAddress}
        onChange={(e) => handleChange('streetAddress', e.target.value)}
        placeholder='Street Address'
      />
      <input
        type="text"
        id="state"
        name="state"
        value={formData.state}
        onChange={(e) => handleChange('state', e.target.value)}
        placeholder='State'
      />
      <input
        type="text"
        id="zipCode"
        name="zipCode"
        value={formData.zipCode}
        onChange={(e) => handleChange('zipCode', e.target.value)}
        placeholder='Zip Code'
      />
      </div>

      </div>
    );
  }
  
  export default BundleInsuranceAddress;