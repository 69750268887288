// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import AboutUs from './AboutUs/AboutUs';
import GetAQuote from './Get A Quote/GetAQuotePage';
import HowItWorks from './How It Works/HowItWorks';
import Testimonials from './Testimonials/Testimonials';
import FrequentlyAskedQuestions from './Resources/Frequently Asked Questions/FrequentlyAskedQuestions';
import Blog from './Resources/Blog/Blog';
import Post from './Resources/Blog/BlogComponents/Post';
import BlogArticles from './Resources/Blog/BlogComponents/blog-articles/blog-articles';
import ScrollToTop from './SCROLL TO TOP/scrolltotop';
import VideoPost from './Resources/Blog/BlogComponents/VideoPost';
import HomeQuotePage1 from './Quote Info/HomeInsuranceInfo/homeinsuranceinfoAddress/homeinsuranceinfo/homeinsuranceinfo';
import AutoQuotePage1 from './Quote Info/AutoInsuranceInfo/autoinsuranceinfo';
import BundleQuotePage1 from './Quote Info/BundleInsuranceInfo/bundleinsuranceinfo';
import Login from './Quote Info/login';
import QuoteInfoFileUpload from './Quote Info/quoteInfoFileUpload';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import DeclarationsPagesInstructions from './Quote Info/declarationsPagesInstructions';
import ForBrokers from './For Brokers/forBrokers';
import LenderSignUp from './ForLenders/LenderSignUp/lenderSignUp';
import LenderLogin from './ForLenders/LenderLogin/lenderLogin';
import DashboardHome from './ForLenders/LenderDashboard/lenderDashboard';
import LenderMyAccount from './ForLenders/LenderDashboard/lenderMyAccount';
import LenderMyQuotes from './ForLenders/LenderDashboard/lenderMyQuotes';
import BrokerLogin from './For Brokers/BrokersDashboard/brokerLogin';
import BrokerDashboard from './For Brokers/BrokersDashboard/brokerDashboard';
import CaptiveDashboard from './For Brokers/For Captive/captiveDashboard';
import BrokerSignUpForm from './For Brokers/BrokersDashboard/brokerSignupForm';
import BrokerMyAccount from './For Brokers/BrokersDashboard/brokerMyAccount';
import ArchivedQuotes from './ForLenders/LenderDashboard/lenderArchivedQuotes';
import BrokerArchivedQuotes from './For Brokers/BrokersDashboard/brokerArchivedQuotes/BrokerArchivedQuotes';
import Community from './Community/community';
import ChangePassword from './For Brokers/BrokersDashboard/changePassword';
import AdminPage from './ADMIN/adminPage';
import ResetInitialBrokerPassword from './ADMIN/resetInitialBrokerPassword';
import CaptiveArchivedQuotes from './For Brokers/For Captive/captiveArchivedQuotes';
import CaptiveMyAccount from './For Brokers/For Captive/captiveMyAccount';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);


  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/admin' element={ <AdminPage /> } />
          <Route path='/getaquote' element={<GetAQuote />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/howitworks' element={<HowItWorks />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/frequentlyaskedquestions' element={<FrequentlyAskedQuestions />} />
          <Route path='/blog' element={<Blog />}>
            <Route index element={<BlogArticles />} />
          </Route>
          <Route path='/blog/:postId' element={<Post />} /> {/* Route for rendering individual post */}
          <Route path='/video/:videoId' element={<VideoPost />} /> {/* Route for rendering video posts */}
          <Route path='/homequotepage1' element={<HomeQuotePage1 />} />
          <Route path='/autoquotepage1' element={<AutoQuotePage1 />} />
          <Route path='/bundlequotepage1' element={<BundleQuotePage1 />} />
          <Route path='/login' element={<Login />} />
          <Route path='/upload' element={<QuoteInfoFileUpload />} />
          <Route path='/decpagehelp' element={<DeclarationsPagesInstructions />} />
          <Route path='/forbrokers' element={<ForBrokers />} />
          <Route path='/forlenders' element={<LenderSignUp />} />
          <Route path='/lender-login' element={<LenderLogin /> } />
          <Route path='/lender-dashboard' element={<DashboardHome />} />
          <Route path='/lendermyaccount' element={<LenderMyAccount />} />
          <Route path='/lender-myquotes' element={<LenderMyQuotes />} />
          <Route path='/archived-quotes' element={<ArchivedQuotes />} />
          <Route path='/broker-login' element={<BrokerLogin />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/broker-signup-form' element={<BrokerSignUpForm />} />
          <Route path='/reset-initial-password' element={ <ResetInitialBrokerPassword /> } />
          <Route path='/broker-dashboard' element={ <BrokerDashboard /> } />
          <Route path='/brokermyaccount' element={<BrokerMyAccount />} />
          <Route path='broker-archived-quotes' element={<BrokerArchivedQuotes />} />
          <Route path='/community' element={<Community />} />
          <Route path='/captive-dashboard' element={ <CaptiveDashboard /> } />
          <Route path='/lead-archived-quotes' element={ <CaptiveArchivedQuotes /> } />
          <Route path='/lead-myaccount' element={ <CaptiveMyAccount /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


