// CaptiveDashboard.jsx
import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import Spinner from '../../Spinner';
import './captivedashboard.css';

// ----- Close Quote Modal Component -----
// A modal that collects the close reason and (optionally) carrier info.
// The optional prop "hideCarrierOptions" hides the carrier selection when true.
const CloseQuoteModal = ({
  isOpen,
  onClose,
  onSubmit,
  closeReason,
  setCloseReason,
  unsuccessfulReason,
  setUnsuccessfulReason,
  selectedCarrier,
  setSelectedCarrier,
  otherCarrier,
  setOtherCarrier,
  hideCarrierOptions,
}) => {
  if (!isOpen) return null;

  const handleSubmit = () => {
    if (!closeReason) {
      alert('Please select a close reason.');
      return;
    }
    if (closeReason === 'unsuccessful' && !unsuccessfulReason) {
      alert('Please select an unsuccessful reason.');
      return;
    }
    onSubmit();
  };

  return (
    <div className="captive-modal-overlay">
      <div className="captive-modal-content">
        <div className="captive-modal-header">
          <h2>Close Quote</h2>
          <button className="captive-modal-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="captive-modal-form-group">
          <label>Close Reason:</label>
          <select value={closeReason} onChange={(e) => setCloseReason(e.target.value)}>
            <option value="">Select a reason</option>
            <option value="successful">Close Successful</option>
            <option value="unsuccessful">Close Unsuccessful</option>
          </select>
        </div>
        {closeReason === 'unsuccessful' && (
          <div className="captive-modal-form-group">
            <label>Unsuccessful Reason:</label>
            <select
              value={unsuccessfulReason}
              onChange={(e) => setUnsuccessfulReason(e.target.value)}
            >
              <option value="">Select a reason</option>
              <option value="could_not_reach">Could not reach</option>
              <option value="not_valid">Not valid</option>
              <option value="not_competitive">Not competitive</option>
            </select>
          </div>
        )}
        {closeReason === 'successful' && !hideCarrierOptions && (
          <div className="captive-modal-form-group">
            <label>Carrier:</label>
            <select value={selectedCarrier} onChange={(e) => setSelectedCarrier(e.target.value)}>
              <option value="">Select a carrier</option>
              <option value="CarrierA">CarrierA</option>
              <option value="CarrierB">CarrierB</option>
              <option value="other">Other</option>
            </select>
            {selectedCarrier === 'other' && (
              <input
                type="text"
                placeholder="Enter other carrier"
                value={otherCarrier}
                onChange={(e) => setOtherCarrier(e.target.value)}
              />
            )}
          </div>
        )}
        <div className="captive-modal-actions">
          <button className="captive-confirm-button" onClick={handleSubmit}>
            Submit
          </button>
          <button className="captive-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// ----- Helper Function: Standardize Quote Info -----
// (Not used in this file, but kept here for completeness.)
const standardizeQuoteInfo = (quoteInfo) => {
  return {
    homeowner1FirstName:
      quoteInfo.homeowner1FirstName ||
      (quoteInfo.homeowner1 && quoteInfo.homeowner1.firstName) ||
      'N/A',
    homeowner1LastName:
      quoteInfo.homeowner1LastName ||
      (quoteInfo.homeowner1 && quoteInfo.homeowner1.lastName) ||
      'N/A',
    homeowner1Email:
      quoteInfo.homeowner1Email ||
      (quoteInfo.homeowner1 && quoteInfo.homeowner1.email) ||
      'N/A',
    homeowner1Phone:
      quoteInfo.homeowner1Phone ||
      (quoteInfo.homeowner1 && quoteInfo.homeowner1.phone) ||
      'N/A',
    homeowner1Birthdate:
      quoteInfo.homeowner1Birthdate ||
      (quoteInfo.homeowner1 && quoteInfo.homeowner1.birthdate) ||
      'N/A',
    homeowner2FirstName:
      quoteInfo.homeowner2FirstName ||
      (quoteInfo.homeowner2 && quoteInfo.homeowner2.firstName) ||
      'N/A',
    homeowner2LastName:
      quoteInfo.homeowner2LastName ||
      (quoteInfo.homeowner2 && quoteInfo.homeowner2.lastName) ||
      'N/A',
    homeowner2Email:
      quoteInfo.homeowner2Email ||
      (quoteInfo.homeowner2 && quoteInfo.homeowner2.email) ||
      'N/A',
    homeowner2Phone:
      quoteInfo.homeowner2Phone ||
      (quoteInfo.homeowner2 && quoteInfo.homeowner2.phone) ||
      'N/A',
    homeowner2Birthdate:
      quoteInfo.homeowner2Birthdate ||
      (quoteInfo.homeowner2 && quoteInfo.homeowner2.birthdate) ||
      'N/A',
    currentAddressStreet:
      quoteInfo.currentAddressStreet ||
      (quoteInfo.currentAddress && quoteInfo.currentAddress.street) ||
      'N/A',
    currentAddressCity:
      quoteInfo.currentAddressCity ||
      (quoteInfo.currentAddress && quoteInfo.currentAddress.city) ||
      'N/A',
    currentAddressState:
      quoteInfo.currentAddressState ||
      (quoteInfo.currentAddress && quoteInfo.currentAddress.state) ||
      'N/A',
    currentAddressZip:
      quoteInfo.currentAddressZip ||
      (quoteInfo.currentAddress && quoteInfo.currentAddress.zip) ||
      'N/A',
    newAddressStreet:
      quoteInfo.newAddressStreet ||
      (quoteInfo.newAddress && quoteInfo.newAddress.street) ||
      'N/A',
    newAddressCity:
      quoteInfo.newAddressCity ||
      (quoteInfo.newAddress && quoteInfo.newAddress.city) ||
      'N/A',
    newAddressState:
      quoteInfo.newAddressState ||
      (quoteInfo.newAddress && quoteInfo.newAddress.state) ||
      'N/A',
    newAddressZip:
      quoteInfo.newAddressZip ||
      (quoteInfo.newAddress && quoteInfo.newAddress.zip) ||
      'N/A',
    notes: quoteInfo.notes || quoteInfo.additionalNotes || 'N/A',
    attachedFiles: quoteInfo.attachedFiles || [],
    agentAttachedFiles: quoteInfo.agentAttachedFiles || [],
    formType: quoteInfo.formType || (quoteInfo.homeowner1 ? 'Lender Lead' : 'Standard Referral'),
  };
};

// ----- Helper Function: Format Timestamps -----
const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'N/A';
  let date;
  if (timestamp.toDate) {
    date = timestamp.toDate();
  } else {
    date = new Date(timestamp);
  }
  return date.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};

// ----- CaptiveDashboard Component -----
const CaptiveDashboard = () => {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  // Authentication state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Data state for sold leads (merged with details)
  const [soldLeads, setSoldLeads] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);

  // UI states
  const [filterText, setFilterText] = useState('');
  const [collapsedLeads, setCollapsedLeads] = useState({});
  const [showCloseModal, setShowCloseModal] = useState(false);

  // Form states for Close Quote Modal
  const [closeReason, setCloseReason] = useState('');
  const [unsuccessfulReason, setUnsuccessfulReason] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [otherCarrier, setOtherCarrier] = useState('');

  // ----- Fetch Agent Data for Authentication -----
  useEffect(() => {
    const fetchAgentData = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to view your quotes.');
        setLoading(false);
        return;
      }
      try {
        const userDoc = await getDoc(doc(db, 'userRoles', user.uid));
        if (!userDoc.exists() || !userDoc.data().roles.captiveLogin) {
          setError('You do not have access to this dashboard.');
          await signOut(auth);
          navigate('/');
          return;
        }
      } catch (err) {
        console.error('Error fetching agent data:', err);
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchAgentData();
  }, [navigate]);

  // ----- Fetch Sold Leads and Merge with Quote Details -----
  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      try {
        // Query SoldLeads sorted by soldAt in descending order
        const soldQuery = query(collection(db, 'soldLeads'), orderBy('soldAt', 'desc'));
        const soldSnap = await getDocs(soldQuery);
        const soldList = soldSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // For each sold lead, attempt to fetch its corresponding details.
        // First try lenderLeads; if not found, then try assignedAgents.
        const mergedPromises = soldList.map(async (soldLead) => {
          let details = null;
          let detailsSource = null;
          const lenderDocRef = doc(db, 'lenderLeads', soldLead.leadId);
          const lenderDocSnap = await getDoc(lenderDocRef);
          if (lenderDocSnap.exists()) {
            details = lenderDocSnap.data();
            detailsSource = 'lenderLeads';
          } else {
            const agentDocRef = doc(db, 'assignedAgents', soldLead.leadId);
            const agentDocSnap = await getDoc(agentDocRef);
            if (agentDocSnap.exists()) {
              details = agentDocSnap.data();
              detailsSource = 'assignedAgents';
            }
          }
          return {
            ...soldLead,
            details,
            detailsSource, // Helps determine which collection to update when closing a quote
          };
        });

        const mergedLeads = await Promise.all(mergedPromises);

        // Filter out leads that have been archived by checking the top-level field.
        const activeLeads = mergedLeads.filter((lead) => !lead.captiveArchived);

        setSoldLeads(activeLeads);

        // Initialize collapse state for each active lead
        const initialCollapse = {};
        activeLeads.forEach((lead) => {
          initialCollapse[lead.id] = true;
        });
        setCollapsedLeads(initialCollapse);
      } catch (err) {
        console.error('Error fetching sold leads:', err);
        setError('Error fetching leads.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, [db]);

  // ----- Filtering Functionality -----
  const filteredLeads = soldLeads.filter((lead) => {
    const search = filterText.toLowerCase();
    // For lender leads, use homeowner1 info; otherwise use sold lead fields.
    const clientName =
      lead.details && lead.details.homeowner1
        ? `${lead.details.homeowner1.firstName} ${lead.details.homeowner1.lastName}`.toLowerCase()
        : (lead.leadName || '').toLowerCase();
    const clientEmail =
      lead.details && lead.details.homeowner1
        ? (lead.details.homeowner1.email || '').toLowerCase()
        : (lead.leadEmail || '').toLowerCase();
    return clientName.includes(search) || clientEmail.includes(search);
  });

  // ----- Collapse/Expand Functions -----
  const toggleLeadCollapse = (leadId) => {
    setCollapsedLeads((prev) => ({
      ...prev,
      [leadId]: !prev[leadId],
    }));
  };

  const collapseAllLeads = () => {
    const newCollapse = {};
    soldLeads.forEach((lead) => {
      newCollapse[lead.id] = true;
    });
    setCollapsedLeads(newCollapse);
  };

  const expandAllLeads = () => {
    const newExpand = {};
    soldLeads.forEach((lead) => {
      newExpand[lead.id] = false;
    });
    setCollapsedLeads(newExpand);
  };

  // ----- Close Quote Modal Handlers -----
  const handleCloseQuoteClick = (lead) => {
    setSelectedQuote(lead);
    // Reset modal form state
    setCloseReason('');
    setUnsuccessfulReason('');
    setSelectedCarrier('');
    setOtherCarrier('');
    setShowCloseModal(true);
  };

  // ----- Updated Close Quote Submission Handler for the Captive Dashboard -----
  // When a captive quote is closed, update only the soldLeads document with:
  // - captiveSoldResult: "successful" OR "unsuccessful"
  // - If unsuccessful, also captiveSoldUnsuccessfulReason
  // - Archive the document by setting captiveArchived, archiveTimestamp, and captiveEmail.
  const handleCloseQuoteSubmit = async () => {
    if (!selectedQuote) return;
    if (!closeReason) {
      alert('Please select a close reason.');
      return;
    }

    // Build the update data for the soldLeads document.
    const updateData = {
      captiveArchived: true, // Flag as archived.
      archiveTimestamp: serverTimestamp(), // Archive timestamp.
      captiveEmail: auth.currentUser.email, // Captive user's email.
    };

    if (closeReason === 'successful') {
      updateData.captiveSoldResult = 'successful';
    } else if (closeReason === 'unsuccessful') {
      updateData.captiveSoldResult = 'unsuccessful';
      if (unsuccessfulReason) {
        updateData.captiveSoldUnsuccessfulReason = unsuccessfulReason;
      }
    }

    try {
      // Update the soldLeads document (using selectedQuote.id which is the soldLeads doc ID).
      const quoteRef = doc(db, 'soldLeads', selectedQuote.id);
      await updateDoc(quoteRef, updateData);

      // Remove the closed (archived) lead from the dashboard state.
      setSoldLeads((prev) => prev.filter((lead) => lead.id !== selectedQuote.id));
      setShowCloseModal(false);
      setSelectedQuote(null);
      alert('Quote closed successfully.');
    } catch (error) {
      console.error('Error closing quote:', error);
      alert('Failed to close quote.');
    }
  };

  // ----- Sign Out Handler -----
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      console.error('Error signing out:', err);
    }
  };

  // ----- Render Functions for Lead Summary & Details -----
  const renderLeadSummary = (lead) => {
    const clientName =
      lead.details && lead.details.homeowner1
        ? `${lead.details.homeowner1.firstName} ${lead.details.homeowner1.lastName}`
        : lead.leadName || 'N/A';
    const clientEmail =
      lead.details && lead.details.homeowner1
        ? lead.details.homeowner1.email
        : lead.leadEmail || 'N/A';
    return (
      <div className="captive-lead-summary" onClick={() => toggleLeadCollapse(lead.id)}>
        <span className="captive-toggle-icon">{collapsedLeads[lead.id] ? '+' : '-'}</span>
        <span className="captive-lead-name">{clientName}</span>
        <span className="captive-lead-email">{clientEmail}</span>
      </div>
    );
  };

  // ----- Render Detailed Quote Information in Chunky Squares -----
  const renderLeadDetails = (lead) => {
    if (lead.detailsSource === 'lenderLeads' && lead.details) {
      const {
        attachedFiles,
        currentAddress,
        homeowner1,
        homeowner2,
        lenderEmail,
        newAddress,
        notes,
        selectedAgent,
        timestamp,
      } = lead.details;

      return (
        <div className="captive-lead-details">
          <div className="captive-quote-square">
            <p><strong>Form Type:</strong> Lender Lead</p>
          </div>
          <div className="captive-quote-square">
            {currentAddress && (
              <>
                <p><strong>Current Address:</strong></p>
                <ul>
                  <li><strong>Street:</strong> {currentAddress.street || 'N/A'}</li>
                  <li><strong>City:</strong> {currentAddress.city || 'N/A'}</li>
                  <li><strong>State:</strong> {currentAddress.state || 'N/A'}</li>
                  <li><strong>Zip:</strong> {currentAddress.zip || 'N/A'}</li>
                </ul>
              </>
            )}
          </div>
          <div className="captive-quote-square">
            {homeowner1 && (
              <>
                <p><strong>Homeowner 1:</strong></p>
                <ul>
                  <li><strong>Name:</strong> {homeowner1.firstName} {homeowner1.lastName}</li>
                  <li><strong>Email:</strong> {homeowner1.email || 'N/A'}</li>
                  <li><strong>Phone:</strong> {homeowner1.phone || 'N/A'}</li>
                  <li><strong>Birthdate:</strong> {homeowner1.birthdate || 'N/A'}</li>
                </ul>
              </>
            )}
          </div>
          <div className="captive-quote-square">
            {homeowner2 && (
              <>
                <p><strong>Homeowner 2:</strong></p>
                <ul>
                  <li><strong>Name:</strong> {homeowner2.firstName} {homeowner2.lastName}</li>
                  <li><strong>Email:</strong> {homeowner2.email || 'N/A'}</li>
                  <li><strong>Phone:</strong> {homeowner2.phone || 'N/A'}</li>
                  <li><strong>Birthdate:</strong> {homeowner2.birthdate || 'N/A'}</li>
                </ul>
              </>
            )}
          </div>
          <div className="captive-quote-square">
            <p><strong>Lender Email:</strong> {lenderEmail || 'N/A'}</p>
            {newAddress && (
              <>
                <p><strong>New Address:</strong></p>
                <ul>
                  <li><strong>Street:</strong> {newAddress.street || 'N/A'}</li>
                  <li><strong>City:</strong> {newAddress.city || 'N/A'}</li>
                  <li><strong>State:</strong> {newAddress.state || 'N/A'}</li>
                  <li><strong>Zip:</strong> {newAddress.zip || 'N/A'}</li>
                </ul>
              </>
            )}
          </div>
          <div className="captive-quote-square">
            <p><strong>Notes:</strong> {notes || 'None'}</p>
            <p><strong>Selected Agent:</strong> {selectedAgent || 'N/A'}</p>
            <p><strong>Timestamp:</strong> {timestamp ? formatTimestamp(timestamp) : 'N/A'}</p>
          </div>
          <div className="captive-quote-square">
            <p><strong>Attached Files:</strong></p>
            {attachedFiles && attachedFiles.length > 0 ? (
              <ul className="captive-attached-files-list">
                {attachedFiles.map((file, index) => (
                  <li key={index}>
                    <a href={file} target="_blank" rel="noopener noreferrer">
                      {`File ${index + 1}`}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>N/A</p>
            )}
          </div>
        </div>
      );
    } else if (lead.detailsSource === 'assignedAgents' && lead.details) {
      const {
        additionalNotes,
        address,
        agentEmail,
        agentName,
        attachedFiles,
        birthdate,
        email,
        formType,
        name,
        phone,
        timestamp,
      } = lead.details;
      return (
        <div className="captive-lead-details">
          <div className="captive-quote-square">
            <p><strong>Form Type:</strong> {formType || 'Standard Referral'}</p>
            <p><strong>Additional Notes:</strong> {additionalNotes || 'N/A'}</p>
          </div>
          <div className="captive-quote-square">
            {address && (
              <>
                <p><strong>Address:</strong></p>
                <ul>
                  <li><strong>Street:</strong> {address.streetAddress || 'N/A'}</li>
                  <li><strong>State:</strong> {address.state || 'N/A'}</li>
                  <li><strong>Zip Code:</strong> {address.zipCode || 'N/A'}</li>
                </ul>
              </>
            )}
          </div>
          <div className="captive-quote-square">
            <p><strong>Birthdate:</strong> {birthdate || 'N/A'}</p>
            <p><strong>Email:</strong> {email || 'N/A'}</p>
            <p><strong>Name:</strong> {name || 'N/A'}</p>
            <p><strong>Phone:</strong> {phone || 'N/A'}</p>
            <p><strong>Timestamp:</strong> {timestamp ? formatTimestamp(timestamp) : 'N/A'}</p>
          </div>
          <div className="captive-quote-square">
            <p><strong>Attached Files:</strong></p>
            {attachedFiles && attachedFiles.length > 0 ? (
              <ul className="captive-attached-files-list">
                {attachedFiles.map((file, index) => (
                  <li key={index}>
                    <a href={file} target="_blank" rel="noopener noreferrer">
                      {`File ${index + 1}`}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>N/A</p>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="captive-lead-details">
          <p>No additional details found.</p>
        </div>
      );
    }
  };

  return (
    <div className="captive-dashboard">
      <Navbar />
      <div className="captive-dashboard-header">
        <h1 id="captive-dashboard-title">ABC Lead Dashboard</h1>
        <div className="captive-dashboard-buttons">
          <button onClick={() => navigate('/lead-myaccount')} className="captive-dashboard-blue-button">
            My Account
          </button>
          <button onClick={() => navigate('/lead-archived-quotes')} className="captive-dashboard-blue-button">
            Archived Quotes
          </button>
          <button onClick={handleSignOut} className="captive-dashboard-blue-button">
            Sign Out
          </button>
        </div>
      </div>
      <div className="captive-dashboard-controls">
        <input
          type="text"
          id="captive-dashboard-search"
          placeholder="Search leads..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <button onClick={collapseAllLeads} className="captive-dashboard-control-button">
          Collapse All
        </button>
        <button onClick={expandAllLeads} className="captive-dashboard-control-button">
          Expand All
        </button>
      </div>
      {loading ? (
        <Spinner />
      ) : error ? (
        <p id="captive-error-message" className="captive-error-message">{error}</p>
      ) : (
        <ul id="captive-lead-list" className="captive-lead-list">
          {filteredLeads.map((lead) => (
            <li key={lead.id} className="captive-lead-item">
              {renderLeadSummary(lead)}
              {!collapsedLeads[lead.id] && renderLeadDetails(lead)}
              <div className="captive-lead-actions">
                <button onClick={() => handleCloseQuoteClick(lead)}>Close Quote</button>
              </div>
            </li>
          ))}
        </ul>
      )}
      {/* Close Quote Modal */}
      <CloseQuoteModal
        isOpen={showCloseModal}
        onClose={() => {
          setShowCloseModal(false);
          setSelectedQuote(null);
        }}
        onSubmit={handleCloseQuoteSubmit}
        closeReason={closeReason}
        setCloseReason={setCloseReason}
        unsuccessfulReason={unsuccessfulReason}
        setUnsuccessfulReason={setUnsuccessfulReason}
        selectedCarrier={selectedCarrier}
        setSelectedCarrier={setSelectedCarrier}
        otherCarrier={otherCarrier}
        setOtherCarrier={setOtherCarrier}
        hideCarrierOptions={true} // Hide carrier options in the captive dashboard
      />
    </div>
  );
};

export default CaptiveDashboard;


