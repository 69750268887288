// Post.js  THIS PAGE IS WHAT RENDERS WHEN A SPECIFIC BLOG ARTICLE IS SELECTED.
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Navbar from '../../../Navbar/Navbar';
import Bottom from '../../../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import StickyBanner from '../../../HomePage/HomePageComponents/STICKY BANNER/stickybanner';
import './markdownfiles.css'


const Post = () => {
  const { postId } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    // Fetch and parse the Markdown file
    import(`./MarkdownFiles/${postId}.md`) // Add .md extension back when importing
      .then(module => fetch(module.default))
      .then(res => res.text())
      .then(text => setContent(text))
      .catch(err => console.error('Error fetching content:', err));
  }, [postId]);

  return (
    <div>
        <Navbar />
        <div className='back-to-blog'>
        <Link to='/blog'>
        <button id='return-to-blogs-button'>Return to blogs</button>
        </Link>
        </div>
        <div className='post-page'>
           {content ? <ReactMarkdown>{content}</ReactMarkdown> : <p>Loading...</p>}
        </div>
        <Bottom />
        <StickyBanner />
    </div>
  );
};

export default Post;