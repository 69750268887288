import React from 'react';
import ReactDOM from 'react-dom/client';
import './aboutus-top.css';

function AboutUsTop() {

  
    return (
        <>
      <div className="aboutus-top">
        <h1>We're making it easier than ever to get the <span id='aboutus-top-RC'>Right Coverage</span> at the <span id='aboutus-top-BP'>Best Price</span></h1>
        <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-25-at-10.54.25-AM-e1696530981572.png?alt=media&token=1752ca1d-ed9f-4bb6-a978-7fa27ebda77b' />
      </div>
      <div className='aboutus-top-bottomborder'></div>
      </>
    );
  }
  
  export default AboutUsTop;