import React, { useEffect, useState } from 'react';
import './paymentCalculationModal.css';

const PaymentCalculationModal = ({ isOpen, onClose, onSubmit, annualPremium, setAnnualPremium }) => {
  const [referralAmount, setReferralAmount] = useState(0);  // State to hold the calculated referral amount

  // Calculate referral amount whenever the annual premium changes
  useEffect(() => {
    const calculatedReferral = annualPremium * 0.15 * 0.5;  // 7.5% of annual premium
    setReferralAmount(calculatedReferral);
  }, [annualPremium]);  // Recalculate whenever annualPremium changes

  const handleSubmit = () => {
    onSubmit(referralAmount);  // Pass referral amount to parent component
    onClose();  // Close the modal after submission
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="payment-modal-backdrop">
      <div className="payment-modal-content">
        <header className="payment-modal-header">
          <h2>Calculate Referral Payment</h2>
          <button className="payment-close-button" onClick={onClose}>×</button>
        </header>
        <div className="payment-modal-body">
          <label>
            Enter Annual Premium:
            <input
              type="number"
              value={annualPremium !== 0 ? annualPremium : ""}  // Set value to empty if annualPremium is 0
              onChange={e => setAnnualPremium(parseFloat(e.target.value) || 0)}  // Ensure it's a valid number
              placeholder="Enter annual premium"
              className="payment-input"
            />
          </label>
          <label>
            Referral Payment (7.5% of Annual Premium):
            <input
              type="number"
              value={referralAmount.toFixed(2)}  // Ensure it's displayed as a fixed decimal
              readOnly  // Make the input read-only
              className="payment-input read-only"
            />
          </label>
          <button className="payment-submit-button" onClick={handleSubmit}>
            Calculate and Submit Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentCalculationModal;

