import { SDK_VERSION, getApps, getApp, initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

console.log("Firebase SDK Version:", SDK_VERSION);


// ✅ Debugging: Ensure firebaseConfig.js runs
console.log("🔥 firebaseConfig.js is running...");

// ✅ Firebase Configuration (Make sure this is correct)
const firebaseConfig = {
  apiKey: "AIzaSyCb09Hi1Nx0G1kgGisi-KRLvxYS3A0R8M0",
  authDomain: "abc-quote.firebaseapp.com",
  projectId: "abc-quote",
  storageBucket: "abc-quote.appspot.com",
  messagingSenderId: "753070875309",
  appId: "1:753070875309:web:3202bcfdb380dada865c24",
  measurementId: "G-7J4B6BKTH0"
};

// ✅ Prevent Multiple Firebase Initializations
const app = getApps().length ? getApp() : initializeApp(firebaseConfig);

// ✅ Initialize Firestore (Without persistentLocalCache)
const db = getFirestore(app);

// ✅ Initialize Other Firebase Services
const auth = getAuth(app);
const functions = getFunctions(app, "us-central1");
const storage = getStorage(app);

// 🔥 Debugging Logs
console.log("✅ Firebase Initialized:", app);
console.log("🔥 Firestore Initialized:", db);
console.log("🔥 Auth Initialized:", auth);
console.log("🔥 Storage Initialized:", storage);



export { app, db, auth, functions, storage };

