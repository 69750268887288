import React, { useState } from 'react';
import { getAuth, updatePassword, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import './resetInitialBrokerPassword.css';

const ResetInitialBrokerPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  // Password validation function
  const validatePassword = (password) => {
    const validations = {
      minLength: password.length >= 8,
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidations(validations);

    return Object.values(validations).every(Boolean);
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (!validatePassword(newPassword)) {
      setError('Please ensure your password meets all requirements.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const user = auth.currentUser;
      await updatePassword(user, newPassword);

      // Update the temporaryPassword field in Firestore
      const userRolesRef = doc(db, 'userRoles', user.uid);
      await updateDoc(userRolesRef, { temporaryPassword: false });

      // Send email verification after resetting the password
      await sendEmailVerification(user);

      setMessage('Password has been reset successfully. Please verify your email.');
      // Optionally, redirect after a delay to allow time to see the success message
      setTimeout(() => navigate('/broker-dashboard'), 5000);
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Failed to reset password. Please try again.');
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleResetPassword}>
        <div>
          <label>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="password-requirements">
          <p style={{ color: passwordValidations.minLength ? 'green' : 'gray' }}>
            {passwordValidations.minLength ? '✓' : '•'} At least 8 characters long
          </p>
          <p style={{ color: passwordValidations.hasUppercase ? 'green' : 'gray' }}>
            {passwordValidations.hasUppercase ? '✓' : '•'} At least one uppercase letter
          </p>
          <p style={{ color: passwordValidations.hasLowercase ? 'green' : 'gray' }}>
            {passwordValidations.hasLowercase ? '✓' : '•'} At least one lowercase letter
          </p>
          <p style={{ color: passwordValidations.hasNumber ? 'green' : 'gray' }}>
            {passwordValidations.hasNumber ? '✓' : '•'} At least one number
          </p>
          <p style={{ color: passwordValidations.hasSpecialChar ? 'green' : 'gray' }}>
            {passwordValidations.hasSpecialChar ? '✓' : '•'} At least one special character
          </p>
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {message && <p style={{ color: 'green' }}>{message}</p>}
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetInitialBrokerPassword;



