import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from '../Navbar/Navbar';
import GetYouGoing from '../HomePage/HomePageComponents/homepage-getyougoing/homepage-getyougoing';
import Bottom from '../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';


function GetAQuote() {

  
    return (
      <div className="getaquote">
        <Navbar />
        <GetYouGoing />
        <Bottom />

      </div>
    );
  }
  
  export default GetAQuote;