import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import Navbar from '../../Navbar/Navbar';

const auth = getAuth();
const db = getFirestore();

const BrokerSignUpForm = () => {
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    agencyName: '',
    agencyWebsite: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: ''
  });
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    const maxLength = 14;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return {
      minLength: password.length >= minLength && password.length <= maxLength,
      hasUpperCase,
      hasLowerCase,
      hasNumbers,
      hasSpecialChar,
    };
  };

  const passwordValidation = validatePassword(password);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!Object.values(passwordValidation).every(Boolean)) {
      setError('Password does not meet requirements. Please try again.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, userInfo.email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'michiganagentlist', user.uid), {
        ...userInfo,
        email: user.email,
        agentId: user.uid,
        createdAt: Timestamp.now()
      });

      await setDoc(doc(db, 'userRoles', user.uid), {
        roles: {
          brokerLogin: true
        }
      }, { merge: true });

      setMessage('Profile updated successfully.');
      navigate('/broker-dashboard');
    } catch (error) {
      console.error('Error during user creation:', error);
      if (error.code === 'auth/email-already-in-use') {
        try {
          const userCredential = await signInWithEmailAndPassword(auth, userInfo.email, password);
          const user = userCredential.user;

          // Update the existing document with new information
          await setDoc(doc(db, 'michiganagentlist', user.uid), {
            ...userInfo,
            email: user.email,
            agentId: user.uid,
            updatedAt: Timestamp.now() // use updatedAt to indicate the update time
          }, { merge: true }); // merge: true ensures existing fields are not overwritten

          // Update the user roles to include brokerLogin
          await setDoc(doc(db, 'userRoles', user.uid), {
            roles: {
              brokerLogin: true
            }
          }, { merge: true });

          setMessage('Profile updated successfully.');
          navigate('/broker-dashboard');
        } catch (err) {
          console.error('Error during sign-in:', err);
          setError('Error updating profile. Please try again.');
        }
      } else {
        setError('Error creating profile. Please try again.');
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard-home-container">
        <h1>We're Thrilled You Have Decided To Partner With Us!</h1>
        <h2>Please complete the form below to complete your registration, you will then be taken to your dashboard.</h2>
        <form className="dashboard-home-form" onSubmit={handleFormSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={userInfo.name}
            onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={userInfo.email}
            onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Agency Name"
            value={userInfo.agencyName}
            onChange={(e) => setUserInfo({ ...userInfo, agencyName: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Agency Website"
            value={userInfo.agencyWebsite}
            onChange={(e) => setUserInfo({ ...userInfo, agencyWebsite: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Street Address"
            value={userInfo.streetAddress}
            onChange={(e) => setUserInfo({ ...userInfo, streetAddress: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="City"
            value={userInfo.city}
            onChange={(e) => setUserInfo({ ...userInfo, city: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="State"
            value={userInfo.state}
            onChange={(e) => setUserInfo({ ...userInfo, state: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Zip"
            value={userInfo.zip}
            onChange={(e) => setUserInfo({ ...userInfo, zip: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <ul className="password-requirements">
            <li className={passwordValidation.minLength ? 'valid' : 'invalid'}>8-14 characters</li>
            <li className={passwordValidation.hasUpperCase ? 'valid' : 'invalid'}>One uppercase letter</li>
            <li className={passwordValidation.hasLowerCase ? 'valid' : 'invalid'}>One lowercase letter</li>
            <li className={passwordValidation.hasNumbers ? 'valid' : 'invalid'}>One number</li>
            <li className={passwordValidation.hasSpecialChar ? 'valid' : 'invalid'}>One special character</li>
          </ul>
          <button type="submit" className="dashboard-blue-button">Complete Profile</button>
          {error && <p className="dashboard-error-message">{error}</p>}
          {message && <p className="dashboard-success-message">{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default BrokerSignUpForm;




