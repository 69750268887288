import React from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom';
import './homepage-getyougoing.css';

function GetYouGoing() {

    return (
        <div id='getyougoing-section' className='getyougoing-section'>
            <p className='getyougoingsection-background'/>
            <div className='getyougoing-top'>
                <h2><span className='getyougoingtop-address'>Address</span>, <span className='getyougoingtop-birthdate'>Birthdate</span>, and <span className='getyougoingtop-contact'>Contact Info</span></h2>
                <p>ABC is all we need to get you where you are going</p>
            </div>
            <div className='getyougoing-buttons'>
                <Link to='/homequotepage1' id='homequotebuttonlink'>
                <button className='getyougoing-homeinsurancebutton'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-home-solid.png?alt=media&token=2858db6e-d684-46fe-b99c-0d102fa2f9ea' />
                    <p>Home Insurance</p>
                    <span className="arrow-right">›</span>
                </button>
                </Link>
                <Link to='/autoquotepage1' id='autoquotebuttonlink'>
                <button className='getyougoing-autoinsurancebutton'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-car-solid.png?alt=media&token=b83eae78-ff87-4472-ad41-4e00b3f0d996' />
                    <p>Auto Insurance</p>
                    <span className='arrow-right'>›</span>
                </button>
                </Link>
                <Link id='bundlequotebuttonlink' to='/bundlequotepage1'>
                <button className='getyougoing-bundlebutton'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-bundle-solid.png?alt=media&token=273a81a8-3f00-4203-a7aa-f4e9a7a01972' />
                    <p>Bundle Discount on Home & Auto</p>
                    <span className='arrow-right'>›</span>
                </button>
                </Link>
            </div>
            </div>
    )
}

export default GetYouGoing;