import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import './lenderMyAccount.css'; // Import the CSS file for styling

const auth = getAuth();
const db = getFirestore();

const LenderMyAccount = () => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    defaultAgent: ''
  });
  const [paymentsOwed, setPaymentsOwed] = useState([]);
  const [agents, setAgents] = useState([]); // State for agents
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpandedPayments, setIsExpandedPayments] = useState(false); // Track itemized expansion for payments
  const [incomeInfo, setIncomeInfo] = useState(null); // Store archived income information
  const [expandedMonthsIncome, setExpandedMonthsIncome] = useState({}); // Track expansion for previous months of lender income
  const [filteredAgents, setFilteredAgents] = useState([]); // State for filtered agents
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [selectedAgent, setSelectedAgent] = useState(''); // State for selected agent

  const navigate = useNavigate(); // Use navigate for routing

  useEffect(() => {
    const fetchUserInfo = async () => {
      setLoading(true);
      setError(null);
      const user = auth.currentUser;
      if (user) {
        console.log('Fetching user information for:', user.uid); // Log current user UID
        try {
          // Fetch lender profile info using the correct 'lenderId' field
          const userDoc = await getDocs(query(collection(db, 'lenders'), where('lenderId', '==', user.uid)));
          if (!userDoc.empty) {
            console.log('User info found:', userDoc.docs[0].data()); // Log user info
            setUserInfo(userDoc.docs[0].data());
          } else {
            console.error('No profile data found for user:', user.uid); // Log if no user data found
            setError('No profile data found');
          }
    
          // Fetch lender payments from lenderMyQuotes
          const paymentsQuery = query(
            collection(db, 'lenderMyQuotes'),
            where('lenderEmail', '==', user.email),
            where('paymentStatus', '==', 'Submitted') // Fetch only payments with status 'Submitted'
          );
          const paymentsSnapshot = await getDocs(paymentsQuery);
          const paymentsList = paymentsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log('Payments owed:', paymentsList); // Log payments owed
          setPaymentsOwed(paymentsList);

          // Fetch archived income history (previous months' payments)
          const incomeQuery = query(
            collection(db, 'lenderMyQuotes'), 
            where('lenderEmail', '==', user.email),
            where('paymentStatus', '==', 'Archived') // Fetch archived payments
          );
          const incomeSnapshot = await getDocs(incomeQuery);
          const incomeList = incomeSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log('Archived income history:', incomeList); // Log archived income
          setIncomeInfo({
            monthlyIncomeArchive: incomeList.reduce((acc, doc) => {
              const monthYearKey = `${new Date(doc.timestamp.seconds * 1000).toLocaleString('default', { month: 'long' })} ${new Date(doc.timestamp.seconds * 1000).getFullYear()}`;
              if (!acc[monthYearKey]) {
                acc[monthYearKey] = {
                  payments: [],
                  totalAmount: 0
                };
              }
              acc[monthYearKey].payments.push(doc);
              acc[monthYearKey].totalAmount += doc.submittedPayment || 0;
              return acc;
            }, {})
          });
          
        } catch (err) {
          console.error('Error fetching profile or payment data:', err); // Log fetch error
          setError('Error fetching profile or payment data');
        } finally {
          setLoading(false);
        }
      } else {
        console.error('User not authenticated'); // Log if user is not authenticated
      }
    };

    const fetchAgents = async () => {
  try {
    const agentsQuery = query(collection(db, 'michiganagentlist'));
    const agentsSnapshot = await getDocs(agentsQuery);
    const agentsList = agentsSnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name,
      email: doc.data().email,
    }));
    setAgents(agentsList); // Set the full agent list
    setFilteredAgents(agentsList); // Initialize filtered agents with all agents
  } catch (err) {
    console.error('Error fetching agents:', err);
  }
    };

    fetchUserInfo();
    fetchAgents(); // Fetch agents when the component mounts
  }, []);

  // Function to parse "Month Year" strings into Date objects
  const parseMonthYear = (monthYearStr) => {
    const [monthName, year] = monthYearStr.split(' ');
    const month = new Date(Date.parse(`${monthName} 1, ${year}`)).getMonth();
    return new Date(year, month);
  };

  // Helper function to toggle expansion for previous months
  const toggleMonth = (monthKey) => {
    setExpandedMonthsIncome((prevState) => ({
      ...prevState,
      [monthKey]: !prevState[monthKey],
    }));
  };

  const handleDefaultAgentChange = async (agentId) => {
    setUserInfo({ ...userInfo, defaultAgent: agentId });
    const user = auth.currentUser;
    try {
      await updateDoc(doc(db, 'lenders', user.uid), { defaultAgent: agentId });
      console.log('Default agent updated in Firestore:', agentId);
    } catch (err) {
      console.error('Error updating default agent:', err);
    }
  };

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    const filteredList = agents.filter(agent =>
      agent.name.toLowerCase().includes(inputValue.toLowerCase()) || agent.email.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredAgents(filteredList);
  };

  const handleAgentSelect = async (agentId) => {
    setUserInfo({ ...userInfo, defaultAgent: agentId });
    setSearchTerm(''); // Clear the search field after selection
    const user = auth.currentUser;
    try {
      await updateDoc(doc(db, 'lenders', user.uid), { defaultAgent: agentId });
      console.log('Default agent updated in Firestore:', agentId);
    } catch (err) {
      console.error('Error updating default agent:', err);
    }
  };

  const handleDropdownChange = async (e) => {
    const selectedAgentId = e.target.value;
    setUserInfo({ ...userInfo, defaultAgent: selectedAgentId });
    const user = auth.currentUser;
    try {
      await updateDoc(doc(db, 'lenders', user.uid), { defaultAgent: selectedAgentId });
      console.log('Default agent updated in Firestore:', selectedAgentId);
    } catch (err) {
      console.error('Error updating default agent:', err);
    }
  };

  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="lender-my-account-container">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Navbar />
        <div className="lender-my-account-container">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <button onClick={() => navigate('/lender-dashboard')} className="dashboard-blue-button">
        Back to Dashboard
      </button>
      <div className="lender-my-account-container">
        <h1>My Account</h1>
        <div className="lender-my-account-info">
          <p><strong>First Name:</strong> {userInfo.firstName}</p>
          <p><strong>Last Name:</strong> {userInfo.lastName}</p>
          <p><strong>Company Name:</strong> {userInfo.companyName}</p>
          <p><strong>Street Address:</strong> {userInfo.streetAddress}</p>
          <p><strong>City:</strong> {userInfo.city}</p>
          <p><strong>State:</strong> {userInfo.state}</p>
          <p><strong>Zip Code:</strong> {userInfo.zip}</p>
          <p><strong>Phone:</strong> {userInfo.phone}</p>

            {/* Searchable agent list */}
        <h2>Assign a Default Agent:</h2>
        <label htmlFor="lenderMyAccount-agent-search">Search for Agent by Name or Email:</label>
        <input
          type="text"
          id="lenderMyAccount-agent-search"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Type to search by name or email"
          autoComplete="off"
          className="lenderMyAccount-input"
        />
        {searchTerm && (
          <ul className="lenderMyAccount-search-results">
            {filteredAgents.map(agent => (
              <li
                key={agent.id}
                onClick={() => handleAgentSelect(agent.id)}
                className={agent.id === userInfo.defaultAgent ? 'lenderMyAccount-selected' : ''}
              >
                {agent.name} ({agent.email})
              </li>
            ))}
          </ul>
        )}

        {/* Dropdown for browsing the full list of agents */}
        <div className="lenderMyAccount-dropdown-container">
          <label htmlFor="lenderMyAccount-agent-dropdown">Browse Agent List:</label>
          <select
            id="lenderMyAccount-agent-dropdown"
            value={userInfo.defaultAgent}
            onChange={handleDropdownChange}
            className="lenderMyAccount-dropdown"
          >
            <option value="">Select an Agent</option>
            {agents.map(agent => (
              <option key={agent.id} value={agent.id}>
                {agent.name} ({agent.email})
              </option>
            ))}
          </select>
        </div>
      </div>


        {/* Section for payments owed to the lender */}
        <div className="section income-section">
          <h2>Current Payments Owed to Me</h2>
          <p>
            <strong>Total Payments Owed:</strong> $
            {paymentsOwed.reduce((acc, payment) => acc + (payment.submittedPayment || 0), 0).toFixed(2)}
          </p>

          {/* Expandable Section for Itemized Payments Owed */}
          <button onClick={() => setIsExpandedPayments(!isExpandedPayments)}>
            {isExpandedPayments ? 'Hide Itemized Payments' : 'View Itemized Payments'}
          </button>

          {isExpandedPayments && (
            <div className="itemized-income">
              <ul>
                {paymentsOwed && paymentsOwed.length > 0 ? (
                  paymentsOwed.map((payment, index) => (
                    <li key={index}>
                      <p>
                        <strong>Agent Name:</strong> {payment.agentName || 'N/A'} (
                        {payment.agentEmail || 'N/A'})
                      </p>
                      <p>
                        <strong>Date Submitted:</strong>{' '}
                        {payment.timestamp ? payment.timestamp.toDate().toLocaleDateString() : 'N/A'}
                      </p>
                      <p>
                        <strong>Client Name:</strong> {payment.homeownerName || 'N/A'}
                      </p>
                      <p>
                        <strong>Submitted Payment:</strong> ${payment.submittedPayment?.toFixed(2) || 0}
                      </p>
                    </li>
                  ))
                ) : (
                  <p>No itemized payments available</p>
                )}
              </ul>
            </div>
          )}
        </div>

        {/* Previous 3 Months Payment History (Owed to Me) */}
        <div className="section income-archive">
          <h2>Previous 3 Months Payment History (Owed to Me)</h2>
          <ul>
            {incomeInfo?.monthlyIncomeArchive &&
            Object.keys(incomeInfo.monthlyIncomeArchive).length > 0 ? (
              Object.keys(incomeInfo.monthlyIncomeArchive)
                .sort((a, b) => parseMonthYear(a) - parseMonthYear(b))
                .slice(-3) // Get the last 3 months after sorting
                .map((monthKey) => {
                  const monthData = incomeInfo.monthlyIncomeArchive[monthKey];
                  const isMonthExpanded = expandedMonthsIncome[monthKey];

                  return (
                    <li key={monthKey}>
                      <strong>{monthKey}:</strong> ${monthData.totalAmount.toFixed(2)}
                      <button
                        className="month-toggle-button"
                        onClick={() => toggleMonth(monthKey)}
                      >
                        {isMonthExpanded ? 'Hide Itemized Payments' : 'Show Itemized Payments'}
                      </button>
                      {isMonthExpanded && (
                        <ul>
                          {monthData.payments.map((payment, index) => (
                            <li key={index}>
                              <p>
                                <strong>Agent Name:</strong> {payment.agentName || 'N/A'} (
                                {payment.agentEmail || 'N/A'})
                              </p>
                              <p>
                                <strong>Date Submitted:</strong>{' '}
                                {payment.timestamp ? payment.timestamp.toDate().toLocaleDateString() : 'N/A'}
                              </p>
                              <p>
                                <strong>Client Name:</strong> {payment.homeownerName || 'N/A'}
                              </p>
                              <p>
                                <strong>Submitted Payment:</strong> ${payment.submittedPayment?.toFixed(2) || 0}
                              </p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })
            ) : (
              <p>No payment history available</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LenderMyAccount;


