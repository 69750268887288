import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './forgotPasswordModal.css';

const db = getFirestore();
const functions = getFunctions(); // Initialize Firebase Functions
const sendPasswordResetEmailCF = httpsCallable(functions, 'sendPasswordResetEmail'); // Cloud Function

const ForgotPasswordModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    try {
      // Check if user exists in Firestore before sending reset email
      const userDoc = await getDoc(doc(db, 'userRoles', email));
      if (userDoc.exists()) {
        await sendPasswordResetEmailCF({ email }); // ✅ Call Cloud Function
        setMessage('✅ Password reset email sent. Please check your inbox.');
      } else {
        setMessage('❌ You do not have access to reset the password.');
      }
    } catch (error) {
      console.error('❌ Error sending password reset email:', error);
      setMessage('❌ Error sending password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-modal">
      <button className="forgot-password-close-button" onClick={onClose}>X</button>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <label>Email Address</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className='send-reset-link' type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send Reset Link'}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ForgotPasswordModal;

