import React from 'react';
import './quoteInfoModal.css';

function QuoteInfoModal({ show, message, onClose }) {
  if (!show) return null;

  return (
    <div className="quote-info-modal-overlay">
      <div className="quote-info-modal">
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default QuoteInfoModal;
