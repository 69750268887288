import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Post from '../Post'; // Import the Post component
import VideoPost from '../VideoPost'; // Import the VideoPost component
import './blog-articles.css';

// Define the list of posts
const postFiles = [
  { name: 'Common Mistakes Teen Drivers Make', file: 'post1.md', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-08-08-at-4.56.05-PM-e1691528204903.png?alt=media&token=c5caefb8-8e1b-419b-bcd6-323b46dfee64', synopsis: 'Learn to recognize them and help your teenager. When your teen takes the wheel, their inexperience can be a problem – for themselves, their passengers and others on the road. Fortunately, most of their beginner’s mistakes can be prevented. Here are some of the most frequent errors teen drivers make, and how you can instill…' },
  { name: 'What Should I Do Now?', file: 'post2.md', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-06-27-at-4.49.58-PM.png?alt=media&token=fddeedde-5697-45ae-a0c4-6ff7f1b2af2d', synopsis: 'Here are the steps you should take after a car accident: Ensure Safety: First and foremost, make sure you and anyone else involved are out of immediate danger. Move to a safe location if possible, away from traffic. Check for Injuries: Assess yourself and others involved in the accident for injuries. If anyone is seriously… ' },
  { name: '21 Things Every Homeowner Needs to Know', file: 'post3.md', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-2.19.16-PM.png?alt=media&token=a6d02e12-91df-438e-b331-ac7840088f22', synopsis: 'While rewarding, being a homeowner can be costly and frustrating if you’re not in the know. Here are 21 essential tips and tricks to save money, solve problems and improve your home. A Leaking Water Heater is a Time Bomb Water heaters sometimes leak from the drain valve or relief valve. Those valves are easy to…'},
  { name: 'The Advantages of Homeownership', file: 'post5.md', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-2.37.46-PM%20(1).png?alt=media&token=e3b2e265-d6d5-4fbf-9f23-f285315d77b5', synopsis: 'Owning a home offers several advantages that make it a desirable option for many individuals and families. Here are the top five advantages of homeownership: Building Equity: One of the primary advantages of homeownership is the opportunity to build equity over time. As you make mortgage payments, you gradually increase your ownership stake in the…'}
];

const videoFiles = [
  { name: '10 Tips for Driving in the Rain', file: 'videopost1', imageUrl: 'https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ftips%20for%20driving%20in%20the%20rain.png?alt=media&token=3900c412-a18d-4198-b220-f0a8e5780698', synopsis: 'Driving safely in the rain requires diligence because wet roads make for hazardous driving conditions. To help keep you and other drivers safe on the road, SJFD’s Firefighter Paramedic Travis Markowski shares ten tips for driving safely in the rain.' }
];

const BlogArticles = () => {
  return (
    <div className='blog-entire-page'>
      <div className='blog-articles-header'>
        <h1>Resources for Home, Auto, Life, and Umbrella Insurance</h1>
      </div>
      <div className='blog-articles-section'>
        <ul>
          {postFiles.map(post => (
            <li className='blog-list-item' key={post.name}>
              <Link to={`/blog/${post.file.replace('.md', '')}`}>
                <img className='blog-list-img' src={post.imageUrl} alt={post.name} />
                <h2 className='blog-list-title'>{post.name}</h2>
                <p className='blog-list-synopsis'>{post.synopsis}</p>
                <button className='blog-list-button'>Read More</button>
              </Link>
            </li>
          ))}
        </ul>
        <Routes>
          <Route path='/:postId' element={<Post />} /> {/* Include Post component for individual posts */}
        </Routes>
      </div>
      <div className='blog-video-library'>
        <h2 className='video-library-header'>Video Library</h2>
        <ul>
          {videoFiles.map(video => (
            <li className='blog-video-item' key={video.name}>
              <Link to={`/video/${video.file}`}>
                <img className='blog-video-img' src={video.imageUrl} alt={video.name} />
                <h2 className='blog-video-title'>{video.name}</h2>
                <p className='blog-video-synopsis'>{video.synopsis}</p>
                <button className='blog-video-button'>Watch Video</button>
              </Link>
            </li>
          ))}
        </ul>
        <Routes>
          <Route path='/video/:videoId' element={<VideoPost />} /> {/* Include VideoPost component for individual video posts */}
        </Routes>
      </div>
    </div>
  );
};

export default BlogArticles;
