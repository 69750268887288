import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, query, collection, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import BrokerDashLenderReferrals from './brokerDashLenderReferrals';
import BrokerDashAssigned from './brokerDashAssigned';
import BrokerDashReferredQuotes from './brokerDashReferredQuotes';
import './brokerDashboard.css';

const auth = getAuth();
const db = getFirestore();

const BrokerDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgentData = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log('No user logged in');
        setError('You must be logged in to view your quotes.');
        setLoading(false);
        return;
      }
  
      try {
        // Fetch roles to confirm access
        const userDoc = await getDoc(doc(db, 'userRoles', user.uid));
        if (!userDoc.exists() || !userDoc.data().roles.brokerLogin) {
          setError('You do not have access to this dashboard.');
          await signOut(auth);
          navigate('/');
          return;
        }
  
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchAgentData();
}, [navigate]);

  
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="broker-dashboard-home-container">
        <div className="dashboard-buttons">
          <button onClick={() => navigate('/brokermyaccount')} id="broker-my-account-button" className="dashboard-blue-button">My Account</button>
          <button onClick={() => navigate('/broker-archived-quotes')} className="dashboard-blue-button">Archived Quotes</button>
          <button onClick={handleSignOut} className="dashboard-blue-button">Sign Out</button>
        </div>
        
<BrokerDashLenderReferrals />
<BrokerDashAssigned />
<BrokerDashReferredQuotes />
      </div>
    </div>
  );
};

export default BrokerDashboard;


