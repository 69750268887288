import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Navbar from '../../Navbar/Navbar';
import './lenderArchivedQuotes.css';

const ArchivedQuotes = ({ role, backToPath }) => {
  const [quotes, setQuotes] = useState([]);
  const [filterText, setFilterText] = useState(''); // State for filter input
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArchivedQuotes = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log('No user logged in');
        setError('You must be logged in to view your archived quotes.');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching archived quotes for user:', user.uid);
        const q = query(
          collection(db, 'lenderMyQuotes'),
          where('uid', '==', user.uid),
          where('archived', '==', true)
        );
        const querySnapshot = await getDocs(q);
        const quotesList = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate()); // Sort by newest
        console.log('Archived quotes fetched:', quotesList);
        setQuotes(quotesList);
      } catch (err) {
        console.error('Error fetching archived quotes:', err);
        setError('Error fetching archived quotes.');
      } finally {
        setLoading(false);
      }
    };

    fetchArchivedQuotes();
  }, []);

  const handleBackToQuotes = () => {
    navigate('/lender-myquotes');
  };

  // Apply the filter to the quotes
  const filteredQuotes = quotes.filter((quote) => {
    const search = filterText.toLowerCase();
    const timestamp = quote.timestamp?.toDate()?.toLocaleString()?.toLowerCase() || '';
    const homeownerName = quote.homeownerName?.toLowerCase() || '';
    const homeownerEmail = quote.homeownerEmail?.toLowerCase() || '';
    const agentName = quote.agentName?.toLowerCase() || '';
    const agentEmail = quote.agentEmail?.toLowerCase() || '';
    const status = quote.status?.toLowerCase() || '';
    const paymentStatus = quote.paymentStatus?.toLowerCase() || '';

    return (
      timestamp.includes(search) ||
      homeownerName.includes(search) ||
      homeownerEmail.includes(search) ||
      agentName.includes(search) ||
      agentEmail.includes(search) ||
      status.includes(search) ||
      paymentStatus.includes(search)
    );
  });

  return (
    <div>
      <Navbar />
      <div className="archived-quotes-container">
        <h2>Archived Quotes</h2>
        <button className='dashboard-blue-button' onClick={handleBackToQuotes}>Back to Quotes</button>
        
        {/* Filter Input */}
        <div className='filter-container'>
          <input
            type="text"
            placeholder="Search archived quotes"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="filter-input"
          />
        </div>

        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {filteredQuotes.length === 0 && !loading && !error && <p>No archived quotes found.</p>}
        {filteredQuotes.length > 0 && (
          <ul>
            {filteredQuotes.map(quote => (
              <li key={quote.id} className="archived-quote-item">
                <div className='archived-quote-details'>
                  <p><strong>Submitted At:</strong> {quote.timestamp?.toDate().toLocaleString()}</p>
                  <p><strong>Homeowner Name:</strong> {quote.homeownerName}</p>
                  <p><strong>Homeowner Email:</strong> {quote.homeownerEmail}</p>
                  <p><strong>Agent Name:</strong> {quote.agentName}</p>
                  <p><strong>Agent Email:</strong> {quote.agentEmail}</p>
                </div>
                <div className='archived-quote-status'>
                  <p>
                    <strong>Status:</strong>{' '}
                    <span
                      className={`status-badge ${quote.status === 'Verified' ? 'status-verified' : 'status-unverified'}`}
                    >
                      {quote.status}
                    </span>
                  </p>
                  <p>
                    <strong>Payment Status:</strong>{' '}
                    <span
                      className={`payment-status-badge ${
                        quote.paymentStatus === 'Paid' ? 'payment-paid' : 'payment-unpaid'
                      }`}
                    >
                      {quote.paymentStatus || (quote.status === 'Verified' ? 'Eligible' : 'Not Eligible')}
                    </span>
                  </p>
                  <p><strong>Submitted Payment:</strong> {quote.submittedPayment ? `$${quote.submittedPayment}` : 'N/A'}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ArchivedQuotes;


