import React, { useEffect, useState } from 'react';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import BrokerAuthModal from '../ForBrokersAccessAuth/brokerAuthModal';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './forBrokersBody.css';
import ForBrokersQuestionnaire from './ForBrokersQuestionnaire/forBrokersQuestionnaire';

function ForBrokersBody() {
  const auth = getAuth();
  const db = getFirestore();
  const [showAuthModal, setShowAuthModal] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, check broker access
        const docRef = doc(db, 'brokerAccess', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data().brokerAccess) {
          setShowAuthModal(false);
        } else {
          setShowAuthModal(true);
        }
      } else {
        // No user is signed in, show the modal
        setShowAuthModal(true);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful
        window.location.href = '/';
      })
      .catch((error) => {
        // An error happened
        console.error('Error signing out: ', error);
      });
  };

  return (
    <div className="for-brokers-body">
      {showAuthModal && <BrokerAuthModal onAuthenticated={() => setShowAuthModal(false)} />}
      {!showAuthModal && (
        <>
          <div className='for-brokers-where-we-win'>
            <h2 className="for-brokers-section-header">Where We (Independent Agents) Win</h2>
            <p className='for-brokers-section-p'>As Independent Agent's, we all provide our customers with a superior experience, greater savings, and better outcomes. 
              However, despite having a way higher close ratio, our volume pales in comparison to captives.</p>
            <h3 className='for-brokers-section-p'>The Insurance Anomoly:</h3>
            <p className='for-brokers-section-p'>Consumers have been conditioned to believe the bigger the company, the better the value. 
              With insurance costs representing a constant financial challenge, consumers are always looking to save money, and there is no shortage of
              Wallstreet funded carriers ready to bombard them with ads promising savings and magical coverage, that often doesn't exist.
              The anomoly with insurance, as you know, is bigger does not equal savings or quality! 
              So where are we winning and where are we losing?
            </p>
            <br />
            <table className='where-we-win-table'>
              <thead>
                <tr>
                  <th className='where-we-win-column-head'>Value to Client</th>
                  <th className='where-we-win-column-head'>Independent Agents</th>
                  <th className='where-we-win-column-head'>Captives, National Brokers</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='where-we-win-row'>Relationship</td>
                  <td className='check-td'><img className='where-we-win-check' src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" alt="check" /></td>
                  <td></td>
                </tr>
                <tr>
                  <td className='where-we-win-row'>Customer Service</td>
                  <td className='check-td'><img className='where-we-win-check' src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" alt="check" /></td>
                  <td></td>
                </tr>
                <tr>
                  <td className='where-we-win-row'>Claims Satisfaction</td>
                  <td className='check-td'><img className='where-we-win-check' src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" alt="check" /></td>
                  <td></td>
                </tr>
                <tr>
                  <td className='where-we-win-row'>Coverage</td>
                  <td className='check-td'><img className='where-we-win-check' src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" alt="check" /></td>
                  <td></td>
                </tr>
                <tr>
                  <td className='where-we-win-row'>Savings</td>
                  <td className='check-td'><img className='where-we-win-check' src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" alt="check" /></td>
                  <td></td>
                </tr>
                <tr>
                <td className='where-we-win-row'>Technology</td>
                  <td></td>
                  <td className='check-td'><img className='where-we-win-check' src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" alt="check" /></td>
                </tr>
                <tr>
                  <td className='where-we-win-row'>Marketing</td>
                  <td></td>
                  <td className='check-td'><img className='where-we-win-check' src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" alt="check" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className='for-brokers-sub-section'>
            <h2 className='for-brokers-section-header'>Changing The Rules Of The Game</h2>
            <p className='for-brokers-section-body'>It doesn't make sense that an inferior client experience with a lower chance of savings would experience up to a 10x increase in volume. 
              Unless you consider that the competition has a billion dollars per year to spend in marketing. As local, Independent Agents, our lane has historically been to leverage our 
              relationships and reputations in the communities we serve. By rising to this challenge we have produced some of the best trained customer service agents and most valuable 
              relationships in any industry. But because of our lack of cohesion, challenging the "big guys" with our respective brands and marketing dollars has 
              been impossible. 
              <br />
              <br />
              Enter <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote. 
              With private equity taking over as many local service industries as they can, we believe the time has come for local, Independent Agents to band together to promote our common interests through a cohesive brand 
              that allows us to leverage our advantageous position in the market.  
        
            </p>
          </div>
          <br />
          <div className='for-brokers-sub-section'>
            <h2 className='for-brokers-section-header'>Who Is The Ideal <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> Broker Partner?</h2>
            <p className='for-brokers-section-body'>Our brokers are best-in-class, local Independent Agents that are committed 
              to providing their customers with a superior client experience. 
              <ol>
                <li>Personal Lines represents at least 40% of their total book of business.</li>
                <li>Have at least 7 total Personal Lines markets (including specialty markets).</li>
                <li>Committed to growing Personal Lines and are willing to implement 
                  the <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> marketing 
                plan.</li>
                <li>Are forward thinking when it comes to technology.</li>
              </ol>
            </p>
          </div>
          <br />
          <div className='for-brokers-sub-section'>
            <h2 className='for-brokers-section-header'>What Does It Mean To Be An <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> Broker 
            Partner?</h2>
            <p className='for-brokers-section-body'>
              Being an <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> broker
               partner means collaborating with your fellow Indepdent Agents by promoting the <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> brand in 
              your community and beyond through:
              <ol>
                <li>Social Media Platforms.</li>
                <li>Guerilla Marketing.</li>
                <li>Physical Marketing.</li>
                <li>Digital Marketing.</li>
              </ol>
            </p>
          </div>
          <br />
          <div className='for-brokers-sub-section'>
            <h2 className='for-brokers-section-header'>What Are The Benefits Of Being An <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> Broker Partner?</h2>
            <p className='for-brokers-section-body'>
              By banding together to promote a singular brand, we are able to greatly multiply
               the reach and effectiveness of our approach, technology, and marketing. The <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> tide 
               is able to raise all of our boats together.  
               <ol>
                <li>Dominate your market by leveraging our digital marketing strategy.</li>
                <li>Access exclusive leads.</li>
                <li>The <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> marketing
                program.</li>
                <li>Technology.</li>
                <li>A compounding return on your marketing dollars - as <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> grows 
                and expands we all experience lift in our respective markets.</li>
                <li>For now, the ability to participate at much more favorable economics, increasig your return.</li>
                <li>To come, access to additional resources and markets.</li>
               </ol>
            </p>
          </div>
          <br />
          <div className='for-brokers-sub-section'>
            <h2 className='for-brokers-section-header'>Who We Are And Who We Are Not</h2>
            <p className='for-brokers-section-body'>
              <ul>
                <li><span style={{fontWeight: 'bold'}} >Who We Are - </span>We are Independent Agency owners, just like you, with over 50 year's of collective experience. 
                  Our focus is driving leads and improving technology for our people and fellow Indepdent Agents.</li>
                <li><span style={{fontWeight: 'bold'}}>Who We Are Not - </span>We are not a private equity group and we are not backed by private equity.</li>
              </ul>
            </p>
          </div>
          <br />
          <div className='for-brokers-sub-section'>
            <h2 className='for-brokers-section-header'>How <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote 
            Makes Money</h2>
            <p className='for-brokers-section-body'>
              The short answer is...we don't. Not yet anyway. We created <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote 
              because we believe sometimes it takes a group of people raising their hand and offering to put in the hardwork 
              to improve the lives of their fellow stakeholders. We are local community members with a history of running family-owned 
              businesses that stretches back to the 1800's. We believe thriving small businesses support healthy community. 
              It is our highest aim to help our fellow Indepdent Agencies compete and thrive in their communities.
              <br />
              <br />
              Ultimately, we are a marketing company that earns a commission on marketing dollars placed, but our first priority 
              is to onboard as many agents as we can and provide them the best value and return for their marketing budgets. In so doing 
              we know that our own agencies will thrive. <span style={{fontWeight: 'bold'}}>To discuss becoming a broker partner, please complete the following survey
                and a company representative will reach out to you shortly. 
              </span>
            </p>
            <ForBrokersQuestionnaire />
          </div>
      {/*<button onClick={handleSignOut} className="sign-out-btn">Sign Out</button>*/}
        </>
      )}
    </div>
  );
}

export default ForBrokersBody;

