import React, { useState, useEffect } from 'react';
import './testimonials-top.css';

function TestimonialsTop() {
    const [scrollIndex, setScrollIndex] = useState(0);

    // Testimonials content
    const testimonials = [
        <div key="1">
            Great service - saved $2,800 over my previous insurance carrier - with better coverage. Agent took the time to ask questions and identified several areas where we were lacking coverage. The options were explained thoroughly and the process was smooth and efficient.<br/><br/>
            <span>Edwin G. customer since 2022</span>
        </div>,
        <div key="2">
            Great people to work with. You have a question they will answer you in a timely manner.<br/><br/>
            <span>Jeff G. customer since 2020</span>
        </div>,
        <div key="3">
            You did a very good job of taking care of my insurances needs and you saved me money from what I was paying.<br/><br/>
            <span>Gary V.</span>    
        </div>,
        <div key="4">
            Been a policy holder for a long time great great service. Very competitive rates. Very good customer service. All the staff is wonderful.<br/><br/>
            <span>Daniel S. customer since 2005</span>
        </div>
        // Add more testimonials as needed
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Increment the scroll index
            setScrollIndex(prevIndex => (prevIndex + 1) % testimonials.length);
        }, 5000); // Adjust the interval timing to match the pause duration

        return () => clearInterval(intervalId);
    }, [testimonials.length]); // Ensure this effect runs whenever testimonials change

    const handlePrev = () => {
        console.log("Previous button clicked");
        setScrollIndex(prevIndex => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };
    
    const handleNext = () => {
        console.log("Next button clicked");
        setScrollIndex(prevIndex => (prevIndex + 1) % testimonials.length);
    };

    return (
        <div className='testimonialstop'>
            <div className="testimonial-container">
                <div className="testimonial-wrapper" style={{transform: `translateX(-${scrollIndex * 100}%)`}}>
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className='testimonial'
                        >
                            {testimonial}
                        </div>
                    ))}
                </div>
            </div>
            <button className="arrow prev" onClick={handlePrev}>{'<'}</button>
            <button className="arrow next" onClick={handleNext}>{'>'}</button>
            <div className="dots">
                {testimonials.map((_, index) => (
                    <span
                        key={index}
                        className={index === scrollIndex ? 'dot active' : 'dot'}
                        onClick={() => setScrollIndex(index)}
                    ></span>
                ))}
            </div>
        <img id='testimonials-stars' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ftestimonial_stars.jpg?alt=media&token=1f54c0f7-0d67-46a7-b2d0-f810711b824c' />
        </div>
    );
}

export default TestimonialsTop;




