import React, { useState, useEffect } from 'react';
import Navbar from '../../../../Navbar/Navbar';
import Bottom from '../../../../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import HomeInsuranceAddress from './homeinsuranceinfoAddress';
import HomeInsuranceBirthdate from './homeinsuranceinfoBirthdate';
import HomeInsuranceContact from './homeinsuranceinfoContact';
import './homeinsuranceinfo.css';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../firebaseConfig'; // adjust the path as needed
import QuoteInfoFileUpload from '../../../quoteInfoFileUpload';
import QuoteInfoModal from '../../../quoteInfoModal';

console.log("Functions instance:", functions);


const RECAPTCHA_SITE_KEY = "6LcDl_QqAAAAAG6P3sU-Mqzc9-Z1Rxxqk_szWAi0"; // Replace with your actual key


function HomeQuotePage1() {
  const initialFormData = {
    streetAddress: '',
    state: '',
    zipCode: '',
    day: '',
    month: '',
    year: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    additionalNotes: '',
    formType: 'homeQuote',
    fileURLs: [], // Initialize as an array
    agentCode: '' // Initialize the agentCode field
  };

  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState({
    isSubmitted: false,
    isSuccess: null,
    errorMessage: null,
  });

  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [documentsUploaded, setDocumentsUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);



  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agentCode = params.get('agentCode');
    if (agentCode) {
      setFormData(prevFormData => ({ ...prevFormData, agentCode }));
    }
  
    // ✅ Load reCAPTCHA script dynamically
    if (!window.grecaptcha) {
      console.log("⚡ Loading reCAPTCHA Enterprise...");
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      script.onload = () => {
        console.log("✅ reCAPTCHA Enterprise script loaded!");
        setRecaptchaLoaded(true);
      };
      script.onerror = () => console.error("🚨 Failed to load reCAPTCHA script!");
      document.body.appendChild(script);
    } else {
      console.log("✅ reCAPTCHA Enterprise is already available");
      setRecaptchaLoaded(true);
    }
  }, []);
  
  
  


  // Firebase functions initialization
  const sendEmail = httpsCallable(functions, 'sendEmail');

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleDocumentUpload = (url) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      fileURLs: [...prevFormData.fileURLs, url]
    }));
    setDocumentsUploaded(true);
  };

  const assignAgentAndLog = httpsCallable(functions, "assignAgentAndLog");



  const handleSubmit = async () => {
    setSubmissionStatus({ isSubmitted: true, isSuccess: null, errorMessage: null });
    setIsLoading(true);
  
    console.log("🔍 Preparing to send formData:", JSON.stringify(formData, null, 2));
  
    try {
      if (!formData || Object.keys(formData).length === 0) {
        throw new Error("🚨 Form data is missing.");
      }
  
      // ✅ Wait for reCAPTCHA to be fully loaded before execution
      if (!recaptchaLoaded || !window.grecaptcha || !window.grecaptcha.enterprise) {
        throw new Error("🚨 reCAPTCHA not loaded.");
      }
  
      // ✅ Execute reCAPTCHA before submitting
      const recaptchaToken = await new Promise((resolve, reject) => {
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action: "submit" })
            .then(token => {
              console.log("✅ reCAPTCHA Token:", token);
              resolve(token);
            })
            .catch(error => {
              console.error("🚨 reCAPTCHA Execution Error:", error);
              reject(error);
            });
        });
      });
  
      if (!recaptchaToken) {
        throw new Error("🚨 Failed to obtain reCAPTCHA token.");
      }
  
      console.log("✅ Sending reCAPTCHA Token to Firebase:", recaptchaToken);
  
      // ✅ Send formData and reCAPTCHA Token to Firebase
      const result = await assignAgentAndLog({ formData, recaptchaToken });
  
      console.log("✅ Agent assigned and logged successfully. Response:", result);
      setSubmissionStatus({ isSubmitted: true, isSuccess: true, errorMessage: null });
      setShowConfirmationModal(true);
      setModalMessage("Your information has been successfully submitted and your ABC Quote agent has been assigned.  Check your email for more information!");
    } catch (error) {
      console.error("❌ Error logging agent:", error);
      setSubmissionStatus({ isSubmitted: true, isSuccess: false, errorMessage: error.message });
      setModalMessage(`Error: ${error.message}`);
      setShowConfirmationModal(true);
    } finally {
      setIsLoading(false);
    }
  };
  


 


  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (currentStep === 3) {
      setShowModal(true); // Show the upload modal before submitting
    } else {
      handleNext();
    }
  };

  const proceedWithSubmission = () => {
    setShowModal(false);
    handleSubmit(); // Submit form data along with file URLs after confirming file upload
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div className="homequotepage1-page">
      <Navbar />
      <div className='homequotepage1-section'>
        <div className='homequotepage1-header'>
          <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-home-solid.png?alt=media&token=2858db6e-d684-46fe-b99c-0d102fa2f9ea' alt="Home Icon"/>
          <h1>Home Insurance Quote</h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          {currentStep === 1 && <HomeInsuranceAddress formData={formData} handleChange={handleChange} />}
          {currentStep === 2 && <HomeInsuranceBirthdate formData={formData} handleChange={handleChange} />}
          {currentStep === 3 && <HomeInsuranceContact formData={formData} handleChange={handleChange} />}

          
          <div className='quotepagebutton-div'>
            {currentStep > 1 && <button className='quotepagebutton' type="button" onClick={handlePrevious}>Previous</button>}
            {currentStep < 3 && <button className='quotepagebutton' type="button" onClick={handleNext}>Next</button>}
            {currentStep === 3 && <button id="submitBtn" className='quotepagebutton' type="submit">Submit</button>}
          </div>
        </form>
      </div>
      <Bottom />

      {showModal && (
        <div className="save-time-money-modal-overlay">
          <div className="save-time-money-modal">
            <button className="save-time-money-close-button" onClick={closeModal}>&times;</button>
            <div className='drag-drop-box'>
              <div className='contact-save-more-money'>
                <br />
                <h3 className="drag-drop-box-title"><span id="happens-next-bold">Before you go...</span></h3>
                <p className='save-time-money-upload-paragraph'>Save time by uploading your current policy documents. <br /> <br /> Or click the blue button below to submit your ABC's and we'll get started on your quotes!</p>
                <details className="save-time-money-details">
                  <summary>Upload Instructions</summary>
                    <ol className="save-time-money-ordered-list">
                      <li>You will be prompted to log in or sign up.</li>
                      <li>Once logged in, upload your files by selecting "Choose Files" or simply drag and drop your documents.</li>
                      <li>Once your files are attached click "Upload".</li>
                      <li>Click the blue submit button.</li>
                    </ol>
                </details>
                <p className='save-time-money-find-help'>Not sure how to find your policy documents? <a href="./decpagehelp" target="_blank" rel="noopener noreferrer">Click here</a> for help.</p>
                </div>
              <QuoteInfoFileUpload onUpload={handleDocumentUpload} />
              <div className="modal-buttons">
                {documentsUploaded ? (
                  <button id="all-done-uploading" onClick={proceedWithSubmission}>All done uploading, please submit</button>
                ) : (
                  <button id="no-thanks-submit" onClick={proceedWithSubmission}>No Thanks, Just Submit</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <QuoteInfoModal show={showConfirmationModal} message={modalMessage} onClose={closeConfirmationModal} />

      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default HomeQuotePage1;




