import React from 'react';
import './lenderSignUpTop.css';


function LenderSignUpTop() {

  
    return (
      <div className="lender-signup-top">
        <img className="lender-signup-top-img" src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ffor-lenders-centered.png?alt=media&token=55f4ab00-b1e9-4f1d-9465-1f4816154dd9' />
      </div>
    );
  }
  
  export default LenderSignUpTop;