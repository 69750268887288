import React, { useState, useEffect, useRef } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage, auth } from '../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import AuthenticationModal from './authenticationModal';
import Login from './login';
import './quoteInfoFileUpload.css';

function QuoteInfoFileUpload({ onUpload }) {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [error, setError] = useState('');
  const [dragOver, setDragOver] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        console.log('User UID on state change:', currentUser.uid);
        setIsModalOpen(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = selectedFiles.filter((file) => isValidFile(file));
    setFiles([...files, ...newFiles]);
    e.target.value = null; // Reset the file input
  };

  const isValidFile = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    return allowedTypes.includes(file.type);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const newFiles = droppedFiles.filter((file) => isValidFile(file));
    setFiles([...files, ...newFiles]);
  };

  const handleUpload = async () => {
    if (!user) {
      setIsModalOpen(true);
      return;
    }

    console.log('Uploading files for user UID:', user.uid); // Log UID for debugging

    const newProgress = {};
    try {
      for (const file of files) {
        const storageRef = ref(storage, `documents/${user.uid}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file, {
          customMetadata: {
            customerId: user.uid,
            customerEmail: user.email,
          }
        });

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            newProgress[file.name] = progressPercent;
            setProgress({ ...newProgress });
          },
          (error) => {
            console.error('Upload failed', error);
            setError('Upload failed');
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              console.log('File available at', downloadURL);
              newProgress[file.name] = 100;
              setProgress({ ...newProgress });

              // Pass the file URL to the parent component
              onUpload(downloadURL);

              // Perform any other actions after successful upload
            } catch (error) {
              console.error('Error getting download URL', error);
              setError('Error processing file upload');
            }
          }
        );

        await uploadTask;
      }
      setFiles([]);
      setError('');
    } catch (error) {
      console.error('Error uploading files', error);
      setError('Error uploading files');
    }
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <>
      <div className="drag-drop-box-header">
        <button id="drag-drop-box-sign-in" onClick={handleUpload}>Log In or Sign Up</button>
        {user && <button className="authButton" id="uploadButtonLogout" onClick={handleLogout}>Logout</button>}
      </div>
      <div
        className={`file-upload-container ${dragOver ? 'drag-over' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          onChange={handleFileChange}
          multiple
          ref={fileInputRef} // Attach the ref to the file input
          style={{ display: 'none' }} // Hide the default file input
        />
        <button id="drag-drop-box-choose-files" onClick={handleCustomButtonClick}>Choose Files</button>
        <button id="drag-drop-box-upload" onClick={handleUpload}>Upload</button>
        <ul id="drag-drop-box-attached">
          {files.map((file, index) => (
            <li id="drag-drop-box-list" key={index}>
              {file.name}
              <div>
                {progress[file.name] !== undefined ? (
                  <span>Progress: {progress[file.name].toFixed(2)}%</span>
                ) : (
                  <span style={{ fontWeight: 'bold' }}>Click the "Upload" button to complete...</span>
                )}
                {progress[file.name] === 100 && <span> - Upload Complete</span>}
              </div>
              <button id="drag-drop-box-remove" onClick={() => handleRemove(index)}>Remove</button>
            </li>
          ))}
        </ul>
        {error && <div className="error">{error}</div>}
        <AuthenticationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Login />
        </AuthenticationModal>
      </div>
    </>
  );
}

export default QuoteInfoFileUpload;









