import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import Navbar from '../../Navbar/Navbar';
import './lenderMyQuotes.css';

const LenderMyQuotes = () => {
  const [quotes, setQuotes] = useState([]);
  const [filterText, setFilterText] = useState(''); // State for filter input
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuotes = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log('No user logged in');
        setError('You must be logged in to view your quotes.');
        setLoading(false);
        return;
      }
    
      try {
        console.log('Fetching quotes for user:', user.uid);
        const q = query(collection(db, 'lenderMyQuotes'), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const quotesList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
    
        // Filter out archived quotes (if the archived field exists)
        const filteredQuotes = quotesList.filter(quote => !quote.archived);
        console.log('Quotes fetched:', filteredQuotes);
        setQuotes(filteredQuotes);
      } catch (err) {
        console.error('Error fetching quotes:', err);
        setError('Error fetching quotes.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuotes();
  }, []);

  // Apply the filter to the quotes
  const filteredQuotes = quotes.filter((quote) => {
    const search = filterText.toLowerCase();
    const timestamp = quote.timestamp?.toDate()?.toLocaleString()?.toLowerCase() || '';
    const homeownerName = quote.homeownerName?.toLowerCase() || '';
    const homeownerEmail = quote.homeownerEmail?.toLowerCase() || '';
    const agentName = quote.agentName?.toLowerCase() || '';
    const agentEmail = quote.agentEmail?.toLowerCase() || '';
    const status = quote.status?.toLowerCase() || '';
    const paymentStatus = quote.paymentStatus?.toLowerCase() || '';

    return (
      timestamp.includes(search) ||
      homeownerName.includes(search) ||
      homeownerEmail.includes(search) ||
      agentName.includes(search) ||
      agentEmail.includes(search) ||
      status.includes(search) ||
      paymentStatus.includes(search)
    );
  });

  const handleRemove = async (quoteId) => {
    try {
      await deleteDoc(doc(db, 'lenderMyQuotes', quoteId));
      setQuotes(quotes.filter(quote => quote.id !== quoteId));
    } catch (err) {
      console.error('Error removing quote:', err);
    }
  };

  const handleArchive = async (quote) => {
    try {
      await updateDoc(doc(db, 'lenderMyQuotes', quote.id), { archived: true });
      setQuotes(quotes.filter(q => q.id !== quote.id));
    } catch (err) {
      console.error('Error archiving quote:', err);
    }
  };

  const handleBackToDashboard = () => {
    navigate('/lender-dashboard');
  };

  return (
    <div>
      <Navbar />
      <div className="lender-my-quotes-container">
        <button className='dashboard-blue-button' onClick={handleBackToDashboard}>Back to Dashboard</button>
        <button className='dashboard-blue-button' onClick={() => navigate('/archived-quotes')}>View Archived Quotes</button>
        <h2>My Quotes</h2>
        <div className='broker-lender-quote-instructions'>
          <h3>Instructions:</h3>
          <ol>
            <li>Once your assigned agent presents their quotes to your client they will "Verify" your quote, updating your "Payment Status" to "Eligible."</li>
            <li>Once your assigned agent have approved your referral payment, your payment status will update to "Paid." Payments are issued the first of each month.</li>
            <li>Once a case is closed you can archive the quote by clicking the "Archive" button. <strong>PLEASE NOTE:</strong> Archived quotes cannot be modified.</li>
            <li>Only delete a quote if it was created in error.</li>
          </ol>
        </div>

        {/* Filter Input */}
        <div className='filter-container'>
          <input
            type="text"
            placeholder="Search quotes"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="filter-input"
          />
        </div>

        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {filteredQuotes.length === 0 && !loading && !error && <p>No quotes found.</p>}
        {filteredQuotes.length > 0 && (
          <ul className='lender-quote-item-ul'>
            {filteredQuotes.map(quote => (
              <li key={quote.id} className="lender-quote-item">
                <div className='lender-quote-details'>
                  <p><strong>Submitted At:</strong> {quote.timestamp?.toDate().toLocaleString()}</p>
                  <p><strong>Homeowner Name:</strong> {quote.homeownerName}</p>
                  <p><strong>Homeowner Email:</strong> {quote.homeownerEmail}</p>
                  <p><strong>Agent Name:</strong> {quote.agentName}</p>
                  <p><strong>Agent Email:</strong> {quote.agentEmail}</p>
                </div>
                <div className='lender-quote-status'>
                  <p>
                    <strong>Status:</strong>{' '}
                    <span className={`status-badge ${quote.status === 'Verified' ? 'status-verified' : 'status-unverified'}`}>
                      {quote.status}
                    </span>
                  </p>
                  <p>
                    <strong>Payment Status:</strong>{' '}
                    <span className={`payment-status-badge ${quote.paymentStatus === 'Paid' ? 'payment-paid' : 'payment-unpaid'}`}>
                      {quote.paymentStatus || (quote.status === 'Verified' ? 'Eligible' : 'Not Eligible')}
                    </span>
                  </p>
                  <p><strong>Submitted Payment:</strong> {quote.submittedPayment ? `$${quote.submittedPayment}` : 'N/A'}</p>
                </div>
                <div className='lender-delete-archive-buttons-section'>
                  <button className='lender-delete-archive-buttons' onClick={() => handleArchive(quote)}>Archive Quote</button>
                  <button className='lender-delete-archive-buttons' onClick={() => handleRemove(quote.id)}>Delete Quote</button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LenderMyQuotes;









