import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import AssignUserRoles from './assignUserRoles';
import CreateBrokerUser from './createUserWithPassword';
import CreateAuthenticatedUser from './createAuthenticatedUser';

const AdminPage = () => {
  const auth = getAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const checkAdminStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult(true);
          setIsAdmin(idTokenResult.claims.admin === true);
        } catch (e) {
          console.error('Error fetching admin claims:', e);
        }
      }
      setLoading(false);
    };

    checkAdminStatus();
  }, [auth]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset any previous error messages

    try {
      await signInWithEmailAndPassword(auth, email, password);
      const idTokenResult = await auth.currentUser.getIdTokenResult(true);
      setIsAdmin(idTokenResult.claims.admin === true);

      if (!idTokenResult.claims.admin) {
        setError('You do not have admin rights.');
      }
    } catch (error) {
      setError('Failed to login. Please check your credentials.');
      console.error('Login error:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Admin Page</h1>
      {auth.currentUser && isAdmin ? (
        <div>
          <p>Welcome, Admin! Here are your controls.</p>
          <br></br>
          <ol>
            <li>everything runs off of the Firebase Authentication database UID.</li>
            <li>If user is not authenticated, Create Authenticated User.</li>
            <li>also need to add the agent to michiganagentlist.</li>
            <li>then using their uid from the auth database, assign their user role to Broker Login using Assign User Roles. </li>
            <li>Then, in the userRoles collection, set their temporaryPassword to true.  We can automate this at some point. </li>
            <li>Provide agent their temp password.  they will prompted to reset it upon logging in.</li>
            <li>if a user is already authenticated you just need to assign them the userRole and set their temporaryPassword to false, unless you want them to create a new password.  probably should.</li>
          </ol>

          <CreateAuthenticatedUser />
          <AssignUserRoles />
          <CreateBrokerUser />
        </div>
      ) : (
        <div>
          <h2>Admin Login</h2>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form onSubmit={handleLogin}>
            <div>
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
          <p>You must log in with admin credentials to access this area.</p>
        </div>
      )}
    </div>
  );
};

export default AdminPage;

/* WORKFLOW FOR ADDING AGENTS:
1:  everything runs off of the Firebase Authentication database UID.
2. If user is not authenticated, Create Authenticated User.
3. also need to add the agent to michiganagentlist.
4. then using their uid from the auth database, assign their user role to Broker Login using Assign User Roles. 
5.  Then, in the userRoles collection, set their temporaryPassword to true.  We can automate this at some point. 
6. Provide agent their temp password.  they will prompted to reset it upon logging in.  
7. if a user is already authenticated you just need to assign them the userRole and set their temporaryPassword to false, unless you want them to create a new password.  probably should.
*/