import React from 'react';
import './paymentCalculationModal.css';

const LenderReferralPaymentModal = ({ isOpen, onClose, onSubmit, quoteId }) => {
  const referralAmount = 20;  // Fixed referral amount of $20 for Mortgage referral

  const handleSubmit = () => {
    if (!quoteId) {
      console.error('Invalid quoteId in LenderReferralPaymentModal');
      return;  // Return early if quoteId is not valid
    }

    onSubmit(referralAmount, quoteId);  // Pass the $20 referral amount and the quoteId
    onClose();  // Close the modal after submission
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="payment-modal-backdrop">
      <div className="payment-modal-content">
        <header className="payment-modal-header">
          <h2>Mortgage Referral Payment</h2>
          <button className="payment-close-button" onClick={onClose}>×</button>
        </header>
        <div className="payment-modal-body">
          <label>
            Referral Payment:
            <input
              type="number"
              value={referralAmount.toFixed(2)}  // Display the fixed amount
              readOnly  // Make the input read-only
              className="payment-input read-only"
            />
          </label>
          <button className="payment-submit-button" onClick={handleSubmit}>
            Submit Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default LenderReferralPaymentModal;
