import React from 'react';
import ReactDOM from 'react-dom/client';
import './autoinsuranceinfoBirthdate.css';


function AutoInsuranceBirthdate({formData, handleChange}) {

  
    return (
      <div className="autoquoteinfo-Birthdate">
      <div className='autoquoteinfo-birthdate-abc'>
      <div className='birthdate-abc-a'>
          <p id='auto-birthdate-abc-a'>A</p>
          <p id='auto-birthdate-abc-address'>Address</p>
        </div>
        <div className='birthdate-abc-b'>
          <p id='auto-birthdate-abc-b'>B</p>
          <p id='auto-birthdate-abc-birthdate'>Birthdate</p>
        </div>
        <div className='birthdate-abc-c'>
          <p id='auto-birthdate-abc-c'>C</p>
          <p id='auto-birthdate-abc-contact'>Contact Info</p>
        </div> 
      </div>
      <div className='birthdateinputs'>
      <input
        type="text"
        id="month"
        name="month"
        value={formData.month}
        onChange={(e) => handleChange('month', e.target.value)}
        placeholder='MM'
      />
      <input
        type="text"
        id="day"
        name="day"
        value={formData.day}
        onChange={(e) => handleChange('day', e.target.value)}
        placeholder='DD'
      />
      <input
        type="text"
        id="year"
        name="year"
        value={formData.year}
        onChange={(e) => handleChange('year', e.target.value)}
        placeholder='YYYY'
      />
      </div>
        

      </div>
    );
  }
  
  export default AutoInsuranceBirthdate;