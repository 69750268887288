import React from "react";
import './communityBody.css';

function CommunityBody() {

    return (
    <div className="Community-Body">
        <h2>Our Mission</h2>
        <p>We believe that by supporting and helping to strengthen our communities we can create a brighter future and better places to live.  When you work with an ABCquote agent you are supporting your local community because we are your local community.  Because our agents are local, they are your fellow stakeholders in your community.  We provide matching funds that go towards making your community a better place to live.</p>
        <br />
        <h2>Insurance is Community</h2>
        <p>If you think about it, the concept of insurance is all about community; everyone contributing a little to help those in need when the worst happens. 
           It is an expense we all share and pay into, we should strive to keep those dollars as close to home as possible! That's what supporting your local, ABCquote agent does.
            Not only are we your fellow community stakeholders, we also provide opportunites for the youth in our communities to get trained
             and pursue their own insurance career.
            Unlike some, that have begun sending your money to private equity groups and Wallstreet funded carriers outside of our communities,
            we take this partnership personally and believe that when you support your local agents the community should be rewarded. 
        </p>
    </div>
    )
}

export default CommunityBody;