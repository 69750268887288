import React from 'react';
import './authenticationModal.css'; // You can create a simple CSS file for basic styling

function AuthenticationModal({ children, isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="authentication-modal-overlay">
      <div className="authentication-modal">
        <button className="authentication-modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
}

export default AuthenticationModal;