import React from 'react';
import ReactDOM from 'react-dom/client';
import './homepage-accordian.css';

function HomepageAccordian() {



  
    return (
<>
<div className='savingscoverageconvenience'>
        <h2 className='savings'>Savings.</h2>
        <h2 className='coverage'>Coverage.</h2>
        <h2 className='convenience'>Convenience.</h2>
</div>
<div className="accordion">
  <div id="right">
    <img className="tablehead" id="logo" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FABCquote_Logo.png?alt=media&token=ccf8ad0a-bfa9-4b4c-8279-560ee539af9f" />
    </div>
    <div id="onlineright">
    <p className="tablehead" id="online">Online</p>
    </div>
  <div>
    <input type="checkbox" name="example_accordion" id="no_call_centers" className="accordion__input" />
    <label htmlFor="no_call_centers" className="accordion__label">No Call Centers
      <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      No one likes working with call centers, it’s frustrating and feels like there is no accountability. That’s why, with ABCquote, the team that sells you your policies is the same team you call when you need help.
      </p>
    </div>
  </div>
  <div>
    <input type="checkbox" name="example_accordion" id="multiple_carriers" className="accordion__input" />
    <label htmlFor="multiple_carriers" className="accordion__label">Multiple Carriers
       <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      The best way to save is to get quotes from 7 or more regional and national carriers. Our agents work with the necessary carriers to make sure you receive the most competitive home and auto quotes in Michigan. That’s how we’re able to save 7 out of 10 people money!      </p>
    </div>
  </div>
  <div>
    <input type="checkbox" name="example_accordion" id="local_agent" className="accordion__input" />
    <label htmlFor="local_agent" className="accordion__label">Local Agent
       <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      When it comes to protecting what matters most, it is important that you have confidence in your agent. Our Independent Agents operate in your area and are your fellow stakeholders in the community.
    </p>
    </div>
  </div>
 <div>
    <input type="checkbox" name="example_accordion" id="licensed_professional" className="accordion__input" />
    <label htmlFor="licensed_professional" className="accordion__label">Licensed Professional
      <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      You wouldn’t let someone without a license do your taxes, don’t let them sell you home and auto insurance! When you work with ABCquote you can rest assured knowing that both our service and sales team are made up of licensed professionals, continually improving their skills to better protect you.      </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="claims_advocacy" className="accordion__input" />
    <label htmlFor="claims_advocacy" className="accordion__label">Claims Advocacy
      <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      When the unthinkable happens you want someone in your corner that you know and trust. Our local agents are here for you, from giving advice to going to bat on your behalf.      </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="annual_review" className="accordion__input" />
    <label htmlFor="annual_review" className="accordion__label">Annual Policy Review
      <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      Buying home and auto insurance isn’t just about checking a box.  It is about having the security and protection you need, when you need it.  One of the biggest mistakes we see people make is paying their premium year after year without spending 15 – 30 minutes with a professional, making sure they have the coverage they need.  That’s why we’re proud to proactively reach out to our customers each year, encouraging them to spend some time making sure everything on their policies is in order.      </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="ease_abc" className="accordion__input" />
    <label htmlFor="ease_abc" className="accordion__label">Ease of ABC's
      <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      Thanks to our latest technology, all we need is your Address, Birthdate, and Contact Info (ABC) to find you 7 or more home and auto insurance quotes from the best local and national carriers available in Michigan.
    </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="no_data_share" className="accordion__input" />
    <label htmlFor="no_data_share" className="accordion__label">No Data Sharing
      <span className="checkmark"><img className="checkmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGreen-Checkmark-e1695662512824.png?alt=media&token=8cd51cb6-1c19-4dd9-b34e-4e3b0aa584c4" />
        </span>
      <span className="xmark"><img className="xmark" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FGrey-x-mark.png?alt=media&token=e08e5c51-cb4f-4b85-b1fd-052f95bace84" />
        </span>
    </label>
    <div className="accordion__content">
      <p>
      We only use your data for the reason you gave it to us, to find you the most competitive home and auto insurance quotes in Michigan.
      </p>
    </div>
  </div>
  </div>
  </>
    );
  }
  
  export default HomepageAccordian;