import React from "react";
import './communityTop.css';

function CommunityTop() {

    return (
    <div className="Community-Top">
        <h1><span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A </span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B </span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>
        <img id="community-top-img" src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fcommunity%20top.png?alt=media&token=20b5842d-9f10-4427-9052-f8ed53fc9dd5" />
        <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A </span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>Better </span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>Community</span></h1>
    </div>
    )
}

export default CommunityTop;