import React, { useState, useEffect } from 'react';
import { getAuth, updatePassword, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import NewCustomerForm from '../LenderLead/lenderLead';
import './lenderDashboard.css'; // Import the CSS file

const auth = getAuth();
const db = getFirestore();

const DashboardHome = () => {
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [password, setPassword] = useState('');
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  });
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRolesDoc = await getDoc(doc(db, 'userRoles', user.uid));
        if (!userRolesDoc.exists() || !userRolesDoc.data().roles.lenders) {
          navigate('/no-access'); // Redirect if the user doesn't have lenders role
          return;
        }

        const userDoc = await getDoc(doc(db, 'lenders', user.uid));
        if (userDoc.exists()) {
          setUserInfo(userDoc.data());
          setIsFormComplete(userDoc.data().verified);
        }
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchUserInfo();
  }, [navigate]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to the abcquote home page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const maxLength = 14;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return {
      minLength: password.length >= minLength && password.length <= maxLength,
      hasUpperCase,
      hasLowerCase,
      hasNumbers,
      hasSpecialChar,
    };
  };

  const passwordValidation = validatePassword(password);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!Object.values(passwordValidation).every(Boolean)) {
      setError('Password does not meet requirements. Please try again.');
      return;
    }
    try {
      await updatePassword(user, password);
      await updateDoc(doc(db, 'lenders', user.uid), {
        ...userInfo,
        verified: true
      });
      setIsFormComplete(true);
      setMessage('Profile updated successfully.');
    } catch (err) {
      setError('Error updating profile. Please try again.');
    }
  };

  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="dashboard-home-container">
          <h1>Loading...</h1>
        </div>
      </div>
    );
  }

  if (!isFormComplete) {
    return (
      <div>
        <Navbar />
        <div className="dashboard-home-container">
          <h1>We're Thrilled You Have Decided To Partner With Us!</h1>
          <h2>Please complete the form below to complete your registration, you will then be taken to your dashboard.</h2>
          <form className="dashboard-home-form" onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder="First Name"
              value={userInfo.firstName}
              onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Last Name"
              value={userInfo.lastName}
              onChange={(e) => setUserInfo({ ...userInfo, lastName: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Company Name"
              value={userInfo.companyName}
              onChange={(e) => setUserInfo({ ...userInfo, companyName: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Street Address"
              value={userInfo.streetAddress}
              onChange={(e) => setUserInfo({ ...userInfo, streetAddress: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="City"
              value={userInfo.city}
              onChange={(e) => setUserInfo({ ...userInfo, city: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="State"
              value={userInfo.state}
              onChange={(e) => setUserInfo({ ...userInfo, state: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Zip"
              value={userInfo.zip}
              onChange={(e) => setUserInfo({ ...userInfo, zip: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Phone (optional)"
              value={userInfo.phone}
              onChange={(e) => setUserInfo({ ...userInfo, phone: e.target.value })}
              autoComplete='tel'
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <ul className="password-requirements">
              <li className={passwordValidation.minLength ? 'valid' : 'invalid'}>8-14 characters</li>
              <li className={passwordValidation.hasUpperCase ? 'valid' : 'invalid'}>One uppercase letter</li>
              <li className={passwordValidation.hasLowerCase ? 'valid' : 'invalid'}>One lowercase letter</li>
              <li className={passwordValidation.hasNumbers ? 'valid' : 'invalid'}>One number</li>
              <li className={passwordValidation.hasSpecialChar ? 'valid' : 'invalid'}>One special character</li>
            </ul>
            <button type="submit" className="dashboard-blue-button">Complete Profile</button>
            {error && <p className="dashboard-error-message">{error}</p>}
            {message && <p className="dashboard-success-message">{message}</p>}
          </form>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="dashboard-home-container">
        <div className="dashboard-buttons">
          <button onClick={() => navigate('/lendermyaccount')} className="lender-dashboard-blue-button">My Account</button>
          <button onClick={() => navigate('/lender-myquotes')} className="lender-dashboard-blue-button">My Quotes</button>
          <button onClick={handleSignOut} className="lender-dashboard-blue-button">Sign Out</button>
        </div>
        <h1>Welcome to Your Dashboard</h1>
        <NewCustomerForm />
      </div>
    </div>
  );
};

export default DashboardHome;



