import React, { useState, useEffect } from 'react';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../firebaseConfig';
import './authenticationModal.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState('');
  const [isEmailLinkSent, setIsEmailLinkSent] = useState(false);
  const [isEmailLinkSignIn, setIsEmailLinkSignIn] = useState(false);

  const actionCodeSettings = {
    url: 'http://localhost:3000/finishSignUp',
    handleCodeInApp: true,
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      setIsEmailLinkSignIn(true);
    }
  }, []);

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setIsEmailLinkSent(true);
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleRegister = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEmailLinkSignIn = async () => {
    try {
      const emailForSignIn = window.localStorage.getItem('emailForSignIn');
      if (emailForSignIn) {
        await signInWithEmailLink(auth, emailForSignIn, window.location.href);
        window.localStorage.removeItem('emailForSignIn');
        setIsEmailLinkSent(false);
        setIsEmailLinkSignIn(false);
        setError('');
      } else {
        setError('No email found for sign-in.');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await sendSignInLinkToEmail(auth, result.user.email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', result.user.email);
      setIsEmailLinkSent(true);
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <div className="loginInputsButtons">
      <p id="loginInputHeader">
        Congratulations! By providing your current policy's declaration's pages your quote will be created faster, with greater accuracy, and with a great chance of saving money! Don't know how to find your declaration's pages? <a href="#">Click Here</a> to learn how.
      </p>
      <input
        className="authInput"
        id="authInputEmail"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        className="authInput"
        id="authInputPassword"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      {error && <div className="error">{error}</div>}
      {isRegistering ? (
        <>
          <button className="authButton" id="authButtonNewUser" onClick={handleRegister}>Complete Sign-Up</button>
          <button className="authButton" onClick={() => setIsRegistering(false)}>Already have an account? Login</button>
        </>
      ) : (
        <>
          <button className="authButton" id="authButtonLogin" onClick={handleLogin}>Login</button>
          <button className="authGoogleButton" id="authButtonGoogle" onClick={handleGoogleSignIn}><span className="google-icon"></span>Sign in with Google</button>
          {isEmailLinkSent && (
            <button onClick={handleEmailLinkSignIn}>Complete Sign-In</button>
          )}
          <button className="authButton" id="authButtonNewUser" onClick={() => setIsRegistering(true)}>New user? Register</button>
        </>
      )}
      <button className="authButton" id="authButtonLogout" onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Login;

