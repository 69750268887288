import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from '../Navbar/Navbar';
import Bottom from '../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import StickyBanner from '../HomePage/HomePageComponents/STICKY BANNER/stickybanner';
import ForBrokersTop from './For Brokers Components/For Brokers Top/forBrokersTop';
import ForBrokersBody from './For Brokers Components/ForBrokersBody/forBrokersBody';


function ForBrokers() {

  
    return (
      <div className="for-brokers">
        <Navbar />
        <ForBrokersTop />
        <ForBrokersBody />
        <Bottom />
        <StickyBanner />
      </div>
    );
  }
  
  export default ForBrokers;