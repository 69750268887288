import React from 'react';
import Navbar from '../../Navbar/Navbar';
import StickyBanner from '../../HomePage/HomePageComponents/STICKY BANNER/stickybanner';
import Bottom from '../../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import BlogArticles from './BlogComponents/blog-articles/blog-articles';



function Blog() {

  
  
    return (
      <div className="Blog">
        <Navbar />
        <BlogArticles />
        <StickyBanner />
        <Bottom />
      </div>
    );
  }
  
  export default Blog;

  /* EXPLANATION OF HOW THE BLOG COMPONENTS WORK:
  1. TO ADD A TEXT BLOG ARTICLE.
    A. CREATE THE MARKDOWN FILE.
    B. ADD IT TO THE postFiles FUNCTION IN THE blog-articles.js FILE.
    C. THE Post.js IS WHAT RENDERS WHEN A SPECIFIC POST IS SELECTED.
  
  2. TO ADD A VIDEO TO THE VIDEO LIBRARY IN THE BLOG:
    A. CREATE THE VIDEO COMPONENT IN THE VIDEOLIBRARY FOLDER, SUCH AS videopost1.js
    B. ADD IT TO THE videoFiles FUNCTION IN THE blog-articles.js FILE.
    C. ADD IT TO THE videoComponents FUNCTION IN THE VideoPost.js FILE.  THIS IS WHAT RENDERS
    WHEN A SPECIFIC VIDEO POST IS SELECTED.
    */