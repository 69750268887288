
import React from 'react';
import ReactDOM from 'react-dom/client';
import './how-it-works-top.css';

function HowItWorksTop() {

  
    return (
      <div className="how-it-works-top">
        <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fairstream_jpg.jpg?alt=media&token=d582ede5-42ff-446e-8569-5c72c18b43ea' />
        <h1>From Point A to Point B with the Coverage you need</h1>

      </div>
    );
  }
  
  export default HowItWorksTop;


