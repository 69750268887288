import React, { useState, useEffect } from 'react';
import { auth, db } from '../../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './BrokerReferredArchivedQuotes.css';

const BrokerReferredArchivedQuotes = ({ filterText }) => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const fetchArchivedQuotes = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to view your archived referred quotes.');
        setLoading(false);
        return;
      }

      try {
        const q = query(
          collection(db, 'agentReferrals'),
          where('agentEmail', '==', user.email),
          where('quoteInfo.referralStatus', '==', 'closed')
        );
        const querySnapshot = await getDocs(q);
        const quotesList = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
        setQuotes(quotesList);
      } catch (err) {
        setError('Error fetching archived referred quotes.');
      } finally {
        setLoading(false);
      }
    };

    fetchArchivedQuotes();
  }, []);

  const filteredQuotes = quotes.filter((quote) => {
    const search = filterText.toLowerCase();
    const timestamp = quote.timestamp?.toDate()?.toLocaleString()?.toLowerCase() || '';
    const agentName = quote.currentAgent?.name?.toLowerCase() || '';
    const agentEmail = quote.currentAgent?.email?.toLowerCase() || '';
    const clientName = quote.quoteInfo?.name?.toLowerCase() || '';
    const clientEmail = quote.quoteInfo?.email?.toLowerCase() || '';
    const closeReason = quote.closeReason?.toLowerCase() || '';
    const writtenCarrier = quote.writtenCarrier?.toLowerCase() || '';
    const unsuccessfulReason = quote.unsuccessfulReason?.toLowerCase() || '';

    return (
      timestamp.includes(search) ||
      agentName.includes(search) ||
      agentEmail.includes(search) ||
      clientName.includes(search) ||
      clientEmail.includes(search) ||
      closeReason.includes(search) ||
      writtenCarrier.includes(search) ||
      unsuccessfulReason.includes(search)
    );
  });

  const renderAttachedFiles = (files) => {
    if (!files || files.length === 0) return 'None';
    return (
      <ul>
        {files.map((fileUrl, index) => (
          <li key={index}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              File {index + 1}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="broker-referred-quote-archive">
      <button
        className="broker-referred-archive-toggle-button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? 'Expand Referred Archived Quotes' : 'Collapse Referred Archived Quotes'}
      </button>
      {!isCollapsed && (
        <ul className="broker-referred-quote-archive-list">
          {filteredQuotes.length === 0 ? (
            <p>No archived referred quotes found.</p>
          ) : (
            filteredQuotes.map(quote => (
              <li key={quote.id} className="broker-referred-archived-quote-item">
                <div className="broker-referred-archived-quote-details">
                  <p><strong>Referred At:</strong> {quote.timestamp?.toDate ? quote.timestamp.toDate().toLocaleString() : 'N/A'}</p>
                  <p><strong>Referring Agent:</strong> {quote.currentAgent?.name || 'N/A'}</p>
                  <p><strong>Agent Email:</strong> {quote.currentAgent?.email || 'N/A'}</p>
                  <p><strong>Client Name:</strong> {quote.quoteInfo?.name || 'N/A'}</p>
                  <p><strong>Client Email:</strong> {quote.quoteInfo?.email || 'N/A'}</p>
                  <p><strong>Client Birthdate:</strong> {quote.quoteInfo?.birthdate || 'N/A'}</p>
                  <p><strong>Client Address:</strong> {`${quote.quoteInfo?.address?.streetAddress || 'N/A'}, ${quote.quoteInfo?.address?.city || 'N/A'}, ${quote.quoteInfo?.address?.state || 'N/A'}, ${quote.quoteInfo?.address?.zipCode || 'N/A'}`}</p>
                  <p><strong>Additional Notes:</strong> {quote.quoteInfo?.additionalNotes || 'N/A'}</p>
                  <p><strong>Attachments:</strong> {renderAttachedFiles(quote.quoteInfo?.attachedFiles)}</p>
                  <p><strong>Agent Attached Files:</strong> {renderAttachedFiles(quote.agentAttachedFiles)}</p>
                  <p><strong>Carriers to Be Quoted:</strong> {quote.carrierCodes ? quote.carrierCodes.join(', ') : 'N/A'}</p>
                  <p><strong>Close Reason:</strong> {quote.closeReason || 'N/A'}</p>
                  {quote.closeReason === 'successful' && (
                    <p><strong>Written Carrier:</strong> {quote.writtenCarrier || 'N/A'}</p>
                  )}
                  {quote.closeReason === 'unsuccessful' && (
                    <p><strong>Unsuccessful Reason:</strong> {quote.unsuccessfulReason || 'N/A'}</p>
                  )}
                </div>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default BrokerReferredArchivedQuotes;



