
import React from 'react';
import ReactDOM from 'react-dom/client';
import './forBrokersTop.css'

function ForBrokersTop() {

  
    return (
      <div className="for-brokers-top">
        <img className="for-brokers-top-img" src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ffor-brokers-full-less-tall.png?alt=media&token=8de00d31-5082-4537-addc-6aa8f9b99c2d' />
      </div>
    );
  }
  
  export default ForBrokersTop;
