import React from 'react';
import ReactDOM from 'react-dom/client';


const VideoPost1 = () => {


return (

    <div className='blog-videopost'>
        <div className='blog-videopost-header'>
            <h1>10 Tips for Driving in the Rain</h1>
        </div>
        <div className='blog-videopost-video'>
        <iframe 
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/seMOxk6AVvY" 
          frameBorder="0" 
        />
        </div>
        <div className='blog-videopost-writeup'>
            <p>Driving safely in the rain requires diligence because wet roads make for hazardous driving conditions. To help keep you and other drivers safe on the road, SJFD’s Firefighter Paramedic Travis Markowski shares ten tips for driving safely in the rain.</p>
        </div>
    </div>
)

}

export default VideoPost1
