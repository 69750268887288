import React, { useState } from 'react';
import './sellLeadModal.css';

function SellLeadModal({ isOpen, onClose, agents, onSell }) {
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  if (!isOpen) return null;

  const handleSell = () => {
    if (!selectedAgentId) {
      // No agent selected, show red text
      setErrorMessage('Please select an agent.');
    } else {
      // Reset any existing error
      setErrorMessage('');
      // Proceed with selling
      onSell(selectedAgentId);
    }
  };

  return (
    <div className="sell-lead-modal-backdrop">
      <div className="sell-lead-modal-content">
        <h2>Sell Lead</h2>
        <p>Select an agent to sell the lead to:</p>

        <ul>
          {agents.map((agent) => (
  <li key={agent.id}>
  <label>
    <div className="agent-info">
      <strong>{agent.agencyName}</strong><br />
      {agent.name} (<em>{agent.email}</em>)<br />
      {agent.captiveCarrier} ({agent.captiveCarrierCode})<br />
      {agent.streetAddress}, {agent.city}, {agent.state} {agent.zipCode}
    </div>

    {/* The radio uses docId as the value */}
    <input
      type="radio"
      name="agent"
      value={agent.id}
      onChange={() => setSelectedAgentId(agent.id)}
    />
  </label>
</li>
          ))}
        </ul>

        <div className="modal-buttons">
          {/* Sell button is always clickable now */}
          <button onClick={handleSell}>
            Sell
          </button>
          <button onClick={onClose}>Cancel</button>
        </div>

        {/* Conditionally render the error message */}
        {errorMessage && (
          <p style={{ color: 'red', marginTop: '1rem' }}>
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
}

export default SellLeadModal;
