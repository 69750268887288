import React from 'react';
import ReactDOM from 'react-dom/client';
import './aboutus-corebeliefs.css';

function AboutUsCoreBeliefs() {

  
    return (
    
    <div className="aboutus-corebeliefs">
            <h2 id='aboutus-corebeliefs-head'>We created <span className='aboutus-corebeliefs-letter' id='aboutus-corebeliefs-A'>A</span><span className='aboutus-corebeliefs-letter' id='aboutus-corebeliefs-B'>B</span><span className='aboutus-corebeliefs-letter' id='aboutus-corebeliefs-C'>C</span>quote based on 3 core beliefs:</h2>
            <br/>
            <ol id='aboutus-corebeliefs-list'>
                <li>Call centers are the worst.</li>
                <br/>
                <li>The best way to save money is by getting quotes from at least 7 different carriers.</li>
                <br/>
                <li>When it comes to protecting what's most important, you should have a licensed professional that can tailor options to meet your specific needs.</li>
            </ol>
    </div>
    
    );
  }
  
  export default AboutUsCoreBeliefs;