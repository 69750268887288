import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Navbar from '../../Navbar/Navbar';
import ForgotPasswordButton from './forgotPasswordButton';
import './lenderLogin.css'; // Assuming you have some CSS for styling

const LenderLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      if (!user.emailVerified) {
        setError('Email not verified. Please check your inbox.');
        return;
      }

      navigate('/lender-dashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Error logging in. Please try again.');
    }
  };

  return (
    <div>
      <Navbar />
      <div className="lender-login-container">
        <h1 id='lender-login-h1'>Welcome Back! <br />Please Log Into Your Dashboard Here</h1>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
      <div className="forgot-password-container">
      <ForgotPasswordButton />
      </div>
    </div>
  );
};

export default LenderLogin;
