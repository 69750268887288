import React from 'react';
import ReactDOM from 'react-dom/client';
import './testimonials-body.css';



function TestimonialsBody() {

  
    return (
      <div className="testimonials-body">  
        <div className='testimonials-body-testimonial'>
            <p className='testimonial-testimonial'>Very customer focused and prices are very competitive. Specifically, the insurance rep calls back and gives me very clear important direction of what package to get. LOVE the agency!!</p>
            <p className='testimonial-name'>Solange M.</p>
            <p className='testimonial-customersince'>Customer Since 2017</p>
        </div>
        <div className='testimonials-body-testimonial'>
            <p className='testimonial-testimonial'>My policies are reviewed each year to ensure I have the coverage I need.</p>
            <p className='testimonial-name'>William H.</p>
            <p className='testimonial-customersince'>Customer Since 2015</p>

        </div>
        <div className='testimonials-body-testimonial'>
            <p className='testimonial-testimonial'>First and foremost, the staff has always been courteous, patient, and genuinely concerned about any issues I have called about. I felt they would try their best to solve any concerns and always found a solution the best to their ability. Shinberg is approachable, someone is always available to talk to you and you are not transferred from person to person or a recording. It appears they find the most affordable policy for an individual.</p>
            <p className='testimonial-name'>Jane H.</p>
            <p className='testimonial-customersince'>Customer Since 2017</p>
        </div>
        <div className='testimonials-body-testimonial'>
            <p className='testimonial-testimonial'>We have always been very pleased with the interaction from your team. We have been a customer for 30+ years. We’ve had a couple of claims due to weather over the years and they went smooth as butter! Thank you!</p>
            <p className='testimonial-name'>David N.</p>
            <p className='testimonial-customersince'>Customer Since 1995</p>
        </div>
        <div className='testimonials-body-testimonial'>
            <p className='testimonial-testimonial'>Todd did a great job, switching both my Home & Auto insurance! Thanks.</p>
            <p className='testimonial-name'>Dan K.</p>
            <p className='testimonial-customersince'>Customer Since 2020</p>        
        </div>
        <div className='testimonials-body-testimonial'>
            <p className='testimonial-testimonial'>I sent my parents to you just recently and they are thrilled. You saved them a ton of money.</p>
            <p className='testimonial-name'>Molly S.</p>
            <p className='testimonial-customersince'>Customer Since 2019</p>
        </div>
        <div className='testimonials-body-testimonial'>
            <p className='testimonial-testimonial'>First and foremost, the staff has always been courteous, patient, and genuinely concerned about any issues I have called about. I felt they would try their best to solve any concerns and always found a solution the best to their ability. It appears they find the most affordable policy for an individual.</p>
            <p className='testimonial-name'>Kathleen M.</p>
            <p className='testimonial-customersince'>Customer Since 2016</p>
        </div>
      </div>
    );
  }
  
  export default TestimonialsBody;