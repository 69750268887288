import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import Spinner from '../../Spinner';
import './referOutModal.css';

// Instead of importing getFunctions locally, import the pre‑initialized instance:
import { functions } from '../../firebaseConfig';

Modal.setAppElement('#root');

const ReferOutModal = ({
  isOpen,
  onClose,
  agents,
  currentAgent,
  selectedQuote,
  onSendReferral,
  setIsModalOpen,
}) => {
  const [selectedCarriers, setSelectedCarriers] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (isOpen) {
      setSelectedCarriers({});
    }
  }, [isOpen]);

  const handleSelectCarrier = (agentId, carrierCode) => {
    agentId = String(agentId).trim(); // Ensure agentId is a trimmed string
    setSelectedCarriers((prevSelected) => {
      const newSelected = {
        ...prevSelected,
        [agentId]: prevSelected[agentId]?.includes(carrierCode)
          ? prevSelected[agentId].filter((code) => code !== carrierCode)
          : [...(prevSelected[agentId] || []), carrierCode],
      };
      console.log('Updated selectedCarriers:', newSelected);
      return newSelected;
    });
  };

  const handleSendReferral = async (agentId) => {
    agentId = String(agentId).trim();

    if (!selectedCarriers[agentId] || selectedCarriers[agentId].length === 0) {
        alert("Please select at least one carrier for this agent before sending the referral.");
        return;
    }

    try {
        setLoading && setLoading(true); // ✅ Only set loading if function exists

        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            alert("You must be logged in to send a referral.");
            return;
        }

        let token;
        try {
            token = await user.getIdToken();
            console.log("🔑 Firebase Auth token:", token);
        } catch (tokenError) {
            console.error("❌ Failed to fetch Firebase token:", tokenError);
            alert("Authentication error. Try refreshing the page.");
            return;
        }

        const payload = {
            currentAgent: { ...currentAgent, agentId: currentAgent.agentId },
            selectedAgents: [agentId],
            selectedCarriers: { [agentId]: selectedCarriers[agentId] },
            quoteInfo: { ...selectedQuote, referralStatus: "Referral Sent" }, // ✅ Ensure `selectedQuote` is defined
        };

        console.log("📨 Sending referral with payload:", JSON.stringify(payload, null, 2));
        console.log("📌 Fetching URL: https://us-central1-abc-quote.cloudfunctions.net/sendReferral");
        console.log("📌 Authorization Header:", `Bearer ${token}`);

        const response = await fetch(
            "https://us-central1-abc-quote.cloudfunctions.net/sendReferral",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // ✅ Include Firebase Auth Token
                },
                body: JSON.stringify(payload),
            }
        );

        const responseData = await response.json();
        console.log("📩 Response from sendReferral:", response.status, responseData);

        if (!response.ok) {
            throw new Error(
                `❌ Failed to send referral. Status: ${response.status} | Error: ${JSON.stringify(responseData)}`
            );
        }

        console.log("✅ Referral Sent Successfully");
        onSendReferral(selectedQuote.id);
        setIsModalOpen(false);
    } catch (error) {
        console.error("❌ Error sending referral:", error);
        alert("An error occurred while sending the referral. Please try again.");
    } finally {
        setLoading && setLoading(false); // ✅ Make sure setLoading is used properly
    }
};

  

  const filterAgents = (agent) => {
    const search = filterText.toLowerCase();

    // Check agent's name
    const name = agent.name?.toLowerCase() || '';
    if (name.includes(search)) return true;

    // Check agent's email
    const email = agent.email?.toLowerCase() || '';
    if (email.includes(search)) return true;

    // Check agent's zip code
    const zip = agent.zip?.toLowerCase() || '';
    if (zip.includes(search)) return true;

    // Check agent's carrier codes
    const carrierCodes = agent.differentCarriers?.map(code => code.toLowerCase()) || [];
    if (carrierCodes.some(code => code.includes(search))) return true;

    return false;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Refer Out Agents"
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
    >
      <h2>Refer Out Agents</h2>

      {/* Filter Input */}
      <input
        type="text"
        placeholder="Search agents by name, email, zip, or carrier code"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        className="filter-input"
      />

      {/* Show the spinner if loading */}
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}

      {agents
        .filter(filterAgents)
        .map((agent) => {
          const agentIdStr = String(agent.agentId || agent.id).trim();
          return (
            <div key={agentIdStr} className="agent-card">
              <h3>{agent.name}</h3>
              <p>Email: {agent.email}</p>
              <p>
                Address: {agent.streetAddress}, {agent.city}, {agent.state}, {agent.zip}
              </p>
              <p>Available Carriers:</p>
              <div className="carrier-list">
                {agent.differentCarriers && agent.differentCarriers.length > 0 ? (
                  agent.differentCarriers.map((carrierCode) => (
                    <label key={carrierCode} className="carrier-label">
                      <input
                        type="checkbox"
                        checked={selectedCarriers[agentIdStr]?.includes(carrierCode) || false}
                        onChange={() => handleSelectCarrier(agentIdStr, carrierCode)}
                      />
                      {carrierCode}
                    </label>
                  ))
                ) : (
                  <p>No different carriers available for this agent.</p>
                )}
              </div>
              <button
                onClick={() => handleSendReferral(agentIdStr)}
                disabled={loading}
                className="modal-button send-button"
              >
                Send Referral
              </button>
            </div>
          );
        })}
      <button onClick={onClose} className="modal-button close-button">
        Close
      </button>
    </Modal>
  );
};

export default ReferOutModal;

