import React, { useState } from 'react';
import './forBrokersQuestionnaire.css';
import { db } from '../../../../firebaseConfig'; // Ensure this path is correct
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore methods
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../firebaseConfig';

const sendBrokerSurveyEmail = httpsCallable(functions, "sendBrokerSurveyEmail");


const ForBrokersQuestionnaire = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    agencyName: '',
    revenueSize: '',
    phoneNumber: '',
    email: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // ✅ Save to Firestore
      const docRef = await addDoc(collection(db, "brokerSurveys"), formData);
      console.log("Document written with ID: ", docRef.id);
  
      // ✅ Send Email via Cloud Function
      await fetch("https://us-central1-abc-quote.cloudfunctions.net/sendBrokerSurveyEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      alert("Survey submitted successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        agencyName: "",
        revenueSize: "",
        phoneNumber: "",
        email: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
      });
    } catch (error) {
      console.error("Error writing document: ", error);
      alert("There was an error submitting the survey: " + error.message);
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="survey-form">
      <h3>For More Information</h3>
      <div>
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          Name of Agency:
          <input
            type="text"
            name="agencyName"
            value={formData.agencyName}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          Revenue Size:
          <select
            name="revenueSize"
            value={formData.revenueSize}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Less than 250,000">Less than 250,000</option>
            <option value="250,000 - 500,000">250,000 - 500,000</option>
            <option value="500,000 - 1,000,000">500,000 - 1,000,000</option>
            <option value="1,000,000 - 2,000,000">1,000,000 - 2,000,000</option>
            <option value="greater than 2,000,000">Greater than 2,000,000</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          Phone Number:
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          Street Address:
          <input
            type="text"
            name="streetAddress"
            value={formData.streetAddress}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          City:
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          State:
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <div>
        <label>
          Zip Code:
          <input
            type="text"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default ForBrokersQuestionnaire;
