import React from 'react';
import ReactDOM from 'react-dom/client';
import './faq-top.css';


function FAQTop() {

  
    return (
      <div className="faq-top">
        <img className='faq-top-img' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fs-e1698331719208.jpg?alt=media&token=05740e78-08ae-439f-a82a-108a5fd1befc' />   
<div className='faq-accordian-header'>
        <h1 className='frequently'>Frequently</h1>
        <h1 className='asked'>Asked</h1>
        <h1 className='questions'>Questions</h1>
</div>
      </div>
    );
  }
  
  export default FAQTop;