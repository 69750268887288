import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from '../../Navbar/Navbar';
import StickyBanner from '../../HomePage/HomePageComponents/STICKY BANNER/stickybanner';
import Bottom from '../../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import FAQTop from './Frequently Asked Questions Components/faq-top/faq-top';
import FAQAccordian from './Frequently Asked Questions Components/faq-accordian/faq-accordian';


function FrequentlyAskedQuestions() {

  
    return (
      <div className="Testimonials">
        <Navbar />
        <FAQTop />
        <FAQAccordian />
        <StickyBanner />
        <Bottom />
      </div>
    );
  }
  
  export default FrequentlyAskedQuestions;