import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../Navbar/Navbar';
import './brokerArchivedQuotes.css';
import BrokerLenderArchivedQuotes from './BrokerLenderArchivedQuotes';
import BrokerAssignedAgentArchivedQuotes from './BrokerAssignedAgentArchivedQuotes';
import BrokerReferredArchivedQuotes from './BrokerReferredArchivedQuotes';

const BrokerArchivedQuotes = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState('');

  const handleBackToDashboard = () => {
    navigate('/broker-dashboard');
  };

  return (
    <div>
      <Navbar />
      <div className="archived-quotes-container">
        <h2>Archived Quotes</h2>
        <button
          id="broker-archived-quotes-blue-button"
          onClick={handleBackToDashboard}
        >
          Back to Dashboard
        </button>

        <div className="filter-container">
          <input
            type="text"
            placeholder="Search archived quotes"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="filter-input"
          />
        </div>

        <h3>Lender Archived Quotes</h3>
        <BrokerLenderArchivedQuotes filterText={filterText} />

        <h3>Assigned Agent Archived Quotes</h3>
        <BrokerAssignedAgentArchivedQuotes filterText={filterText} />

        <h3>Referred Archived Quotes</h3>
        <BrokerReferredArchivedQuotes filterText={filterText} />
      </div>
    </div>
  );
};

export default BrokerArchivedQuotes;

