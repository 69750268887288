import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from '../Navbar/Navbar';
import StickyBanner from '../HomePage/HomePageComponents/STICKY BANNER/stickybanner';
import TestimonialsTop from './TestimonialsComponents/testimonials-top';
import TestimonialsBody from './TestimonialsComponents/testimonials-body/testimonials-body';
import Bottom from '../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';



function Testimonials() {

  
    return (
      <div className="Testimonials">
        <Navbar />
        <TestimonialsTop />
        <TestimonialsBody />
        <StickyBanner />
        <Bottom />
      </div>
    );
  }
  
  export default Testimonials;