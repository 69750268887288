import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  Timestamp
} from 'firebase/firestore';
import './captivePurchasedLeads.css';

const CaptivePurchasedLeads = () => {
  const auth = getAuth();
  const db = getFirestore();
  const [monthlyData, setMonthlyData] = useState({});
  const [expanded, setExpanded] = useState({});
  const [loading, setLoading] = useState(true);

  const getFirstOfMonth = (offset = 0) => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() - offset, 1);
  };

  useEffect(() => {
    const fetchLeads = async () => {
      const user = auth.currentUser;
      if (!user) return;

      setLoading(true);

      const newMonthlyData = {};

      try {
        for (let i = 0; i < 3; i++) {
          const start = getFirstOfMonth(i);
          const end = getFirstOfMonth(i - 1);
          const q = query(
            collection(db, 'soldLeads'),
            where('soldToAgentId', '==', user.uid),
            where('soldAt', '>=', Timestamp.fromDate(start)),
            where('soldAt', '<', Timestamp.fromDate(end))
          );

          const snapshot = await getDocs(q);
          const leads = snapshot.docs.map(doc => doc.data());
          const total = leads.reduce((sum, lead) => sum + (lead.soldPrice || 0), 0);
          const label = start.toLocaleString('default', { month: 'long', year: 'numeric' });

          newMonthlyData[label] = {
            leads,
            total
          };
        }

        setMonthlyData(newMonthlyData);
      } catch (err) {
        console.error('Error fetching sold leads:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, []);

  const toggleExpand = (month) => {
    setExpanded(prev => ({ ...prev, [month]: !prev[month] }));
  };

  if (loading) return <p>Loading sold leads...</p>;

  return (
    <div className="captive-purchased-leads">
      <h2>Leads Purchased</h2>
      {Object.entries(monthlyData).map(([month, data], idx) => (
        <div key={month} className="monthly-section">
          <h3>{month}</h3>
          <p><strong>Total Paid:</strong> ${data.total.toFixed(2)}</p>
          {idx === 0 ? (
            <ul className="lead-list">
              {data.leads.map((lead, index) => (
                <li key={index} className="lead-item">
                  <p><strong>Lead Name:</strong> {lead.leadName || 'N/A'}</p>
                  <p><strong>Lead Email:</strong> {lead.leadEmail || 'N/A'}</p>
                  <p><strong>Sold Price:</strong> ${lead.soldPrice?.toFixed(2) || 0}</p>
                  <p><strong>Date Sold:</strong> {lead.soldAt?.toDate().toLocaleDateString() || 'N/A'}</p>
                  <p><strong>Sold To:</strong> {lead.soldToAgentName || 'N/A'} ({lead.soldToAgentEmail || 'N/A'})</p>
                </li>
              ))}
            </ul>
          ) : (
            <>
              <button className="toggle-button" onClick={() => toggleExpand(month)}>
                {expanded[month] ? 'Hide Details' : 'Show Details'}
              </button>
              {expanded[month] && (
                <ul className="lead-list">
                  {data.leads.map((lead, index) => (
                    <li key={index} className="lead-item">
                      <p><strong>Lead Name:</strong> {lead.leadName || 'N/A'}</p>
                      <p><strong>Lead Email:</strong> {lead.leadEmail || 'N/A'}</p>
                      <p><strong>Sold Price:</strong> ${lead.soldPrice?.toFixed(2) || 0}</p>
                      <p><strong>Date Sold:</strong> {lead.soldAt?.toDate().toLocaleDateString() || 'N/A'}</p>
                      <p><strong>Sold To:</strong> {lead.soldToAgentName || 'N/A'} ({lead.soldToAgentEmail || 'N/A'})</p>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default CaptivePurchasedLeads;
