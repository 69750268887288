// THIS PAGE IS WHAT RENDERS WHEN A SPECIFIC VIDEO BLOG IS CLICKED.

import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../../Navbar/Navbar';
import Bottom from '../../../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import StickyBanner from '../../../HomePage/HomePageComponents/STICKY BANNER/stickybanner';
import VideoPost1 from '../../VideoLibrary/videopost1'; // Import your video component
import './videopost.css';


const VideoPost = () => {
  const { videoId } = useParams();

  // Map videoId to the correct video component
  const videoComponents = {
    'videopost1': VideoPost1, // Ensure the key matches the file name without extension
    // Add other video components here as needed
  };

  const VideoComponent = videoComponents[videoId];

  return (
    <div>
      <Navbar />
      <div className='back-to-blog'>
        <button id='return-to-blogs-button' onClick={() => window.history.back()}>Return to blogs</button>
      </div>
      <div className='post-page'>
        <div className='post-page-video'>
            {VideoComponent ? <VideoComponent /> : <p>Video not found</p>}
        </div>
      </div>
      <Bottom />
      <StickyBanner />
    </div>
  );
};

export default VideoPost;
