import React, { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { auth, db } from '../../firebaseConfig';
import { collection, getDocs, doc, getDoc, Timestamp, setDoc } from 'firebase/firestore';
import LenderQuoteFileUpload from './lenderQuoteFileUpload';
import './lenderLead.css';

const NewCustomerForm = () => {
  const [currentAddress, setCurrentAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });
  const [homeowner1, setHomeowner1] = useState({
    firstName: '',
    lastName: '',
    birthdate: '',
    email: '',
    phone: '',  // Add phone field
  });
  const [homeowner2, setHomeowner2] = useState({
    firstName: '',
    lastName: '',
    birthdate: '',
    email: '',
    phone: '',  // Add phone field
  });
  const [notes, setNotes] = useState('');
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [defaultAgentEmail, setDefaultAgentEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for form submission
  const [uploadLoading, setUploadLoading] = useState(false); // Loading state for file upload
  const [uploadSuccess, setUploadSuccess] = useState(false); // Success state for file upload
  const [uploadedFiles, setUploadedFiles] = useState([]);  // State to store uploaded file URLs

  useEffect(() => {
    const fetchDefaultAgent = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'lenders', user.uid));
          if (userDoc.exists()) {
            const defaultAgentId = userDoc.data().defaultAgent;
            if (defaultAgentId) {
              const agentDoc = await getDoc(doc(db, 'michiganagentlist', defaultAgentId));
              if (agentDoc.exists()) {
                setDefaultAgentEmail(agentDoc.data().email);
                setSelectedAgent(agentDoc.id); // Pre-set the selected agent
                console.log('Default agent pre-set in NewCustomerForm:', agentDoc.data()); // Log for debugging
              }
            }
          }
        } catch (err) {
          console.error('Error fetching default agent:', err);
        }
      }
    };

    const fetchAgents = async () => {
      try {
        const agentsCollection = collection(db, 'michiganagentlist');
        const agentsSnapshot = await getDocs(agentsCollection);
        const agentsList = agentsSnapshot.docs.map(doc => ({
          agentId: doc.id,
          name: doc.data().name,
          email: doc.data().email
        }));
        setAgents(agentsList);
      } catch (err) {
        console.error('Error fetching agents:', err);
      }
    };

    fetchDefaultAgent();
    fetchAgents();
  }, []);

  const sendLenderLeadEmail = async (formData) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        const token = await user.getIdToken(); // ✅ Get Auth Token

        const response = await fetch("https://us-central1-abc-quote.cloudfunctions.net/sendLenderLeadEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`, // ✅ Attach Authentication Token
            },
            body: JSON.stringify({ formData }),
        });

        if (!response.ok) {
            throw new Error(`Error: ${await response.text()}`);
        }

        console.log("✅ Lender lead email sent successfully.");
    } catch (error) {
        console.error("❌ Error sending lender lead email:", error);
    }
};


const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);

  const user = auth.currentUser;
  if (!user) {
    setError("You must be logged in to submit a lead.");
    setLoading(false);
    return;
  }

  let agentEmail = null;

  // ✅ Step 1: Assign agent manually if selected (excluding "Assign By Zip Code")
  if (selectedAgent && selectedAgent !== "Assign By Zip Code") {
    agentEmail = agents.find(agent => agent.agentId === selectedAgent)?.email;
  }

  // ✅ Step 2: If "Assign By Zip Code" is selected, call Firebase function for round-robin assignment
  if (!agentEmail && selectedAgent === "Assign By Zip Code") {
    try {
      const assignAgentFunction = httpsCallable(functions, "assignAgentForLender");
      const result = await assignAgentFunction({ zipCode: currentAddress.zip });

      if (result.data?.email) {
        agentEmail = result.data.email;
      }
    } catch (error) {
      console.error("Error during round-robin assignment:", error);
    }
  }

  // ✅ Step 3: If no agent is assigned, fall back to the default agent
  if (!agentEmail) {
    agentEmail = defaultAgentEmail;
  }

  // ❌ Step 4: Error handling if no agent is assigned
  if (!agentEmail) {
    setError("Agent email not found. Please select an agent or check the zip code.");
    setLoading(false);
    return;
  }

  if (!homeowner1.email) {
    setError("Homeowner 1 email is required.");
    setLoading(false);
    return;
  }

  // ✅ Step 5: Construct form data
  const formData = {
    currentAddress,
    newAddress,
    homeowner1,
    homeowner2,
    notes,
    selectedAgent: selectedAgent === "Assign By Zip Code" ? "" : selectedAgent, // ✅ Always store agent ID, not email
    lenderEmail: user.email,
    uid: user.uid,
    timestamp: Timestamp.now(),
    attachedFiles: uploadedFiles,
    zipCode: currentAddress.zip,
    brokerArchived: false,
    archiveReason: "",
    writtenCarrier: "",
  };

  try {
    // ✅ Step 6: Save to Firestore
    const newLeadRef = doc(collection(db, "lenderLeads"));
    await setDoc(newLeadRef, formData);

    const newQuoteRef = doc(collection(db, "lenderMyQuotes"), newLeadRef.id);
    await setDoc(newQuoteRef, {
      agentEmail,
      agentName: selectedAgent ? agents.find(agent => agent.agentId === selectedAgent)?.name : "",
      homeownerEmail: homeowner1.email,
      homeownerName: `${homeowner1.firstName} ${homeowner1.lastName}`,
      lenderEmail: user.email,
      uid: user.uid,
      timestamp: Timestamp.now(),
      submittedPayment: 0,
    });

    // ✅ Step 7: Call Firebase Cloud Function for Email Notification
    const token = await user.getIdToken();

    const response = await fetch("https://us-central1-abc-quote.cloudfunctions.net/sendLenderLeadEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ formData }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || response.statusText);
    }

    // ✅ Step 8: Show success message and reset form
    alert("Customer added successfully");

    setCurrentAddress({ street: "", city: "", state: "", zip: "" });
    setNewAddress({ street: "", city: "", state: "", zip: "" });
    setHomeowner1({ firstName: "", lastName: "", birthdate: "", email: "", phone: "" });
    setHomeowner2({ firstName: "", lastName: "", birthdate: "", email: "", phone: "" });
    setNotes("");
    setUploadedFiles([]);

  } catch (error) {
    setError(`Error adding customer: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

  
  
const handleUpload = (files) => {
  setUploadedFiles(prevFiles => [...prevFiles, ...files]); // Store uploaded files in state
};

  const handleChange = (e, section) => {
    const { name, value } = e.target;
    if (section === 'currentAddress') {
      setCurrentAddress({ ...currentAddress, [name]: value });
    } else if (section === 'newAddress') {
      setNewAddress({ ...newAddress, [name]: value });
    } else if (section === 'homeowner1') {
      setHomeowner1({ ...homeowner1, [name]: value });
    } else if (section === 'homeowner2') {
      setHomeowner2({ ...homeowner2, [name]: value });
    } else if (section === 'notes') {
      setNotes(value);
    }
  };

  return (
    <div className="new-customer-form-container">
      <form className="new-customer-form" onSubmit={handleSubmit}>
        <h2>Submit Customer for Quote</h2>

        {error && <p className="error">{error}</p>}

        <div className="lender-quote-address-sections">
          <div id="lender-quote-current-address" className="lender-quote-submit-section">
            <div className="lender-quote-section-label">Current Address</div>
            <input className='lender-quote-input'
              type="text"
              placeholder="Current Street Address"
              name="street"
              value={currentAddress.street}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="Current City"
              name="city"
              value={currentAddress.city}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="Current State"
              name="state"
              value={currentAddress.state}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="Current Zip Code"
              name="zip"
              value={currentAddress.zip}
              onChange={(e) => handleChange(e, 'currentAddress')}
              required
            />
          </div>

          <div id="lender-quote-new-address" className="lender-quote-submit-section">
            <div className="lender-quote-section-label">New Address</div>
            <input className='lender-quote-input'
              type="text"
              placeholder="New Street Address"
              name="street"
              value={newAddress.street}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="New City"
              name="city"
              value={newAddress.city}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="New State"
              name="state"
              value={newAddress.state}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
            <input className='lender-quote-input'
              type="text"
              placeholder="New Zip Code"
              name="zip"
              value={newAddress.zip}
              onChange={(e) => handleChange(e, 'newAddress')}
              required
            />
          </div>
        </div>

        <div className="lender-quote-homeowner-sections">
        <div className="lender-quote-submit-section">
  <div className="lender-quote-section-label">Homeowner 1</div>
  <input className='lender-quote-input'
    type="text"
    placeholder="First Name"
    name="firstName"
    value={homeowner1.firstName}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <input className='lender-quote-input'
    type="text"
    placeholder="Last Name"
    name="lastName"
    value={homeowner1.lastName}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <label>Date of Birth</label>
  <input className='lender-quote-input'
    type="date"
    name="birthdate"
    value={homeowner1.birthdate}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <input className='lender-quote-input'
    type="email"
    placeholder="Email"
    name="email"
    value={homeowner1.email}
    onChange={(e) => handleChange(e, 'homeowner1')}
    required
  />
  <input className='lender-quote-input'
    type="tel"
    placeholder="Phone Number"
    name="phone"
    value={homeowner1.phone}
    onChange={(e) => handleChange(e, 'homeowner1')}  // Add phone number input
    required
  />
</div>

<div className="lender-quote-submit-section">
  <div className="lender-quote-section-label">Homeowner 2</div>
  <input className='lender-quote-input'
    type="text"
    placeholder="First Name"
    name="firstName"
    value={homeowner2.firstName}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <input className='lender-quote-input'
    type="text"
    placeholder="Last Name"
    name="lastName"
    value={homeowner2.lastName}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <label>Date of Birth</label>
  <input className='lender-quote-input'
    type="date"
    name="birthdate"
    value={homeowner2.birthdate}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <input className='lender-quote-input'
    type="email"
    placeholder="Email"
    name="email"
    value={homeowner2.email}
    onChange={(e) => handleChange(e, 'homeowner2')}
  />
  <input className='lender-quote-input'
    type="tel"
    placeholder="Phone Number"
    name="phone"
    value={homeowner2.phone}
    onChange={(e) => handleChange(e, 'homeowner2')}  // Add phone number input
  />
</div>

        </div>

        <textarea
          className="textarea"
          placeholder="Additional Notes"
          value={notes}
          onChange={(e) => handleChange(e, 'notes')}
        />

        <div className="agent-selection">
          <label htmlFor="agent">Select Agent for this Quote (go to "My Account" to set default agent):</label>
          <select
            id="agent"
            value={selectedAgent}
            onChange={(e) => setSelectedAgent(e.target.value)}
            required
          >
            <option value="" disabled>Select an agent</option>
            {agents.map(agent => (
              <option key={agent.agentId} value={agent.agentId}>
                {agent.name} ({agent.email})
              </option>
            ))}
          </select>
        </div>

        <LenderQuoteFileUpload
          onUpload={handleUpload}
          selectedAgentEmail={agents.find(agent => agent.agentId === selectedAgent)?.email || defaultAgentEmail}
        />

        {uploadLoading && <div className="loader">Uploading...</div>} {/* Show loader during upload */}

        <button type="submit" className="lender-lead-submit-button" disabled={loading}>
          {loading ? <div className="loader">Submitting...</div> : 'Submit Customer for Quote'}
        </button>
      </form>
    </div>
  );
};

export default NewCustomerForm;








