import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, doc, updateDoc, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { storage, auth } from '../../firebaseConfig'; // Adjust the import path as needed
import Modal from 'react-modal';
import './agentFileUpload.css';

Modal.setAppElement('#root');

const AgentFileUpload = ({ quote, onUploadComplete, onClose, collectionName }) => {
  const db = getFirestore();
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [error, setError] = useState('');
  const [dragOver, setDragOver] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef(null);

  // Helper function to extract file name from URL
  const getFileNameFromURL = (url) => {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const decodedPath = decodeURIComponent(pathname);
      const parts = decodedPath.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    } catch (error) {
      console.error('Error extracting file name from URL:', error);
      return 'Unknown File';
    }
  };

  // Helper function to extract storage path from download URL
  const getStoragePathFromURL = (url) => {
    try {
      const urlObj = new URL(url);
      const path = urlObj.pathname;
      const pathParts = path.split('/o/');
      if (pathParts.length > 1) {
        const storagePath = decodeURIComponent(pathParts[1]); // Decode %2F to /
        return storagePath; // Return storage path with URL-encoded filename
      } else {
        return '';
      }
    } catch (error) {
      console.error('Error extracting storage path from URL:', error);
      return '';
    }
  };

  // Load existing files from Firestore when component mounts
  useEffect(() => {
    const loadExistingFiles = async () => {
      try {
        const quoteRef = doc(db, collectionName, quote.id);
        const quoteDoc = await getDoc(quoteRef);
        if (quoteDoc.exists()) {
          const existingFiles = quoteDoc.data().agentAttachedFiles || [];
          // Map over existingFiles to ensure each has 'url', 'name', and 'storagePath'
          const filesWithMetadata = existingFiles.map((fileItem) => {
            if (typeof fileItem === 'string') {
              // If fileItem is a string (url), extract storagePath from URL
              const storagePath = getStoragePathFromURL(fileItem);
              const name = getFileNameFromURL(fileItem);
              return {
                url: fileItem,
                name: name,
                storagePath: storagePath,
              };
            } else {
              // Ensure fileItem has 'name' and 'storagePath', derive if missing
              return {
                ...fileItem,
                name: fileItem.name || getFileNameFromURL(fileItem.url),
                storagePath: fileItem.storagePath || getStoragePathFromURL(fileItem.url),
              };
            }
          });
          setFiles(filesWithMetadata);
        }
      } catch (error) {
        console.error('Error loading existing files:', error);
      }
    };

    loadExistingFiles();
  }, [db, collectionName, quote.id]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = selectedFiles
      .filter((file) => isValidFile(file))
      .map((file) => ({ file, name: file.name }));
    setFiles([...files, ...newFiles]);
    e.target.value = null; // Reset the file input
  };

  const isValidFile = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    return allowedTypes.includes(file.type);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const newFiles = droppedFiles
      .filter((file) => isValidFile(file))
      .map((file) => ({ file, name: file.name }));
    setFiles([...files, ...newFiles]);
  };

  const handleUpload = async () => {
    if (!quote || files.length === 0) return;

    setUploadLoading(true);
    setUploadSuccess(false);

    const newProgress = {};
    try {
      const uploadPromises = files.map((fileItem) => {
        return new Promise(async (resolve, reject) => {
          if (fileItem.url) {
            // File already uploaded
            resolve({ url: fileItem.url, storagePath: fileItem.storagePath, name: fileItem.name });
          } else {
            const file = fileItem.file;
            const storageRef = ref(storage, `agentFiles/${quote.id}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                newProgress[file.name] = progressPercent;
                setProgress({ ...newProgress });
              },
              (error) => {
                console.error('Upload failed', error);
                setError('Upload failed');
                reject(error);
              },
              async () => {
                try {
                  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                  const storagePath = uploadTask.snapshot.ref.fullPath; // Correct storage path with URL-encoded filename

                  console.log('File uploaded:', {
                    name: file.name,
                    url: downloadURL,
                    storagePath: storagePath,
                  });

                  // Update the fileItem with the URL and storage path
                  fileItem.url = downloadURL;
                  fileItem.storagePath = storagePath;
                  fileItem.name = file.name; // Store the name
                  resolve({ url: downloadURL, storagePath, name: file.name });
                } catch (error) {
                  console.error('Error getting download URL', error);
                  setError('Error processing file upload');
                  reject(error);
                }
              }
            );
          }
        });
      });

      const uploadedFiles = await Promise.all(uploadPromises);

      // Update Firestore with the new list of file objects
      const quoteRef = doc(db, collectionName, quote.id);
      await updateDoc(quoteRef, {
        agentAttachedFiles: uploadedFiles.map((file) => ({
          url: file.url,
          name: file.name,
          storagePath: file.storagePath,
        })),
      });

      // Update the files state
      setFiles(uploadedFiles);

      setError('');
      setUploadLoading(false);
      setUploadSuccess(true);

      if (onUploadComplete) {
        onUploadComplete();
      }

      // Clear progress after a delay
      setTimeout(() => {
        setProgress({});
      }, 2000);
    } catch (error) {
      console.error('Error uploading files', error);
      setError('Error uploading files');
      setUploadLoading(false);
    }
  };

  const handleRemove = async (index) => {
    const fileItem = files[index];
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    // Remove from storage and update Firestore if the file was already uploaded
    if (fileItem.url) {
      try {
        const storagePath = fileItem.storagePath; // Use the extracted storagePath
        console.log('Attempting to delete file with storagePath:', storagePath);
        const fileRef = ref(storage, storagePath);
        await deleteObject(fileRef);

        const quoteRef = doc(db, collectionName, quote.id);
        await updateDoc(quoteRef, {
          agentAttachedFiles: arrayRemove({
            url: fileItem.url,
            name: fileItem.name,
            storagePath: fileItem.storagePath,
          }),
        });
      } catch (error) {
        console.error('Error removing file:', error);
      }
    }
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Upload Files"
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
    >
      <h2 className="modal-header">Upload Agent Files</h2>
      <div
  className={`file-upload-container ${dragOver ? 'drag-over' : ''}`}
  onDragOver={handleDragOver}
  onDragEnter={handleDragEnter}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
>
        <p className="upload-instruction">
          Drag and drop files here, or click "Choose Files" to select files. Once files are chosen, press Upload to add them.
        </p>
        <input
  type="file"
  onChange={handleFileChange}
  multiple
  ref={fileInputRef}
  className="agent-upload-file-input" // Add this class for CSS styling
/>
        <div className="button-group">
          <button type="button" onClick={handleCustomButtonClick} className="modal-button choose-button">
            Choose Files
          </button>
          <button
            type="button"
            onClick={handleUpload}
            disabled={uploadLoading || files.length === 0}
            className="modal-button upload-button"
          >
            Upload
          </button>
        </div>
        {files.length > 0 && (
          <ul className="file-list">
            {files.map((fileItem, index) => (
              <li key={index} className="file-item">
                <a
                  href={fileItem.url || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-link"
                >
                  {fileItem.name || fileItem.file.name || `File ${index + 1}`}
                </a>
                <div className="file-status">
                  {progress[fileItem.name || fileItem.file.name] !== undefined ? (
                    <span>
                      Progress: {progress[fileItem.name || fileItem.file.name].toFixed(2)}%
                    </span>
                  ) : fileItem.url ? (
                    <span className="status-uploaded"> - Uploaded</span>
                  ) : (
                    <span className="status-ready"> Ready to upload</span>
                  )}
                  {progress[fileItem.name || fileItem.file.name] === 100 && (
                    <span className="status-complete"> - Upload Complete</span>
                  )}
                </div>
                <button onClick={() => handleRemove(index)} className="modal-button remove-button">
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
        {uploadLoading && <div className="loader">Uploading...</div>}
        {uploadSuccess && (
          <div className="success-message">
            Upload successful!
          </div>
        )}
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}
      </div>
      <button onClick={onClose} className="modal-button close-button">
        Close
      </button>
    </Modal>
  );
};

export default AgentFileUpload;





