import React, { useState, useEffect, useRef } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage, auth } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import AuthenticationModal from '../../Quote Info/authenticationModal';
import Login from '../../Quote Info/login';

function LenderQuoteFileUpload({ onUpload, selectedAgentEmail }) {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [fileUrls, setFileUrls] = useState([]);  // State to store file URLs (if needed locally)
  const [error, setError] = useState('');
  const [dragOver, setDragOver] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = selectedFiles.filter((file) => isValidFile(file));
    setFiles([...files, ...newFiles]);
    e.target.value = null; // Reset the file input
  };

  const isValidFile = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    return allowedTypes.includes(file.type);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const newFiles = droppedFiles.filter((file) => isValidFile(file));
    setFiles([...files, ...newFiles]);
  };

  const handleUpload = async () => {
    if (!user) {
        setIsModalOpen(true);
        return;
    }

    setUploadLoading(true);
    setUploadSuccess(false);

    try {
        const uploadedFileUrls = await Promise.all(files.map(async (file) => {
            const storageRef = ref(storage, `documents/${user.uid}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    null,
                    reject,
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    }
                );
            });
        }));

        setFileUrls(prevUrls => [...prevUrls, ...uploadedFileUrls]);
        setFiles([]);
        setError('');
        setUploadLoading(false);
        setUploadSuccess(true);

        // ✅ Pass the uploaded file URLs to lenderLead
        onUpload(uploadedFileUrls);

    } catch (error) {
        console.error('Error uploading files', error);
        setError('Error uploading files');
        setUploadLoading(false);
    }
};


  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="drag-drop-box-header">
        <button id="drag-drop-box-sign-in" onClick={handleUpload}>Upload Files</button>
        {user && (
          <button className="authButton" id="uploadButtonLogout" onClick={() => auth.signOut()}>
            Logout
          </button>
        )}
      </div>
      <div
        className={`file-upload-container ${dragOver ? 'drag-over' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          onChange={handleFileChange}
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <button type="button" id="drag-drop-box-choose-files" onClick={handleCustomButtonClick}>
          Choose Files
        </button>
        <button type="button" id="drag-drop-box-upload" onClick={handleUpload}>
          Upload
        </button>
        <ul id="drag-drop-box-attached">
          {files.map((file, index) => (
            <li key={index}>
              {file.name}
              <div>
                {progress[file.name] !== undefined ? (
                  <span>Progress: {progress[file.name].toFixed(2)}%</span>
                ) : (
                  <span style={{ fontWeight: 'bold' }}>Click the "Upload" button to complete...</span>
                )}
                {progress[file.name] === 100 && <span> - Upload Complete</span>}
              </div>
              <button onClick={() => handleRemove(index)}>Remove</button>
            </li>
          ))}
        </ul>
        {uploadLoading && <div>Uploading...</div>}
        {uploadSuccess && <div>Upload successful!</div>}
        {error && <div>{error}</div>}
        <AuthenticationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Login />
        </AuthenticationModal>
      </div>
    </>
  );
}

export default LenderQuoteFileUpload;






