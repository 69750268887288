import React from 'react';
import ReactDOM from 'react-dom/client';
import './aboutus-plainsight.css';

function AboutUsPlainSight() {

  
    return (
    
    <div className="aboutus-plainsight">
        <h2 id='aboutus-plainsight-head'>Hiding in Plain Sight</h2>
        <p id='aboutus-plainsight-body'>For too long Independent Agents have been losing the technology race to Wall Street-funded carriers who are more beholden to their shareholders than they are to their policyholders. These carriers have failed to provide multiple carrier options, personalized coverage, and the professional guidance and service available through the local, independent agent. Studies show that while 74% of consumers start their insurance shopping online, only 25% end up making their purchase online. This reinforced what we already knew, while customers want convenience, it cannot be at the expense of receiving a quality product.

        <br /><br />Through our decades of experience, we have developed a rigorous set of standards we use to select our Independent Agent Partners to ensure that you receive the best price available and have an agent you can trust to help you for years to come.  Our Independent Agents represent the best national and regional carriers and work for you, not any one particular carrier.  They have local knowledge and care about their communities.  Since they work for you and not the carrier they are able to advise you conflict-free while offering additional services such as coverage reviews and advocacy at the time of a claim, when you need help and guidance the most.
        </p>
        <div className='aboutus-plainsight-img'>
            <img id='aboutus-plainsight-img1'  src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-2.21.26-PM-1.png?alt=media&token=10aacb6a-ed91-4bf0-b841-9f7bc02bc0c4' />
            <img id='aboutus-plainsight-img2'  src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-11-at-11.42.33-AM.png?alt=media&token=e8956719-55cb-450c-8de0-b71c990f5a31'/>
            <img id='aboutus-plainsight-img3'  src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-2.37.46-PM%20(1).png?alt=media&token=e3b2e265-d6d5-4fbf-9f23-f285315d77b5' />
        </div>
    </div>
    
    );
  }
  
  export default AboutUsPlainSight;