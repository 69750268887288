import React from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom';
import './stickybanner.css';

function StickyBanner() {

    return (
        <div className='stickybanner'>
        <Link id="navgetaquote" to="/getaquote">
            <button className="btn-white">Get a Quote ›</button>
        </Link>
        </div>
    )
}

export default StickyBanner;