import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';
import './BrokerAssignedAgentArchivedQuotes.css';

const BrokerDashAssignedAgentQuotesArchive = ({ filterText }) => {
  const auth = getAuth();
  const db = getFirestore();
  const [quotes, setQuotes] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const assignedAgentsQuery = query(
      collection(db, 'assignedAgents'),
      where('agentEmail', '==', user.email),
      where('brokerArchived', '==', true)
    );

    const unsubscribe = onSnapshot(assignedAgentsQuery, (snapshot) => {
      const quotesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQuotes(quotesData);
    });

    return () => unsubscribe();
  }, [auth, db]);

  const filteredQuotes = quotes.filter((quote) => {
    const search = filterText.toLowerCase();
    return (
      quote.agentName?.toLowerCase().includes(search) ||
      quote.agentEmail?.toLowerCase().includes(search) ||
      quote.timestamp?.toDate()?.toLocaleString()?.toLowerCase().includes(search)
    );
  });

  return (
    <div className="assigned-agent-quote-archive">
      <button
        className="assigned-agent-archive-toggle-button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? 'Expand Archived Assigned Agent Quotes' : 'Collapse Archived Assigned Agent Quotes'}
      </button>
      {!isCollapsed && (
        <ul className="assigned-agent-quote-archive-list">
          {filteredQuotes.length === 0 ? (
            <p>No archived assigned agent quotes found.</p>
          ) : (
            filteredQuotes.map(quote => (
              <li key={quote.id} className="assigned-agent-archived-quote-item">
                <div className="assigned-agent-archived-quote-details">
                  <p><strong>Assigned At:</strong> {quote.timestamp?.toDate().toLocaleString()}</p>
                  <p><strong>Agent Name:</strong> {quote.agentName}</p>
                  <p><strong>Agent Email:</strong> {quote.agentEmail}</p>
                  <p><strong>Form Type:</strong> {quote.formType}</p>
                  <p><strong>Address:</strong> {quote.address ? `${quote.address.streetAddress || ''}, ${quote.address.city || ''}, ${quote.address.state || ''}, ${quote.address.zipCode || ''}` : 'N/A'}</p>
                  <p><strong>Birthdate:</strong> {quote.birthdate || 'N/A'}</p>
                  <p><strong>Phone:</strong> {quote.phone || 'N/A'}</p>
                  <p><strong>Additional Notes:</strong> {quote.additionalNotes || 'N/A'}</p>
                  <p><strong>Attached Files:</strong> {quote.attachedFiles ? quote.attachedFiles.map((file, index) => (
                    <a key={index} href={file} target="_blank" rel="noopener noreferrer">{`File ${index + 1}`}</a>
                  )) : 'None'}</p>
                  <p><strong>Referral Status:</strong> {quote.referralStatus || 'Not Referred'}</p>
                  <p><strong>Close Reason:</strong> {quote.closeReason || 'N/A'}</p>
                </div>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default BrokerDashAssignedAgentQuotesArchive;


