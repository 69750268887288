import React, { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Bottom from '../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import './declarationPagesInstructions.css';


function DeclarationsPagesInstructions() {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (section) => {
    setExpanded(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const carriers = [
    {
        name: "General Instructions",
        instructions: (
          <ol>
            <li>Online via the Insurance Company's Website or App.</li>
            <ol>
              <li>Log In:</li>
              <ul>
                <li>Visit the insurance company's website or open their mobile app.</li>
                <li>Log in to your account using your username and password. If you don't have an account, you may need to create one by providing your policy details (policy and/or account number).</li>
              </ul>
              <li>Navigate to Policy Documents:</li>
              <ul>
                <li>Once logged in, look for a section labeled "Documents," "Policy Documents," "My Account," or similar.</li>
                <li>Locate the specific policy for which you need.</li>
              </ul>
              <li>Download the Declaration Page:</li>
              <ul>
                <li>Click on the link or button to view or download the declaration page.</li>
                <li>Save the document.</li>
              </ul>
            </ol>
            <li>By Phone</li>
            <ol>
              <li>Customer Service:</li>
              <ul>
                <li>Call the customer service number provided by your insurance company (usually found on their website, your insurance card, or policy documents).</li>
                <li>Follow the prompts to speak with a representative.</li>
              </ul>
              <li>Request the Declaration Page:</li>
              <ul>
                <li>Provide your policy number or personal identification information.</li>
                <li>Ask the representative to send you the declaration page for your home or auto insurance policy. They may offer to mail, email, or fax the document to you.</li>
              </ul>
              <li>Through Your Insurance Agent</li>
              <ol>
                <li>Contact Your Agent:</li>
                <ul>
                  <li>Reach out to your local insurance agent who handles your policy. This can be done via phone, email, or by visiting their office.</li>
                </ul>
                <li>Request the Declaration Page:</li>
                <ul>
                  <li>Provide your policy details and request the declaration page.</li>
                  <li>The agent can usually print, email, or mail the document to you.</li>
                </ul>
              </ol>
            </ol>
          </ol>
        )
      },
    {
      name: "Allstate",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Allstate account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Allstate customer service at 1-800-255-7828.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Allstate agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "State Farm",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your State Farm account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call State Farm customer service at 1-800-782-8332.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your State Farm agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Farmers",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Farmers account on their website or app.</li>
            <li>Navigate to the "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Farmers customer service at 1-800-327-6335.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Farmers agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Progressive",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Progressive account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Progressive customer service at 1-800-776-4737.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Progressive agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Geico",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Geico account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Geico customer service at 1-800-841-3000.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Geico agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Auto-Owners",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Auto-Owners account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Auto-Owners customer service at 1-888-252-4626.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Auto-Owners agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Citizens",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Citizens account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Citizens customer service at 1-866-411-2742.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Citizens agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Safeco",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Safeco account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Safeco customer service at 1-800-332-3226.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Safeco agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Frankenmuth",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Frankenmuth account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Frankenmuth customer service at 1-800-234-1133.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Frankenmuth agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
    {
      name: "Pioneer State Mutual",
      instructions: (
        <ol>
          <li>Online:</li>
          <ul>
            <li>Log in to your Pioneer account on their website or app.</li>
            <li>Navigate to the "My Account" or "Documents" section.</li>
            <li>Locate the policy for which you need the declaration page and download it.</li>
          </ul>
          <li>Phone:</li>
          <ul>
            <li>Call Pioneer State Mutual customer service at 1-800-837-7674.</li>
            <li>Request the declaration page for your home and auto insurance policy.</li>
          </ul>
          <li>Agent:</li>
          <ul>
            <li>Contact your Pioneer State Mutual agent.</li>
            <li>Request them to provide the declaration page.</li>
          </ul>
        </ol>
      )
    },
   
  ];

  return (
    <>
      <Navbar />
      <div >
        <h1 className='dec-page-help-header'>Select your carrier below to see how you can obtain your policy documents</h1>
        <p id='dec-page-help-p' className='dec-page-help-header'>Don't see your carrier? Our general instructions can help.</p>
      </div>
      <div className="dec-page-help-grid-container">
        {carriers.map(carrier => (
          <a key={carrier.name} href={`#${carrier.name}`} className="dec-page-help-grid-item">
          <h2 onClick={() => toggleExpand(carrier.name)} style={{ cursor: 'pointer' }}>
            {carrier.name}
          </h2>
          {expanded[carrier.name] && (
            <div>{carrier.instructions}</div>
          )}
        </a>
        ))}
      </div>
      <Bottom />
    </>
  );
}

export default DeclarationsPagesInstructions;
