import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar/Navbar';
import CaptivePurchasedLeads from './CaptivePurchasedLeads';
import './captiveMyAccount.css';

const auth = getAuth();
const db = getFirestore();

const CaptiveMyAccount = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPurchasingEnabled, setIsPurchasingEnabled] = useState(false);
  const [zipCodes, setZipCodes] = useState([]);  // Array of zip codes
  const [newZipCode, setNewZipCode] = useState('');  // Input field for new zip code
  const [states, setStates] = useState([]);  // Array of states
  const [newState, setNewState] = useState('');  // Input field for new state
  const [purchaseLimits, setPurchaseLimits] = useState({ daily: '', weekly: '', monthly: '' }); // Lead limits
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [tallies, setTallies] = useState({ daily: 0, weekly: 0, monthly: 0 }); // Tally for lead purchases
  const [initialZipCodes, setInitialZipCodes] = useState([]);
  const [initialStates, setInitialStates] = useState([]);
  const [initialPurchaseLimits, setInitialPurchaseLimits] = useState({ daily: '', weekly: '', monthly: '' });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);  // Track if the Save button should be disabled
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const user = auth.currentUser;
      if (user) {
        try {
          // Fetch captive agent info from the "michigancaptivelist" collection
          const userDoc = await getDoc(doc(db, 'michigancaptivelist', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserInfo(data);
            setIsPurchasingEnabled(data.leadParameters?.isPurchasingEnabled || false);
            const zipCodesFromFirestore = data.leadParameters?.zipCodes || [];
            const statesFromFirestore = data.leadParameters?.states || [];
            const purchaseLimitsFromFirestore = data.leadParameters?.purchaseLimits || { daily: '', weekly: '', monthly: '' };
            const dailyPurchases = data.leadParameters?.dailyPurchases || 0;
            const weeklyPurchases = data.leadParameters?.weeklyPurchases || 0;
            const monthlyPurchases = data.leadParameters?.monthlyPurchases || 0;

            setZipCodes(zipCodesFromFirestore);
            setStates(statesFromFirestore);
            setPurchaseLimits(purchaseLimitsFromFirestore);
            setTallies({ daily: dailyPurchases, weekly: weeklyPurchases, monthly: monthlyPurchases });
            
            // Save initial values to compare for changes
            setInitialZipCodes(zipCodesFromFirestore);
            setInitialStates(statesFromFirestore);
            setInitialPurchaseLimits(purchaseLimitsFromFirestore);
          } else {
            setError('No profile data found for captive agent.');
          }
        } catch (err) {
          console.error('Error fetching captive agent data:', err);
          setError('Error fetching data.');
        } finally {
          setLoading(false);
        }
      } else {
        setError('User not logged in.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTogglePurchasing = async () => {
    const newPurchasingState = !isPurchasingEnabled;
    setIsPurchasingEnabled(newPurchasingState);

    try {
      const user = auth.currentUser;
      if (user) {
        // Save the current settings when purchasing is disabled
        if (!newPurchasingState) {
          await updateDoc(doc(db, 'michigancaptivelist', user.uid), {
            'leadParameters.isPurchasingEnabled': newPurchasingState,
            'leadParameters.zipCodes': zipCodes,
            'leadParameters.states': states,
            'leadParameters.purchaseLimits': purchaseLimits,
          });
        } else {
          // When enabling, we don't need to save if purchasing was previously off
          await updateDoc(doc(db, 'michigancaptivelist', user.uid), {
            'leadParameters.isPurchasingEnabled': newPurchasingState,
          });
        }
      }
    } catch (err) {
      console.error('Error updating purchasing state:', err);
    }
  };

  const handleSaveParameters = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Handle defaults for unlimited limits
        const updatedPurchaseLimits = {
          daily: purchaseLimits.daily || 'unlimited',
          weekly: purchaseLimits.weekly || 'unlimited',
          monthly: purchaseLimits.monthly || 'unlimited',
        };

        await updateDoc(doc(db, 'michigancaptivelist', user.uid), {
          'leadParameters.zipCodes': zipCodes,
          'leadParameters.states': states,
          'leadParameters.purchaseLimits': updatedPurchaseLimits
        });

        // Show confirmation message after saving
        setConfirmationMessage('Settings have been saved successfully!');

        // Clear the confirmation message after 3 seconds
        setTimeout(() => {
          setConfirmationMessage('');
        }, 3000);

        // After saving, disable the save button
        setIsSaveDisabled(true);

      } catch (err) {
        console.error('Error saving parameters:', err);
      }
    }
  };

  // Handle adding a new zip code
  const handleAddZipCode = () => {
    if (newZipCode && !zipCodes.includes(newZipCode)) {
      setZipCodes([...zipCodes, newZipCode]);
      setNewZipCode('');
      setIsSaveDisabled(false); // Enable the Save button when changes are made
    }
  };

  // Handle deleting a zip code from the list
  const handleDeleteZipCode = (zipCodeToDelete) => {
    setZipCodes(zipCodes.filter(zipCode => zipCode !== zipCodeToDelete));
    setIsSaveDisabled(false); // Enable the Save button when changes are made
  };

  // Handle adding a new state
  const handleAddState = () => {
    if (newState && !states.includes(newState)) {
      setStates([...states, newState]);
      setNewState('');
      setIsSaveDisabled(false); // Enable the Save button when changes are made
    }
  };

  // Handle deleting a state from the list
  const handleDeleteState = (stateToDelete) => {
    setStates(states.filter(state => state !== stateToDelete));
    setIsSaveDisabled(false); // Enable the Save button when changes are made
  };

  // Check if the current form values are different from the initial values
  useEffect(() => {
    const isModified =
      JSON.stringify(zipCodes) !== JSON.stringify(initialZipCodes) ||
      JSON.stringify(states) !== JSON.stringify(initialStates) ||
      JSON.stringify(purchaseLimits) !== JSON.stringify(initialPurchaseLimits);

    setIsSaveDisabled(!isModified);
  }, [zipCodes, states, purchaseLimits, initialZipCodes, initialStates, initialPurchaseLimits]);

  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="captive-my-account-container">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Navbar />
        <div className="captive-my-account-container">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="captive-my-account-container">
        <div className="captive-my-account-header">
          <button
            onClick={() => navigate('/captive-dashboard')}
            className="captive-account-dashboard-button"
          >
            Back to Dashboard
          </button>
          <h1>My Account</h1>
        </div>

        {/* Display Captive Agent Information */}
        <div className="captive-my-account-info">
          <p>
            <strong>Name:</strong> {userInfo?.name || 'N/A'}
          </p>
          <div className="address-section">
            <p>
              <strong>Agency Name:</strong> {userInfo?.agencyName || 'N/A'}
            </p>
            <p>
              <strong>Street Address:</strong> {userInfo?.streetAddress || 'N/A'}
            </p>
            <p>
              <strong>City:</strong> {userInfo?.city || 'N/A'},{' '}
              <strong>State:</strong> {userInfo?.state || 'N/A'}
            </p>
          </div>
          <p className="full-width">
            <strong>Email:</strong> {userInfo?.email || 'N/A'}
          </p>
          <p>
            <strong>Carrier:</strong> {userInfo?.captiveCarrier || 'N/A'}
          </p>
          <p>
            <strong>Carrier Code:</strong> {userInfo?.captiveCarrierCode || 'N/A'}
          </p>
        </div>

        {/* Lead Purchasing Parameters Section */}
        <div className="lead-purchasing-parameters">
          <div className="toggle-purchasing">
            <label>
              Enable Lead Purchasing:
              <input
                type="checkbox"
                checked={isPurchasingEnabled}
                onChange={handleTogglePurchasing}
              />
            </label>
          </div>

          {isPurchasingEnabled && (
            <div>
              <div className="purchase-parameters">
                {/* Zip Codes */}
                <div>
                  <label>Zip Codes:</label>
                  <div className="zip-code-input">
                    <input
                      type="text"
                      value={newZipCode}
                      onChange={(e) => setNewZipCode(e.target.value)}
                      placeholder="Enter zip code"
                    />
                    <button onClick={handleAddZipCode}>Add</button>
                  </div>
                  <div className="zip-code-list">
                    {zipCodes.map((zipCode, index) => (
                      <span key={index} className="captive-zip-code-item">
                        {zipCode}
                        <button
                          onClick={() => handleDeleteZipCode(zipCode)}
                          className="captive-delete-zip-code"
                        >
                          x
                        </button>
                      </span>
                    ))}
                  </div>
                </div>

                {/* States */}
                <div>
                  <label>States:</label>
                  <div className="state-input">
                    <input
                      type="text"
                      value={newState}
                      onChange={(e) => setNewState(e.target.value)}
                      placeholder="Enter state"
                    />
                    <button onClick={handleAddState}>Add</button>
                  </div>
                  <div className="state-list">
                    {states.map((state, index) => (
                      <span key={index} className="captive-zip-code-item">
                        {state}
                        <button
                          onClick={() => handleDeleteState(state)}
                          className="captive-delete-zip-code"
                        >
                          x
                        </button>
                      </span>
                    ))}
                  </div>
                </div>

                {/* Purchase Limits */}
                <div>
                  <label>Daily Lead Limit:</label>
                  <input
                    type="number"
                    value={purchaseLimits.daily || ''}
                    onChange={(e) => setPurchaseLimits({ ...purchaseLimits, daily: e.target.value })}
                    placeholder='No Limit'
                  />
                  <p>Running Total: {tallies.daily}</p>
                </div>

                <div>
                  <label>Weekly Lead Limit:</label>
                  <input
                    type="number"
                    value={purchaseLimits.weekly || ''}
                    onChange={(e) => setPurchaseLimits({ ...purchaseLimits, weekly: e.target.value })}
                    placeholder='No Limit'
                  />
                  <p>Running Total: {tallies.weekly}</p>
                </div>

                <div>
                  <label>Monthly Lead Limit:</label>
                  <input
                    type="number"
                    value={purchaseLimits.monthly || ''}
                    onChange={(e) => setPurchaseLimits({ ...purchaseLimits, monthly: e.target.value })}
                    placeholder='No Limit'
                  />
                  <p>Running Total: {tallies.monthly}</p>
                </div>
              </div>

              <button onClick={handleSaveParameters} disabled={isSaveDisabled}>Save Parameters</button>

              {/* Display the confirmation message */}
              {confirmationMessage && (
                <div className="confirmation-message">
                  {confirmationMessage}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <CaptivePurchasedLeads />
    </div>
  );
};

export default CaptiveMyAccount;





