import React from 'react';
import Modal from 'react-modal';
import './referralDetailsModal.css';

Modal.setAppElement('#root');

const ReferralDetailsModal = ({ isOpen, onClose, referralData, soldData }) => {

  // Helper to format a Firestore timestamp
  const formatDate = (timestamp) => {
    if (timestamp?.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString();
    }
    return 'No timestamp available';
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Referral Details"
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
    >
      <h2 className="modal-header">Referral Details</h2>

      <div className="referral-details">
        {/* ============= REFERRAL INFO SECTION ============= */}
        <h3 className="section-title">Referral Info</h3>

        {referralData ? (
          <>
            <p>
              <strong>Agent Name:</strong> {referralData.agentName || 'N/A'}
            </p>
            <p>
              <strong>Agent Email:</strong> {referralData.agentEmail || 'N/A'}
            </p>
            <p>
              <strong>Carriers:</strong>{' '}
              {referralData.carrierCodes
                ? referralData.carrierCodes.join(', ')
                : 'N/A'}
            </p>
            <p>
              <strong>Referral Date:</strong> {formatDate(referralData.timestamp)}
            </p>
          </>
        ) : (
          <p>No referral data found.</p>
        )}

        {/* ============= SOLD INFO SECTION ============= */}
        <h3 className="section-title">Sold Info</h3>

        {soldData ? (
          <>
            <p>
              <strong>Sold To Name:</strong> {soldData.soldToAgentName || 'N/A'}
            </p>
            <p>
              <strong>Sold To Email:</strong> {soldData.soldToAgentEmail || 'N/A'}
            </p>
            <p>
              <strong>Sold At:</strong> {formatDate(soldData.soldAt)}
            </p>
          </>
        ) : (
          <p>No sold info found.</p>
        )}
      </div>

      <button onClick={onClose} className="modal-button close-button">
        Close
      </button>
    </Modal>
  );
};

export default ReferralDetailsModal;

