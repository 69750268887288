import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from '../Navbar/Navbar';
import AboutUsTop from './AboutUsComponents/aboutus-top/aboutus-top';
import AboutUsOurStory from './AboutUsComponents/aboutus-ourstory/aboutus-ourstory';
import AboutUsCoreBeliefs from './AboutUsComponents/aboutus-corebeliefs/aboutus-corebeliefs';
import AboutUsPlainSight from './AboutUsComponents/aboutus-plainsight/aboutus-plainsight';
import Bottom from '../HomePage/HomePageComponents/hompage-bottom/homepage-bottom';
import StickyBanner from '../HomePage/HomePageComponents/STICKY BANNER/stickybanner';


function AboutUs() {

  
    return (
      <div className="AboutUs">
        <Navbar />
        <AboutUsTop />
        <AboutUsOurStory />
        <AboutUsCoreBeliefs />
        <AboutUsPlainSight />
        <Bottom />
        <StickyBanner />
      </div>
    );
  }
  
  export default AboutUs;