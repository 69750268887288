// CaptiveArchivedQuotes.jsx
import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig'; // Adjust the path as needed
import Navbar from '../../Navbar/Navbar';

const CaptiveArchivedQuotes = () => {
  const [filterText, setFilterText] = useState('');
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleBackToDashboard = () => {
    navigate('/captive-dashboard');
  };

  useEffect(() => {
    const fetchArchivedQuotes = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to view your archived quotes.');
        setLoading(false);
        return;
      }
      try {
        // Query the soldLeads collection for archived quotes.
        // Note: We use soldToAgentId (which should match the captive's UID) per our security rules.
        const archivedQuery = query(
          collection(db, 'soldLeads'),
          where('captiveArchived', '==', true),
          where('soldToAgentId', '==', user.uid),
          orderBy('archiveTimestamp', 'desc')
        );
        const snapshot = await getDocs(archivedQuery);
        const quotesList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQuotes(quotesList);
      } catch (err) {
        console.error('Error fetching archived quotes:', err);
        setError('Error fetching archived quotes.');
      } finally {
        setLoading(false);
      }
    };

    fetchArchivedQuotes();
  }, [auth]);

  // Filter quotes by archive timestamp, result, unsuccessful reason, lead name, or lead email.
  const filteredQuotes = quotes.filter(quote => {
    const search = filterText.toLowerCase();
    const archiveTime = quote.archiveTimestamp?.toDate?.().toLocaleString()?.toLowerCase() || '';
    const result = quote.captiveSoldResult?.toLowerCase() || '';
    const unsuccessful = quote.captiveSoldUnsuccessfulReason?.toLowerCase() || '';
    const leadName = quote.leadName?.toLowerCase() || '';
    const leadEmail = quote.leadEmail?.toLowerCase() || '';
    return (
      archiveTime.includes(search) ||
      result.includes(search) ||
      unsuccessful.includes(search) ||
      leadName.includes(search) ||
      leadEmail.includes(search)
    );
  });

  return (
    <div>
      <Navbar />
      <div className="archived-quotes-container">
        <h2>Archived Quotes</h2>
        <button className="back-button" onClick={handleBackToDashboard}>
          Back to Dashboard
        </button>
        <div className="filter-container">
          <input
            type="text"
            placeholder="Search archived quotes"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="filter-input"
          />
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : quotes.length === 0 ? (
          <p>No archived quotes found.</p>
        ) : (
          <ul className="archived-quote-list">
            {filteredQuotes.map(quote => (
              <li key={quote.id} className="archived-quote-item">
                <div className="quote-summary">
                  <p>
                    <strong>Lead Name:</strong> {quote.leadName || 'N/A'}
                  </p>
                  <p>
                    <strong>Lead Email:</strong> {quote.leadEmail || 'N/A'}
                  </p>
                  <p>
                    <strong>Archived At:</strong>{' '}
                    {quote.archiveTimestamp?.toDate?.().toLocaleString() || 'N/A'}
                  </p>
                  <p>
                    <strong>Result:</strong> {quote.captiveSoldResult || 'N/A'}
                  </p>
                  {quote.captiveSoldResult === 'unsuccessful' && (
                    <p>
                      <strong>Unsuccessful Reason:</strong> {quote.captiveSoldUnsuccessfulReason || 'N/A'}
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CaptiveArchivedQuotes;
