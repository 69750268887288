import React, { useState, useEffect } from 'react';
import { auth, db } from '../../../firebaseConfig';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import './BrokerLenderArchivedQuotes.css';

const BrokerLenderArchivedQuotes = ({ filterText }) => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false); // State to control collapse

  useEffect(() => {
    const fetchArchivedQuotes = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to view your archived lender quotes.');
        setLoading(false);
        return;
      }

      try {
        const lenderMyQuotesQuery = query(
          collection(db, 'lenderMyQuotes'),
          where('agentEmail', '==', user.email),
          where('brokerArchived', '==', true)
        );
        const lenderMyQuotesSnapshot = await getDocs(lenderMyQuotesQuery);
        const lenderMyQuotesList = lenderMyQuotesSnapshot.docs.map(doc => ({
          id: doc.id,
          brokerArchived: doc.data().brokerArchived,
          status: doc.data().status || 'N/A',
          paymentStatus: doc.data().paymentStatus || 'N/A',
          submittedPayment: doc.data().submittedPayment || 0,
          archiveReason: doc.data().archiveReason || 'N/A',
          writtenCarrier: doc.data().writtenCarrier || 'N/A',
          unsuccessfulReason: doc.data().unsuccessfulReason || 'N/A',
        }));

        const lenderLeadsData = await Promise.all(
          lenderMyQuotesList.map(async (quote) => {
            const lenderLeadsDocRef = doc(db, 'lenderLeads', quote.id);
            const lenderLeadsDoc = await getDoc(lenderLeadsDocRef);
            if (lenderLeadsDoc.exists()) {
              return {
                ...lenderLeadsDoc.data(),
                ...quote,
              };
            } else {
              return null;
            }
          })
        );

        const filteredLeads = lenderLeadsData.filter(lead => lead !== null);

        const sortedLeads = filteredLeads.sort((a, b) => {
          const aTimestamp = a.timestamp?.toDate ? a.timestamp.toDate() : new Date(0);
          const bTimestamp = b.timestamp?.toDate ? b.timestamp.toDate() : new Date(0);
          return bTimestamp - aTimestamp;
        });

        setQuotes(sortedLeads);
      } catch (err) {
        console.error('Error fetching archived lender quotes:', err);
        setError('Error fetching archived lender quotes.');
      } finally {
        setLoading(false);
      }
    };

    fetchArchivedQuotes();
  }, []);

  const filteredQuotes = quotes.filter((quote) => {
    const search = filterText.toLowerCase();
    const timestamp = quote.timestamp?.toDate()?.toLocaleString()?.toLowerCase() || '';
    const homeowner1Name = `${quote.homeowner1?.firstName || ''} ${quote.homeowner1?.lastName || ''}`.toLowerCase();
    const homeowner1Email = quote.homeowner1?.email?.toLowerCase() || '';
    const homeowner2Name = `${quote.homeowner2?.firstName || ''} ${quote.homeowner2?.lastName || ''}`.toLowerCase();
    const homeowner2Email = quote.homeowner2?.email?.toLowerCase() || '';
    const currentAddress = `${quote.currentAddress?.street || ''}, ${quote.currentAddress?.city || ''}, ${quote.currentAddress?.state || ''}, ${quote.currentAddress?.zip || ''}`.toLowerCase();
    const newAddress = `${quote.newAddress?.street || ''}, ${quote.newAddress?.city || ''}, ${quote.newAddress?.state || ''}, ${quote.newAddress?.zip || ''}`.toLowerCase();
    const notes = quote.notes?.toLowerCase() || '';
    const archiveReason = quote.archiveReason?.toLowerCase() || '';
    const writtenCarrier = quote.writtenCarrier?.toLowerCase() || '';
    const unsuccessfulReason = quote.unsuccessfulReason?.toLowerCase() || '';

    return (
      timestamp.includes(search) ||
      homeowner1Name.includes(search) ||
      homeowner1Email.includes(search) ||
      homeowner2Name.includes(search) ||
      homeowner2Email.includes(search) ||
      currentAddress.includes(search) ||
      newAddress.includes(search) ||
      notes.includes(search) ||
      archiveReason.includes(search) ||
      writtenCarrier.includes(search) ||
      unsuccessfulReason.includes(search)
    );
  });

  const renderAttachedFiles = (attachedFiles) => {
    if (!attachedFiles || attachedFiles.length === 0) {
      return 'N/A';
    }

    return (
      <ul>
        {attachedFiles.map((fileUrl, index) => (
          <li key={index}>
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              File {index + 1}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const renderArchiveDetails = (quote) => {
    if (quote.archiveReason === 'successful') {
      return <p><strong>Written Carrier:</strong> {quote.writtenCarrier}</p>;
    } else if (quote.archiveReason === 'unsuccessful') {
      return <p><strong>Unsuccessful Reason:</strong> {quote.unsuccessfulReason}</p>;
    }
    return <p><strong>Archive Reason:</strong> {quote.archiveReason}</p>;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="broker-lender-quote-archive">
      <button
        className="archive-toggle-button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? 'Expand Lender Archived Quotes' : 'Collapse Lender Archived Quotes'}
      </button>
      {!isCollapsed && (
        <ul className="broker-lender-quote-archive-list">
          {filteredQuotes.length === 0 ? (
            <p>No archived lender quotes found.</p>
          ) : (
            filteredQuotes.map(quote => (
              <li key={quote.id} className="broker-lender-archived-quote-item">
                <div className="broker-lender-archived-quote-details">
                  <p><strong>Submitted At:</strong> {quote.timestamp?.toDate ? quote.timestamp.toDate().toLocaleString() : 'N/A'}</p>
                  <p><strong>Homeowner 1:</strong> {`${quote.homeowner1.firstName} ${quote.homeowner1.lastName}`} | {quote.homeowner1.email}</p>
                  <p><strong>Phone 1:</strong> {quote.homeowner1.phone || 'N/A'}</p>
                  <p><strong>Homeowner 2:</strong> {`${quote.homeowner2.firstName} ${quote.homeowner2.lastName}`} | {quote.homeowner2.email}</p>
                  <p><strong>Phone 2:</strong> {quote.homeowner2.phone || 'N/A'}</p>
                  <p><strong>Current Address:</strong> {`${quote.currentAddress.street}, ${quote.currentAddress.city}, ${quote.currentAddress.state}, ${quote.currentAddress.zip}`}</p>
                  <p><strong>New Address:</strong> {`${quote.newAddress.street}, ${quote.newAddress.city}, ${quote.newAddress.state}, ${quote.newAddress.zip}`}</p>
                  <p><strong>Notes:</strong> {quote.notes || 'N/A'}</p>
                  <p><strong>Attached Files:</strong> {renderAttachedFiles(quote.attachedFiles)}</p>
                  <p><strong>Broker Archived:</strong> {quote.brokerArchived ? 'Yes' : 'No'}</p>
                  {renderArchiveDetails(quote)}
                </div>
                <div className="broker-lender-archived-quote-status">
                  <p><strong>Status:</strong> {quote.status}</p>
                  <p><strong>Payment Status:</strong> {quote.paymentStatus}</p>
                  <p><strong>Submitted Payment:</strong> ${quote.submittedPayment ? quote.submittedPayment.toFixed(2) : 'N/A'}</p>
                </div>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default BrokerLenderArchivedQuotes;






