import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Navbar from '../../Navbar/Navbar';
import ForgotPasswordButton from '../../ForLenders/LenderLogin/forgotPasswordButton';
import './brokerLogin.css';

const auth = getAuth();
const db = getFirestore();

const BrokerLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showRoleSelection, setShowRoleSelection] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const uid = user.uid;

      // Fetch roles from Firestore
      const userRolesRef = doc(db, 'userRoles', uid);
      const userRolesSnapshot = await getDoc(userRolesRef);

      if (!userRolesSnapshot.exists()) {
        setError('User roles not found. Please contact support.');
        await signOut(auth);
        return;
      }

      const userRolesDoc = userRolesSnapshot.data();
      const roles = userRolesDoc.roles || {};
      const temporaryPassword = userRolesDoc.temporaryPassword;

      // If user has a temporary password, force reset
      if (temporaryPassword) {
        navigate('/reset-initial-password');
        return;
      }

      // Check if user's email is verified
      if (!user.emailVerified) {
        setError('Please verify your email before accessing the dashboard.');
        await signOut(auth);
        return;
      }

      // Determine role-based access
      const hasBrokerRole = !!roles.brokerLogin;
      const hasCaptiveRole = !!roles.captiveLogin;

      // If user has neither role, they can't proceed
      if (!hasBrokerRole && !hasCaptiveRole) {
        setError('You do not have access to this dashboard.');
        await signOut(auth);
        return;
      }

      // If user has both, prompt them to choose
      if (hasBrokerRole && hasCaptiveRole) {
        setShowRoleSelection(true);
        return;
      }

      // Otherwise, go straight to the appropriate dashboard
      if (hasBrokerRole) {
        navigate('/broker-dashboard');
      } else if (hasCaptiveRole) {
        navigate('/captive-dashboard');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Error logging in. Please try again.');
    }
  };

  const handleDashboardSelection = (dashboardType) => {
    if (dashboardType === 'broker') {
      navigate('/broker-dashboard');
    } else if (dashboardType === 'captive') {
      navigate('/captive-dashboard');
    }
  };

  // If the user has both roles, show the selection screen
  if (showRoleSelection) {
    return (
      <div>
        <Navbar />
        <div className="broker-login-container">
          <h1>Select Dashboard</h1>
          <p>You have access to both Broker and Captive dashboards. Choose one below:</p>
          <button
            className="dashboard-selection-btn"
            onClick={() => handleDashboardSelection('broker')}
          >
            Go to Broker Dashboard
          </button>
          <button
            className="dashboard-selection-btn"
            onClick={() => handleDashboardSelection('captive')}
          >
            Go to Captive Dashboard
          </button>
        </div>
      </div>
    );
  }

  // Normal login form
  return (
    <div>
      <Navbar />
      <div className="broker-login-container">
        <h1>Broker Login</h1>
        <h2>Welcome Back! Please Log Into Your Dashboard Here</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className="broker-login-button" type="submit">
            Login
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <ForgotPasswordButton />
      </div>
    </div>
  );
};

export default BrokerLogin;







