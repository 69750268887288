import React from 'react';
import ReactDOM from 'react-dom/client';
import './faq-accordian.css';


function FAQAccordian() {

  
    return (
        <div className='faq-section'>

<div className="faq-accordion">

  <div>
    <input type="checkbox" name="example_accordion" id="no_call_centers" className="faq-accordion__input" />
    <label htmlFor="no_call_centers" className="faq-accordion__label">Who is ABCQuote?
    </label>
    <div className="faq-accordion__content">
      <p>We are independent agents on a mission to provide you with the best price and service available.  See <span><a href='/aboutus'>About Us</a></span> to learn more.
      </p>
    </div>
  </div>
  <div>
    <input type="checkbox" name="example_accordion" id="multiple_carriers" className="faq-accordion__input" />
    <label htmlFor="multiple_carriers" className="faq-accordion__label">What is an Independent Agent?

    </label>
    <div className="faq-accordion__content">
      <p>We call Independent Agents our industry's "best kept secret."  As independent agents, we are able to work with multiple carriers, allowing us to find you the best price for the right coverage, while being your personal advocate in your time of need.  We work for you, not the carrier, and are able to assist you in everything from simple policy questions and changes to annual reviews and looking for new options.
      </p>
    </div>
  </div>
  <div>
    <input type="checkbox" name="example_accordion" id="local_agent" className="faq-accordion__input" />
    <label htmlFor="local_agent" className="faq-accordion__label">Who are your network of Professional Independent Agents?
    </label>
    <div className="faq-accordion__content">
      <p>Using our decades of experience, we handpick our agent professionals according to a rigorous set of standards that ensure you receive the most reliable service and best price.
    </p>
    </div>
  </div>
 <div>
    <input type="checkbox" name="example_accordion" id="licensed_professional" className="faq-accordion__input" />
    <label htmlFor="licensed_professional" className="faq-accordion__label">What happens when I enter my Address, Birthdate, and Contact Info?
    </label>
    <div className="faq-accordion__content">
      <p>Your Address, Birthdate, and Contact info (ABC) is all we need to get started on finding you 7 or more quotes from the best regional and national carriers available in your area.  Once you have entered your ABC’s we’ll begin working on your quotes.  See <span><a href='/howitworks'>How It Works.</a></span>
      </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="claims_advocacy" className="faq-accordion__input" />
    <label htmlFor="claims_advocacy" className="faq-accordion__label">Is ABCQuote an Insurance Carrier?
    </label>
    <div className="faq-accordion__content">
      <p>ABCquote is not an insurance carrier.  We are independent agents that work with the leading regional and national carriers available in your area.
      </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="annual_review" className="faq-accordion__input" />
    <label htmlFor="annual_review" className="faq-accordion__label">Does it cost money to use ABCquote?
    </label>
    <div className="faq-accordion__content">
        <p>ABCquote is free for you to use.  As independent agents we get paid by the carrier when we place policies with them. This does not affect the pricing or discounts available to you. Think of us as an included service with your carrier.
        </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="ease_abc" className="faq-accordion__input" />
    <label htmlFor="ease_abc" className="faq-accordion__label">How does ABCquote make money?
    </label>
    <div className="faq-accordion__content">
      <p>ABCquote earns commission directly from the regional and national carriers we place policies with.
    </p>
    </div>
  </div>
   <div>
    <input type="checkbox" name="example_accordion" id="no_data_share" className="faq-accordion__input" />
    <label htmlFor="no_data_share" className="faq-accordion__label">Is my personal information protected?
    </label>
    <div className="faq-accordion__content">
      <p>Yes. We never sell your personal information and will only use it for the reason you shared it with us, to find you the right coverage at the best price.
      </p>
    </div>
  </div>
  </div>
  </div>
        
    );
  }
  
  export default FAQAccordian