import React from 'react';
import ReactDOM from 'react-dom/client';
import './homepage-customer-experiences.css';   


function CustomerExperiences() {

  
    return (
      <div className='customerexperiences-section'>
      <div className='customerexperiences-stars'>
        <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fstars-min.png?alt=media&token=fbaf7512-77f9-4e5c-a2ef-646c8a4a16a4' />
      </div>
      <div className='customerexperiences-weworkwiththebest'>
        <a href='/testimonials'>Customer Experiences</a>
        <h2>We work with the best because you deserve the best, here's what clients are saying about our agents.</h2>
      </div>
      <div className='customerexperiences-tiles'>
        <div className='tile' id='rickm'>
            <img className='rickmimg' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-06-27-at-2.07.16-PM-e1687889293921.png?alt=media&token=a566aafe-5d36-48ab-862d-1711cae76afd' />
            <div className='tileNameProduct'>
              <p className='tileName'>Rick M.</p>
              <p className='tileProduct'>Home Insurance</p>
            </div>
            <p className='tileTestimonial'>It only took a minute to submit the form and my agent, Jim, walked me through everything in about 10 minutes before I decided to switch.</p>
        </div>
        <div className='tile' id='ginaa'>
            <img className='ginaaimg' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-06-27-at-1.57.43-PM-e1687888744388.png?alt=media&token=4d10e1d4-cd2c-44aa-8b68-e495127a1622' />
            <div className='tileNameProduct'>
              <p className='tileName'>Gina A.</p>
              <p className='tileProduct'>Auto Insurance</p>
            </div>
            <p className='tileTestimonial'>ABCquote was able to save my family over $500 a year on our Auto Insurance!</p>
        </div>
        <div className='tile' id='toddf'>
            <img className='toddfimg' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-3.34.03-PM-e1689018686530.png?alt=media&token=ed50380f-218e-4773-bf26-7af3fc5f6388' />
            <div className='tileNameProduct'>
              <p className='tileName'>Todd F.</p>
              <p className='tileProduct'>Home & Auto Bundle</p>
            </div>
            <p className='tileTestimonial'>It’s an easy process to get a quote, I literally did it while I was filling up my tank at the gas station.</p>
        </div>
        <div className='tile' id='maryb'>
            <img className='marybimg' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-3.48.37-PM.png?alt=media&token=f42fc269-2229-4647-85c7-e92a26a419ec' />
            <div className='tileNameProduct'>
              <p className='tileName'>Mary B.</p>
              <p className='tileProduct'>Auto Insurance</p>
            </div>
            <p className='tileTestimonial'>We love that an actual person answers the phone right away. There are no lengthy automated questions we have to answer first. You all provide true customer service and great advice.</p>
        </div>
        <div className='tile' id='zeket'>
            <img className='zeketimg' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-3.28.05-PM.png?alt=media&token=a81d6442-454a-4766-8aec-18b6ae23d49f' />
            <div className='tileNameProduct'>
              <p className='tileName'>Zeke T.</p>
              <p className='tileProduct'>Home & Auto Bundle</p>
            </div>
            <p className='tileTestimonial'>Thanks to your team I feel like I save a lot of money and couldn’t be happier with the claims processing so quickly! Honestly feels like having a good friend you can count on!</p>
        </div>
        <div className='tile' id='mollys'>
            <img className='mollysimg' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FScreen-Shot-2023-07-10-at-3.41.37-PM.png?alt=media&token=0d606f35-f7ea-4eb0-b4d5-b5670eeb2806' />
            <div className='tileNameProduct'>
              <p className='tileName'>Molly S.</p>
              <p className='tileProduct'>Home & Auto Bundle</p>
            </div>
            <p className='tileTestimonial'>You are always looking for ways to save us money and you have been there any time we need you. Great service.</p>
        </div>
      </div>
      </div>
    );
  }
  
  export default CustomerExperiences;