import React from "react";
import Navbar from "../Navbar/Navbar";
import Bottom from "../HomePage/HomePageComponents/hompage-bottom/homepage-bottom";
import StickyBanner from "../HomePage/HomePageComponents/STICKY BANNER/stickybanner";
import CommunityTop from "./Community_Components/communityTop";
import CommunityBody from "./Community_Components/communityBody";

function Community() {

    return (
    <div className="Community">
        <Navbar />
        <CommunityTop />
        <CommunityBody />
        <Bottom />
        <StickyBanner />
    </div>
    )
}

export default Community;