import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import './Navbar.css';



const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };



    return (
        <header className="header" role="banner">
            <div className="wrapper">
                <Link to="/">
                    <img
                        id="headerlogo"
                        src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FABCquote_Logo.png?alt=media&token=ccf8ad0a-bfa9-4b4c-8279-560ee539af9f"
                        alt="Logo"
                    />
                </Link>
                <button className="burger-menu" onClick={toggleMenu}>
                    ☰
                </button>
                {/* Navbar for larger screens */}
                <nav className={`nav ${showMenu ? "show" : ""}`}>
                    <li>
                        <Link id="navgetaquote" to="/getaquote" >
                            <button className="btn-purple" >Get a Quote ›</button>
                        </Link>
                    </li>
                    <li>
                        <Link id="navaboutus" to="/aboutus">
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link id="navhowitworks" to="/howitworks">
                            How It Works
                        </Link>
                    </li>
                    <div className="navdropdown">
                        <li>
                            <Link id="navresources" to="#">
                                Resources
                            </Link>
                        </li>
                        <div className="navdropdowncontent" href='/blog'>
                            <a id="navblog" href="/blog">
                                Blog
                            </a>
                            <a id="navfaq" href="/frequentlyaskedquestions">
                                Frequently Asked Questions
                            </a>
                            <a id="navforbrokers" href="/forbrokers">
                                For Brokers
                            </a>
                            <a id="navbrokerlogin" href="/broker-login">
                                Broker Login
                            </a>
                            <a id="navforlenders" href="/forlenders">
                                For Lenders
                            </a>
                        </div>
                    </div>
                    <li>
                        <Link id="navtestimonials" to="/testimonials">
                            Testimonials
                        </Link>
                    </li>
                    <li>
                        <Link id="navcommunity" to="/community">
                            Community <br /> Support
                        </Link>
                    </li>
                </nav>
                {/* Sidebar for smaller screens */}
                <nav className={`sidebar ${showMenu ? "show" : ""}`}>
                <button className="nav-close-btn" onClick={toggleMenu}>
                        &times; {/* "x" button to close the sidebar */}
                    </button>
                    <ul>
                        <li>
                            <Link to="/getaquote">
                                <button className='btn-purple'>Get a Quote</button>
                            </Link>
                        </li>
                        <li>
                            <Link id='navaboutus' to="/aboutus">
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link id='navhowitworks' to="/howitworks">
                                How It Works
                            </Link>
                        </li>
                        <li>
                        <div className="navdropdown">
                            <li>
                                <Link id="navresources" to="#">
                                    Resources
                                </Link>
                            </li>
                            <div className="navdropdowncontent">
                                <a id="navblog" href="/blog">
                                    Blog
                                </a>
                                <a id="navfaq" href="/frequentlyaskedquestions">
                                    Frequently Asked Questions
                                </a>
                                <a id="navforbrokers" href="/forbrokers">
                                    For Brokers
                                </a>
                                <a id="navbrokerlogin" href="/broker-login">
                                Broker Login
                                </a>
                                <a id="navforlenders" href="/forlenders">
                                For Lenders
                            </a>
                        </div>
                    </div>
                        </li>
                        <li>
                            <Link id='navtestimonials' to='/testimonials'>
                                Testimonials
                            </Link>
                        </li>
                    <li>
                        <Link id="navcommunity" to="/community">
                            Community Support
                        </Link>
                    </li>
                    </ul>
                </nav>
                {showMenu && (
                    <div className="overlay" onClick={toggleMenu}></div>
                )}
            </div>
        </header>
    );
}

export default Navbar;
