import React, {useEffect, useRef}  from 'react';
import ReactDOM from 'react-dom/client';
import HomePageTop from './HomePageComponents/homepage-top/homepage-top';
import HomepageAccordian from './HomePageComponents/homepage-accordian/homepage-accordian';
import CustomerExperiences from './HomePageComponents/homepage-customer-experiences/homepage-customer-experiences';
import RightCoverage from './HomePageComponents/homepage-rightcoverage/homepage-rightcoverage';
import GetYouGoing from './HomePageComponents/homepage-getyougoing/homepage-getyougoing';
import Bottom from './HomePageComponents/hompage-bottom/homepage-bottom';
import Navbar from '../Navbar/Navbar';

import './HomePage.css';
import StickyBanner from './HomePageComponents/STICKY BANNER/stickybanner';


function HomePage() {


  
  
    return (
      <div className="HomePage">
        <Navbar />
        <HomePageTop />
        <HomepageAccordian />
        <CustomerExperiences />
        <RightCoverage />
        <GetYouGoing />
        <Bottom />
        <StickyBanner />
      </div>
    );
  }
  
  export default HomePage;