import React, { useState, useEffect } from 'react';
import './brokerAuthModal.css';
import { getAuth, signInWithEmailLink, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';

const BrokerAuthModal = ({ onAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1); // Step 1: Are you an independent agent? Step 2: Authentication

  const auth = getAuth();
  const db = getFirestore();

  const logAgentLogin = async (user) => {
    try {
      console.log('Logging in user with UID:', user.uid);

      // Add agent login log with uid as document ID
      await setDoc(doc(db, 'agentLogins', user.uid), {
        uid: user.uid,
        email: user.email,
        timestamp: new Date(),
      });

      // Update user role in Firestore with uid as document ID
      const userRolesRef = doc(db, 'userRoles', user.uid);
      const userRolesDoc = await getDoc(userRolesRef);
      if (userRolesDoc.exists()) {
        await updateDoc(userRolesRef, {
          'roles.agentLogins': true
        });
        console.log('Updated existing user role for UID:', user.uid);
      } else {
        await setDoc(userRolesRef, {
          uid: user.uid,
          roles: {
            agentLogins: true,
            lenders: false,
            fileUpload: false
          }
        });
        console.log('Set new user role for UID:', user.uid);
      }
    } catch (err) {
      console.error('Error logging agent login:', err);
    }
  };

  const handleEmailSignIn = async () => {
    try {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      alert('Please verify your account by clicking the link sent to your email!');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      console.log('User signed in with Google:', result.user);
      await logAgentLogin(result.user);
      onAuthenticated();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEmailLinkSignIn = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      try {
        const result = await signInWithEmailLink(auth, email, window.location.href);
        window.localStorage.removeItem('emailForSignIn');
        console.log('User signed in with email link:', result.user);
        await logAgentLogin(result.user);
        onAuthenticated();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    handleEmailLinkSignIn();
  }, []);

  const handleIndependentAgentResponse = (response) => {
    if (response) {
      setStep(2);
    } else {
      window.location.href = '/';
    }
  };

  return (
    <div className="broker-auth-modal">
      <div className="broker-auth-modal-content">
        {step === 1 && (
          <>
            <h2>Restricted Area</h2>
            <p>Are you an independent agent?</p>
            <button className='are-you-independent-button' onClick={() => handleIndependentAgentResponse(true)}>Yes</button>
            <button className='are-you-independent-button' onClick={() => handleIndependentAgentResponse(false)}>No</button>
          </>
        )}
        {step === 2 && (
          <>
            <h2>This Area is Restricted for Independent Agents Only</h2>
            <p>Please register your email or sign in with Google to continue.</p>
            <div className="broker-auth-row">
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={handleEmailSignIn}>Sign in with Email</button>
            </div>
            <div className="broker-auth-row">
              <button onClick={handleGoogleSignIn} className="google-signin-btn">
                <img src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" alt="Sign in with Google" />
              </button>
            </div>
            <button className="close-btn" onClick={() => window.location.href = '/'}>
              Close
            </button>
            {error && <p className="error-message">{error}</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default BrokerAuthModal;




