import React from 'react';
import ReactDOM from 'react-dom/client';
import './homepage-top.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';



function HomePageTop() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <div className="HomePageTop">
      <div className='homepagetop-headers'>
      <h1><span className='homepagetop-7quotes'>The Best Way To Get 7 Quotes</span>
      <br />
      <span className='homepagetop-one-local-agent'>From One Local Agent</span>
      <br />
      <span className='homepagetop-just-abc'>With Just Your Address, Birthdate, and Contact Info</span></h1>
      {/*<a className="homepagetop-howitworks" href='/howitworks'>How it works</a>*/}

      </div>
      <div className='homepagetop-buttons'>
        <Link id='homequotebuttonlink' to='/homequotepage1'>
        <button className='homeinsurancebutton'>
          <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-home-solid.png?alt=media&token=2858db6e-d684-46fe-b99c-0d102fa2f9ea' />
          <p>Home Insurance</p>
          <span className="arrow-right">›</span>
        </button>
        </Link>
        <Link id='autoquotebuttonlink' to='/autoquotepage1'>
        <button className='autoinsurancebutton'>
          <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-car-solid.png?alt=media&token=b83eae78-ff87-4472-ad41-4e00b3f0d996' />
          <p>Auto Insurance</p>
          <span className='arrow-right'>›</span>
        </button>
        </Link>
        <Link id='bundlequotebuttonlink' to='bundlequotepage1'>
        <button className='bundlebutton'>
          <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-bundle-solid.png?alt=media&token=273a81a8-3f00-4203-a7aa-f4e9a7a01972' />
          <p>Bundle Discount on Home & Auto</p>
          <span className='arrow-right'>›</span>
        </button>
        </Link>
      </div>
      <div className='homepagetop-video'>
        <video onClick={openModal} autoPlay muted loop playsInline>
          <source src="https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FWorking_on_computer.mp4?alt=media&token=d50f2033-0ffc-4ea1-98b7-ef83c1ba542f" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className='play-icon'>
          <img onClick={openModal} loop src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficons8-play-64-1.png?alt=media&token=2197df9d-6c81-4db8-80e6-1786ca490c59' alt='Play' />
        </div>
        {isModalOpen && (
          <div className="homepage-top-modal-overlay">
            <div className="homepage-top-modal-content">
              <button className="close-button" onClick={closeModal}>Close</button>
              <iframe
                src="https://player.vimeo.com/video/823147672?h=68bd9e4755&autoplay=1&title=0&byline=0&portrait=0"
                width="100%"
                height="400"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; fullscreen"
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </div>
<div className='homepagetop-border'></div>
</>

  );
}

export default HomePageTop;