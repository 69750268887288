import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithPopup, GoogleAuthProvider, signInWithEmailLink } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import Navbar from '../../Navbar/Navbar';
import LenderSignUpTop from './lenderSignUpTop/lenderSignUpTop';
import './lenderSignUp.css'; // Import the CSS file

const auth = getAuth();
const db = getFirestore();

const LenderSignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  });
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  const logLenderLogin = async (user) => {
    try {
      console.log('Logging in user with UID:', user.uid);

      // Update user role in Firestore with uid as document ID
      const userRolesRef = doc(db, 'userRoles', user.uid);
      const userRolesDoc = await getDoc(userRolesRef);
      if (userRolesDoc.exists()) {
        await updateDoc(userRolesRef, {
          'roles.lenders': true
        });
        console.log('Updated existing user role for UID:', user.uid);
      } else {
        await setDoc(userRolesRef, {
          roles: {
            lenders: true,
            quotes: false,
            fileUpload: false
          }
        });
        console.log('Set new user role for UID:', user.uid);
      }

      // Create or update the lenders collection with additional fields
      await setDoc(doc(db, 'lenders', user.uid), {
        ...userInfo,
        email: user.email,
        lenderId: user.uid, // Add lenderId as user.uid
        createdAt: Timestamp.now(),
        verified: false
      }, { merge: true }); // merge option will ensure only new fields are added/updated

      console.log('Lenders collection updated for UID:', user.uid);

    } catch (err) {
      console.error('Error logging lender login:', err);
      setError('Error updating profile, please try again.');
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      await logLenderLogin(user);
  
      setMessage('Account created successfully. Please check your email to verify your account.');
      navigate('/lender-dashboard');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        // Display a warning message instead of signing them in
        setError('An account with this email already exists. Please log in instead.');
      } else {
        setError('Error creating profile. Please try again.');
      }
    }
  };
  

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      console.log('User signed in with Google:', result.user);
      await logLenderLogin(result.user);
      navigate('/lender-dashboard');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEmailLinkSignIn = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      try {
        const result = await signInWithEmailLink(auth, email, window.location.href);
        window.localStorage.removeItem('emailForSignIn');
        console.log('User signed in with email link:', result.user);
        await logLenderLogin(result.user);
        navigate('/lender-dashboard');
      } catch (err) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    handleEmailLinkSignIn();
  }, []);

  return (
    <div>
      <Navbar />
      <LenderSignUpTop />
      <div className='lender-sign-up-header-section'>
        <h1>Take Care of Your Clients, Close On Time, And Make Money with <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote</h1>
        <h2>What <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> Can Do For Your Clients</h2>
          <ul>
            <li className="lender-sign-up-li">Get the most out of your client relationships by partnering with <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote!</li>
            <li className="lender-sign-up-li">Refer your clients to best-in-class, local agents that are able to provide your clients with 7 or more quotes for their Home, Auto, Life, and Umbrella insurance needs.</li>
            <li className="lender-sign-up-li">Help them save! Your clients are 5x more likely to save money with  <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote 
            than online carriers.</li>
          </ul>
        <h2>What <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span> Can Do For You</h2>
          <ul>
          <li className="lender-sign-up-li">Easily submit your clients' quote requests from your own personalized dashboard.</li>
          <li className="lender-sign-up-li">Close on time! When you submit your client's information to  <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote,
           we will have their quotes completed within 1 business day. We will also keep you updated throughout the entire process.</li>
           <li className="lender-sign-up-li">Earn more money! Your client relationships are valuable, that's why we pay you for every quote we complete for your clients, even if they don't buy from us.</li>
          </ul>
        <h2>Support Your Community By Working With  <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote</h2>
          <ul>
            <li className="lender-sign-up-li"> <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote 
            agents are Independent Agents in your area that are committed to providing clients with the right coverage at the best price.</li>
            <li className="lender-sign-up-li">Learn more about your local  <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote agents <a href="/aboutus">HERE</a>.</li>
          </ul>
      </div>
      <div className="lender-sign-up-container">
        <h2 className='lender-sign-up-container-header'>Create Your User Profile</h2>
        <form className="lender-sign-up-form" onSubmit={handleEmailSignIn}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='lender-sign-up-submit' type="submit">Sign Up with Email</button>
          <button type="button" onClick={handleGoogleSignIn} className="google-signin-btn">
            <img src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" alt="Sign in with Google" />
          </button>
          {error && <p className="lender-sign-up-error-message">{error}</p>}
          {message && <p className="lender-sign-up-success-message">{message}</p>}
          <p>Already a member? <Link to="/lender-login">Click here to login</Link></p>
        </form>
      </div>
    </div>
  );
};

export default LenderSignUp;








