import React from 'react';
import ReactDOM from 'react-dom/client';
import './aboutus-ourstory.css';

function AboutUsOurStory() {

  
    return (
    
    <div className="aboutus-ourstory">
    <p id='aboutus-ourstory-head'>Our Story</p>
    <p id='aboutus-ourstory-body'>As Independent Agents, we realized that while we are able to offer the best price, coverage, and service, we were seriously lacking in providing the convenience that technology can offer.  The result is <span className='aboutus-ourstory-letter' id='aboutus-ourstory-A'>A</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-B'>B</span><span className='aboutus-ourstory-letter' id='aboutus-ourstory-C'>C</span>quote.com, the easiest way to obtain 7 or more quotes while also receiving the professional guidance and exemplary service only available through our network of qualified Independent Agents.</p>
    </div>
    
    );
  }
  
  export default AboutUsOurStory;