
import React from 'react';
import ReactDOM from 'react-dom/client';
import './how-it-works-tiles.css';

function HowItWorksTiles() {

  
    return (
      <div className="how-it-works-tiles">
        <div className='how-it-works-step'>
            <p className='how-it-works-stepheader'>Step 1</p>
            <img className="step-img" src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2FUntitled-design.png?alt=media&token=0f4b4d7c-28e0-4439-86b2-393077b93a87' />
            <p className='how-it-works-steptitle'>ABC's - It really is that easy</p>
            <p className='how-it-works-stepbody'>Just enter your Address, Birthdate, and Contact Information.</p>
        </div>
        <div className='how-it-works-step'>
           <p className='how-it-works-stepheader'>Step 2</p>
           <img className='step-img' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fyou-came-to-the-right-place.png?alt=media&token=67042ce8-7db5-4262-aa72-eafcf4b8bda2' />
            <p className='how-it-works-steptitle'>We'll Take It From Here</p>
            <p className='how-it-works-stepbody'>Your local agent will begin working on quotes from the best regional and national carriers available in your area.</p>
        </div>
        <div className='how-it-works-step'>
            <p className='how-it-works-stepheader'>Step 3</p>
            <img className='step-img' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Flady-on-phone.png?alt=media&token=33bde7b3-dc92-49aa-aa32-6f3dbf5f7d93' />
            <p className='how-it-works-steptitle'>Let's Chat</p>
            <p className='how-it-works-stepbody'>Within 24 hours your agent will set up a time to go over your quotes and verify everything is correct before finalizing your purchase.</p>
        </div>
        <div className='how-it-works-step'>
            <p className='how-it-works-stepheader'>Step 4</p>
            <img className='step-img' src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fthats-all-folks.png?alt=media&token=3f602fdf-b9c8-4f94-8da5-f913baad88c3' />
            <p className='how-it-works-steptitle'>That's All Folks</p>
            <p className='how-it-works-stepbody'>Your agent will take care of getting your new policiies set up, your old policies cancelled, and make sure you know how to get a hold of them in the future.</p>
        </div>

      </div>
    );
  }
  
  export default HowItWorksTiles;