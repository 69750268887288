import React from 'react';
import ReactDOM from 'react-dom/client';
import './homepage-rightcoverage.css';



function RightCoverage() {

    return (
        <div className='rightcoverage-section'>
        <div className='rightcoverage-top'>
            <h2 className='rightcoverage-rcbp'>The Right Coverage + the Best Price = </h2>
            <h2 className='rightcoveragetop-logo'><span className='rightcoverage-A'>A</span><span className='rightcoverage-B'>B</span><span className='rightcoverage-C'>C</span><span className='rightcoverage-quote'>quote.com</span></h2>
            <p>Experience the convenience of technology and the value of working with a licensed professional</p>
        </div>
        <div className='rightcoverage-tiles'>
            <div className='rightcoverage-bestprice'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fphone-money-green-min-1.png?alt=media&token=9d1b7eff-33db-468c-afe0-0d6298a9b93c'/>
                <div rightcoveragetiles-p1p2>    
                    <p className='rightcoveragetiles-p1'>Best Price</p>
                    <p className='rightcoveragetiles-p2'>Online quoting services want you to think they offer the lowest prices. At ABCquote.com, we work exclusively with the best independent agents in your area that represent the most reliable and competitive regional and national carriers. That’s why we are able to save 7 out of 10 people money!</p>
                </div>
            </div>
            <div className='rightcoverage-rightcoverage'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ficon-claim-min.png?alt=media&token=3fc2253b-c2f1-438a-8bc8-6f1e7b3141cf' />
                <div rightcoveragetiles-p1p2>    
                    <p className='rightcoveragetiles-p1'>Right Coverage</p>
                    <p className='rightcoveragetiles-p2'>In a world of “name your price” insurance options, we haven’t lost sight of what is most important: making sure our customers have the coverage they need at the time of a claim.</p>
                </div>
            </div>
            <div className='rightcoverage-technology'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Fphone-abc-min.png?alt=media&token=2673eab4-1dc3-413e-8b41-1564027ae603' />
                <div rightcoveragetiles-p1p2>    
                    <p className='rightcoveragetiles-p1'>Technology/Convenience</p>
                    <p className='rightcoveragetiles-p2'>For too long Independent Agents have been losing the technology race to large Wallstreet carriers who are more beholden to their shareholders than they are to their policyholders. Thanks to ABCquote, the convenience required to compete in today’s market is back in the hand of your community. We utilize the most secure and up-to-date technology to make having your insurance quoted by all of the top-rated carriers in your area as easy as A B C. With just your Address, Birthdate and Contact information we can complete a quote for you.</p>
                </div>
            </div>
            <div className='rightcoverage-professionalservice'>
                <img src='https://firebasestorage.googleapis.com/v0/b/abc-quote.appspot.com/o/src%20files%2Ftwo-people-meeting.jpg?alt=media&token=4999240d-4a78-4151-ba2a-125b7840fc01' />
                <div rightcoveragetiles-p1p2>    
                    <p className='rightcoveragetiles-p1'>Professional Service</p>
                    <p className='rightcoveragetiles-p2'>Our network of professional agents work for you, not the carrier. They have local knowledge and care about their communities. Since they work for you, they are able to advise you conflict-free while offering additional services such as coverage reviews and advocacy at the time of a claim.</p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default RightCoverage